import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import Select from "@material-ui/core/Select";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Info from "@material-ui/icons/Info";
// material-ui
import { withStyles } from "@material-ui/styles";
import CustomerFinder from "components/CustomerFinder";
import Help from "components/Help";
// custom
import Pallet from "components/Pallet";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Card from "../Card";
// constants
import {
  ADD,
  DRY,
  EXPEDITED_IM_SERVICE,
  FREIGHT_CLASS,
  INCHES,
  LTL,
  LTL_TEMPERATURE,
  REEFER,
  REMOVE,
  STANDARD_IM_SERVICE,
  // COMMODITIES,
  STRIKE_COMMODITIES,
  TL,
  TL_TEMPERATURE,
} from "../constants";
import FormPalletDimension from "../FormPalletDimension";
// helpers
import { positiveNumber, validNumber } from "../helpers";
import PalletChecker from "../PalletChecker";
// styles
import styles from "./styles";

class AboutQuote extends Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    checkPallets: PropTypes.func,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    service: PropTypes.string,
    customerName: PropTypes.string,
    commodityDescriptionId: PropTypes.number,
    units: PropTypes.object,
    dryVanOnly: PropTypes.bool,
    commodityDescriptions: PropTypes.array,
    mode: PropTypes.string,
    freightClass: PropTypes.string,
    customerId: PropTypes.number,
    reefer: PropTypes.object,
    nextClicked: PropTypes.bool,
    searchCustomers: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { gatherErrorFunction } = this.props;
    this.state = {
      palletDimensionOpen: false,
    };
    gatherErrorFunction(
      {
        errorFunction: this.hasError.bind(this),
        section: "About the Load",
      },
      ADD
    );
  }

  componentWillUnmount() {
    const { gatherErrorFunction } = this.props;
    gatherErrorFunction(
      {
        errorFunction: this.hasError.bind(this),
        section: "About the Load",
      },
      REMOVE
    );
  }

  handleChange = (name, number) => (event) => {
    const { onChange, units, reefer } = this.props;
    const { NotificationCenter } = this.context;
    const { target } = event;
    const { value } = target;

    if (name === "service" && value === STANDARD_IM_SERVICE) {
      onChange({
        mode: DRY,
        reefer: {
          reeferTemp: "",
          reeferCont: false,
        },
      });
    }

    if (number && !(validNumber(value) || value === "" || value === "-")) {
      return;
    }

    if (name === "reeferTemp") {
      const _reefer = JSON.parse(JSON.stringify(reefer));
      _reefer[name] = value;
      onChange({
        reefer: _reefer,
      });
      return;
    }

    if (name === "totalWeight") {
      const _units = JSON.parse(JSON.stringify(units));
      _units.totalDimensions[name] = value;
      onChange({
        units: _units,
      });
      return;
    }

    onChange({
      [name]: value,
    });

    if (name === "commodityDescriptionId") {
      switch (value) {
        case 1: // Machinery
          NotificationCenter.sweetAlert(
            {
              title: "Important Information",
              body: "Machinery must be at minimal skeleton crate",
              info: true,
              timestamp: new Date().getTime(),
            },
            {
              confirm: {
                label: "Got it",
                level: "success",
              },
            }
          );
          break;
        case 7: // Tote
          NotificationCenter.sweetAlert(
            {
              title: "Important Information",
              body: "If the load contains 990 gallons or more, the driver needs a tanker endorsement",
              info: true,
              timestamp: new Date().getTime(),
            },
            {
              confirm: {
                label: "Got it",
                level: "success",
              },
            }
          );
          break;
        default:
          break;
      }
    }
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;
    const { onChange, reefer } = this.props;

    if (name === "reeferCont") {
      const _reefer = JSON.parse(JSON.stringify(reefer));
      _reefer[name] = checked;
      onChange({
        reefer: _reefer,
      });
      return;
    }

    onChange({
      [name]: checked,
    });
  };

  handlePalletDelete(index) {
    const { units, onChange } = this.props;
    const _units = JSON.parse(JSON.stringify(units));

    _units.palletDimensions.splice(index, 1);

    onChange({
      units: _units,
    });
  }

  handleDimensionEdit(index) {
    const { units } = this.props;
    const { palletDimensions } = units;

    this.setState({
      selectedPalletIndex: index,
      palletToUpdate: { ...palletDimensions[index] },
    });
    this.openPalletDimension();
  }

  handleUpdatedPalletDimension(updatedPallet) {
    const { units, onChange } = this.props;
    const _units = JSON.parse(JSON.stringify(units));

    const { selectedPalletIndex } = this.state;

    _units.palletDimensions[selectedPalletIndex] = updatedPallet;
    onChange({ units: _units });
  }

  handlePalletTmp(e) {
    const { target } = e;
    const { value } = target;

    if (positiveNumber(value) || value === "") {
      this.setState((prevState) => ({
        tmpPallet: {
          ...prevState.tmpPallet,
          count: Number(value),
        },
      }));
    }
  }

  handlePalletFocus() {
    this.setState({
      tmpPallet: {
        units: INCHES,
        length: 48,
        width: 40,
        height: 48,
        count: "",
        stackable: false,
        key: Date.now(),
      },
    });
  }

  hasError() {
    const { service, units, mode, reefer, commodityDescriptionId } = this.props;

    const { reeferTemp } = reefer;

    const { totalDimensions, palletDimensions } = units;
    const { totalWeight } = totalDimensions;

    if (commodityDescriptionId === undefined) {
      return true;
    }

    if (
      mode === REEFER &&
      service === LTL &&
      !LTL_TEMPERATURE.test(reeferTemp)
    ) {
      return true;
    }

    if (mode === REEFER && service === TL && !TL_TEMPERATURE.test(reeferTemp)) {
      return true;
    }

    if (palletDimensions.length === 0) {
      return true;
    }

    if (totalWeight === 0 || totalWeight === "") {
      return true;
    }

    return false;
  }

  openPalletDimension() {
    this.setState({ palletDimensionOpen: true });
  }

  closePalletDimension() {
    this.setState({ palletDimensionOpen: false });
  }

  addPallet() {
    const { units, onChange } = this.props;
    const _units = JSON.parse(JSON.stringify(units));

    const { tmpPallet } = this.state;

    if (
      tmpPallet === undefined ||
      (tmpPallet && tmpPallet.count === "") ||
      (tmpPallet && tmpPallet.count === 0)
    ) {
      this.setState({
        tmpPallet: undefined,
      });
      this.handlePalletFocus();
      return;
    }

    _units.palletDimensions.push(tmpPallet);

    onChange({
      units: _units,
    });
    this.handlePalletFocus();
  }

  render() {
    const {
      classes,
      disabled,
      checkPallets,
      theme,
      service,
      commodityDescriptionId,
      units,
      mode,
      dryVanOnly,
      reefer,
      nextClicked,
      csr,
      searchCustomers,
      customerName,
      customerId,
      freightClass,
      commodityDescriptions,
    } = this.props;

    const { totalDimensions, palletDimensions } = units;
    const { totalWeight } = totalDimensions;

    const { reeferTemp, reeferCont } = reefer;

    const { tmpPallet, palletDimensionOpen, palletToUpdate } = this.state;

    const primaryColor = theme.palette.primary.color[500];
    let temperatureError = "";
    if (
      mode === REEFER &&
      service === LTL &&
      !LTL_TEMPERATURE.test(reeferTemp)
    ) {
      temperatureError = "Temperature must be between 40℉ and 80℉";
    }

    if (mode === REEFER && service === TL && !TL_TEMPERATURE.test(reeferTemp)) {
      temperatureError = "Temperature must be between -20℉ and 80℉";
    }

    return (
      <div>
        <Card
          nextClicked={nextClicked}
          error={this.hasError()}
          primaryColor={primaryColor}
          disabled={disabled}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Info />
            </Grid>
            <Grid item>
              <Typography display="block" variant="h6">
                About the Load
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.line}>
            {csr ? (
              <Grid item xs={12} md={12}>
                <Help explanation="Select the customer you are quoting for. This could affect the final prices for customer who have special agreements.">
                  <CustomerFinder
                    searchCustomers={searchCustomers}
                    onChange={this.handleChange("customerId")}
                    defaultValue={{ name: customerName, id: customerId }}
                  />
                </Help>
              </Grid>
            ) : (
              <div />
            )}
            <Grid item xs={12} md={3}>
              <Help explanation="Select LTL (Less Than Truckload) or TL (Truckload)">
                <FormControl component="fieldset" disabled={disabled}>
                  <FormLabel component="legend">Service</FormLabel>
                  <RadioGroup
                    aria-label="service-type"
                    name="service-type"
                    value={service}
                    onChange={this.handleChange("service")}
                  >
                    <FormControlLabel
                      value={LTL}
                      control={<Radio color="primary" />}
                      label={LTL}
                    />
                    <FormControlLabel
                      value={TL}
                      control={<Radio color="primary" />}
                      label={TL}
                    />
                    <FormControlLabel
                      value={STANDARD_IM_SERVICE}
                      control={<Radio color="primary" />}
                      label={STANDARD_IM_SERVICE}
                    />
                    <FormControlLabel
                      value={EXPEDITED_IM_SERVICE}
                      control={<Radio color="primary" />}
                      label={EXPEDITED_IM_SERVICE}
                    />
                  </RadioGroup>
                </FormControl>
              </Help>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl component="fieldset" disabled={disabled}>
                <FormLabel component="legend">Mode</FormLabel>
                <RadioGroup
                  aria-label="mode"
                  name="mode"
                  value={mode}
                  onChange={this.handleChange("mode")}
                >
                  <FormControlLabel
                    value={DRY}
                    control={<Radio color="primary" />}
                    label={DRY}
                  />
                  <FormControlLabel
                    value={REEFER}
                    control={<Radio color="primary" />}
                    disabled={service === STANDARD_IM_SERVICE}
                    label={REEFER}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            {mode === REEFER && service !== STANDARD_IM_SERVICE && (
              <Grid item xs={12} md={3}>
                <Help explanation="Temperature range depends on the service you selected.">
                  <TextField
                    id="Temperature"
                    label="Temperature"
                    placeholder={"in Fahrenheit"}
                    helperText={
                      service === LTL
                        ? "Between 40℉ and 80℉"
                        : "Between -20℉ and 80℉"
                    }
                    fullWidth
                    value={reeferTemp}
                    onChange={this.handleChange("reeferTemp", true)}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={temperatureError !== "" && nextClicked}
                    disabled={mode === DRY || disabled}
                  />
                </Help>
              </Grid>
            )}
            {mode === REEFER && (
              <Grid item>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={reeferCont}
                        onChange={this.handleCheckboxChange("reeferCont")}
                        value="Continuous Running"
                        color="primary"
                      />
                    }
                    label="Continuous Running"
                    disabled={disabled || mode === DRY}
                  />
                </FormGroup>
              </Grid>
            )}
            {mode === DRY && (
              <Grid>
                <Help explanation="If selected only a dry van will be use (van with AC unit won't be used)">
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={dryVanOnly}
                          onChange={this.handleCheckboxChange("dryVanOnly")}
                          value="Dry Van Only"
                          color="primary"
                        />
                      }
                      label="Dry Van Only"
                      disabled={disabled || mode === REEFER}
                    />
                  </FormGroup>
                </Help>
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2} className={classes.line}>
            <Grid item xs={12} md={3}>
              <FormControl
                fullWidth
                disabled={disabled}
                error={commodityDescriptionId === undefined && nextClicked}
              >
                <InputLabel htmlFor="commodity-description">
                  Commodity Description
                </InputLabel>
                <Select
                  value={commodityDescriptionId || ""}
                  onChange={this.handleChange("commodityDescriptionId")}
                  inputProps={{
                    name: "Commodity Description",
                    id: "commodity-description",
                  }}
                >
                  {commodityDescriptions &&
                    commodityDescriptions
                      .filter((f) => !f.hidden)
                      .map((commodity) => (
                        <MenuItem value={commodity.id} key={commodity.id}>
                          {commodity.name}
                        </MenuItem>
                      ))}
                  {commodityDescriptions &&
                    commodityDescriptions
                      .filter((f) => f.hidden)
                      .map((commodity) => (
                        <MenuItem value={-1} key={commodity} disabled>
                          <span className={classes.strikethrough}>
                            {commodity.name}
                          </span>
                        </MenuItem>
                      ))}
                  {STRIKE_COMMODITIES.map((commodity) => (
                    <MenuItem value={-1} key={commodity} disabled>
                      <span className={classes.strikethrough}>{commodity}</span>
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText
                  error={commodityDescriptionId === undefined && nextClicked}
                >
                  Required
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <Help explanation="Weight will be verified on server side to check the equipment compability.">
                <TextField
                  disabled={disabled}
                  id="totalWeight"
                  label="Total Weight"
                  placeholder="In Pounds"
                  fullWidth
                  helperText={"Required"}
                  value={totalWeight}
                  onChange={this.handleChange("totalWeight", true)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={
                    (totalWeight === "" || totalWeight === 0) && nextClicked
                  }
                />
              </Help>
            </Grid>
            <Grid item xs={12} md={3}>
              <FormControl fullWidth disabled={disabled}>
                <InputLabel htmlFor="commodity-description">
                  Freight Class
                </InputLabel>
                <Select
                  value={freightClass}
                  onChange={this.handleChange("freightClass")}
                  inputProps={{
                    name: "Freight Class",
                    id: "freight-class",
                  }}
                >
                  {FREIGHT_CLASS.map((c) => (
                    <MenuItem value={c} key={c}>
                      {c}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.line}>
            {disabled !== true ? (
              <Grid item xs={12} md={3}>
                <Help explanation="Pallets size will be verified on server side to check the equipment compability.">
                  <TextField
                    disabled={disabled}
                    fullWidth
                    error={palletDimensions.length === 0 && nextClicked}
                    onFocus={this.handlePalletFocus.bind(this)}
                    onChange={this.handlePalletTmp.bind(this)}
                    onBlur={this.addPallet.bind(this)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        this.addPallet(true);
                      }
                    }}
                    value={tmpPallet ? tmpPallet.count : ""}
                    id="palletCount"
                    label="Pallet Count"
                    placeholder="Enter Pallet Count"
                    helperText={palletDimensions.length === 0 ? "Required" : ""}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Help>
              </Grid>
            ) : (
              <Grid item>
                <Typography
                  display="block"
                  variant="subtitle1"
                  color="textSecondary"
                >
                  Pallets :
                </Typography>
              </Grid>
            )}
            {palletDimensions.map((p, index) => (
              <Grid item>
                <Pallet
                  key={p.key}
                  {...p}
                  onDelete={this.handlePalletDelete.bind(this)}
                  onDimensionEdit={this.handleDimensionEdit.bind(this)}
                  index={Number(index)}
                  disabled={disabled}
                />
              </Grid>
            ))}
          </Grid>
          <Grid container justify="flex-end">
            <Grid item>
              <PalletChecker units={units} checkPallets={checkPallets} />
            </Grid>
          </Grid>
        </Card>
        <FormPalletDimension
          open={palletDimensionOpen}
          close={this.closePalletDimension.bind(this)}
          pallet={palletToUpdate}
          onSave={this.handleUpdatedPalletDimension.bind(this)}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AboutQuote);
