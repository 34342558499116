// material-ui
import LocalShipping from "@material-ui/icons/LocalShipping";
import Warning from "@material-ui/icons/Warning";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// constants
import { DAY_SIZE } from "../../constants";
// styles
import styles from "./styles";

class Day extends Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    day: PropTypes.object,
    selectable: PropTypes.bool,
    pickup: PropTypes.bool,
    selected: PropTypes.bool,
    transit: PropTypes.bool,
    first: PropTypes.bool,
    last: PropTypes.bool,
    grouped: PropTypes.bool,
    color: PropTypes.string,
    onSelect: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { pickup, selectable, transit } = this.props;

    // check invariants
    let invariantViolation = false;
    if ((selectable && transit) || (selectable && pickup)) {
      invariantViolation = true;
    }

    this.state = {
      invariantViolation,
    };
  }

  render() {
    const {
      classes,
      pickup,
      day,
      selectable,
      onSelect,
      first,
      last,
      selected,
      transit,
      color,
      theme,
      grouped,
    } = this.props;

    const { invariantViolation } = this.state;

    let background = selectable ? color : undefined;
    if (selected) background = "white";
    if (transit)
      background = theme.palette.type === "dark" ? "white" : "#607d8b";
    let col = selectable ? "white" : undefined;
    if (selected) col = color;
    let width = selected ? DAY_SIZE - 10 : undefined;
    if (transit) width = DAY_SIZE;
    let height = selected ? DAY_SIZE - 10 : undefined;
    if (transit) height = 10;
    if (selectable) {
      height = DAY_SIZE - 12;
      width = DAY_SIZE - 12;
    }
    if (grouped) {
      width = DAY_SIZE - 4;
      height = DAY_SIZE - 12;
    }
    let borderTopLeftRadius = DAY_SIZE;
    if (transit || grouped) borderTopLeftRadius = first ? DAY_SIZE : 0;
    let borderBottomLeftRadius = DAY_SIZE;
    if (transit || grouped) borderBottomLeftRadius = first ? DAY_SIZE : 0;
    let borderTopRightRadius = DAY_SIZE;
    if (transit || grouped) borderTopRightRadius = last ? DAY_SIZE : 0;
    let borderBottomRightRadius = DAY_SIZE;
    if (transit || grouped) borderBottomRightRadius = last ? DAY_SIZE : 0;
    let margin = !transit ? 4 : 0;
    if (grouped) margin = "4px 0px 4px 0px";
    if (grouped && first) {
      margin = "4px 0px 4px 4px";
      width = DAY_SIZE - 8;
    }
    if (grouped && last) {
      margin = "4px 4px 4px 0px";
      width = DAY_SIZE - 8;
    }

    if (invariantViolation) {
      return <Warning style={{ color: "#ff9800", fontSize: "19px" }} />;
    }

    return (
      <div
        style={{
          paddingTop: transit ? "10px" : undefined,
          paddingBottom: transit ? "10px" : undefined,
        }}
      >
        <div
          className={`${classes.container}`}
          onClick={() => {
            if (selectable && onSelect) {
              onSelect(day);
            }
          }}
          style={{
            cursor: selectable ? "pointer" : "not-allowed",
            margin,
            background,
            color: col,
            width,
            height,
            border: selectable ? `solid 2px ${color}` : undefined,
            borderTopLeftRadius,
            borderBottomLeftRadius,
            borderTopRightRadius,
            borderBottomRightRadius,
          }}
        >
          {!transit && !pickup && day.format("D")}
          {pickup ? (
            <LocalShipping color="inherit" style={{ fontSize: "19px" }} />
          ) : (
            []
          )}
          {selected && grouped && !last ? (
            <div className={classes.rightBorderPatch} />
          ) : (
            []
          )}
          {selected && grouped && !first ? (
            <div className={classes.leftBorderPatch} />
          ) : (
            []
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Day);
