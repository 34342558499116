// routes
import { QUOTE, QUOTES } from "constants/routes";

// icons
import Money from "@material-ui/icons/AttachMoney";
import History from "@material-ui/icons/History";

export const menu = [
  {
    icon: Money,
    label: "New Quote",
    path: QUOTE,
    divider: true,
  },
  {
    icon: History,
    label: "Quote History",
    path: QUOTES,
    divider: true,
  },
];
