export default () => ({
  box: {
    border: "3px solid #C5C5C5",
    borderRadius: 10,
    width: 100,
    height: 100,
    textAlign: "center",
    position: "relative",
    cursor: "pointer",
  },
  contentGroupedBox: {
    borderRadius: 10,
    width: 102,
    height: 100,
    textAlign: "center",
    position: "relative",
  },
  groupeBox: {
    cursor: "pointer",
  },
  disableBox: {
    border: "3px solid #C5C5C5",
    color: "#C5C5C5",
    borderRadius: 10,
    width: 100,
    height: 100,
    opacity: 0.5,
    textAlign: "center",
    position: "relative",
  },
  dayBox: {
    position: "relative",
    width: 100,
  },
  date: {
    position: "absolute",
    bottom: 0,
    left: 4,
    right: 4,
  },
  rate: {
    position: "absolute",
    bottom: 40,
    // left: 25,
    width: "100%",
    textAlign: "center",
  },
  topLeftIcon: {
    position: "absolute",
    top: 5,
    left: 5,
  },
  selected: {
    color: "white",
  },
  dots: {
    position: "absolute",
    top: 35,
    left: 25,
  },
  truck: {
    position: "absolute",
    top: 25,
    left: 35,
  },

  pickUp: {
    position: "absolute",
    top: 50,
    left: 25,
  },
});
