import { networkAction } from "helpers/network/networkAction";

import { TENDER, CANCEL_TENDER } from "constants/tender";

import * as Api from "api";

export const tender = (body) => async (dispatch) =>
  networkAction(dispatch, TENDER, Api.tenderLoad, [body]);

export const cancelTender = (tenderID) => async (dispatch) =>
  networkAction(dispatch, CANCEL_TENDER, Api.cancelTender, [tenderID]);
