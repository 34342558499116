// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Path extends Component {
  static propTypes = {
    classes: PropTypes.object,
    method: PropTypes.string,
  };

  getMethodClass(method) {
    const { classes } = this.props;
    switch (method) {
      case "POST":
        return classes.post;
      case "GET":
        return classes.get;
      case "PUT":
        return classes.put;
      case "DEL":
        return classes.del;
      default:
        return classes.get;
    }
  }

  render() {
    const { method } = this.props;

    return <span className={this.getMethodClass(method)}>{method}</span>;
  }
}

export default withStyles(styles)(Path);
