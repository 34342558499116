// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Highlight from "react-highlight";
// styles
import styles from "./styles";

class Code extends Component {
  static propTypes = {
    value: PropTypes.node,
    language: PropTypes.string,
  };

  render() {
    const { value, language } = this.props;

    return (
      <div>
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.13.1/styles/atom-one-dark.min.css"
        />
        <Highlight className={language}>{value}</Highlight>
      </div>
    );
  }
}

export default withStyles(styles)(Code);
