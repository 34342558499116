// material-ui
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import AdvancedSearch from "components/AdvancedSearchRefactor";
// custom
import Table from "components/TablePagination";
// helpers
import { formattedPrice } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class QuotesList extends Component {
  static propTypes = {
    // classes: PropTypes.object,
    quotes: PropTypes.array,
    onSelect: PropTypes.func,
    filters: PropTypes.string,
    history: PropTypes.object,
    location: PropTypes.object,
    personNames: PropTypes.array,
    searchCustomers: PropTypes.func,
    getQuotesV3: PropTypes.func,
  };

  onSelect(selectedQuote) {
    const { onSelect } = this.props;
    if (onSelect) {
      onSelect(selectedQuote);
    }
  }

  removeDuplicate(array) {
    const ids = {};
    array.forEach((i) => (ids[i.id] = 1));
    return array.filter((i) => {
      if (ids[i.id]) {
        delete ids[i.id];
        return true;
      }
      return false;
    });
  }

  render() {
    const {
      quotes,
      filters,
      history,
      location,
      personNames,
      searchCustomers,
      getQuotesV3,
    } = this.props;

    const data = this.removeDuplicate(personNames);

    return (
      <div>
        <AdvancedSearch
          history={history}
          location={location}
          filters={filters}
          refresh={getQuotesV3}
          keyword="quotes"
          metaDataEndpoint="/v3/sales/quotes/searchMetadata"
          metaDataOveride={{
            load_type: {
              type: "autocomplete",
              data: [
                { value: "LTL", label: "LTL" },
                { value: "TL", label: "TL" },
              ],
              valueKey: "value",
              labelKey: "label",
            },
            api_version: {
              type: "autocomplete",
              data: [
                { value: 0, label: "v0" },
                { value: 1, label: "v1" },
                { value: 2, label: "v2" },
                { value: 3, label: "v3" },
              ],
              valueKey: "value",
              labelKey: "label",
            },
            name: {
              type: "autocomplete",
              data: [
                { value: "EDI", label: "EDI" },
                { value: "APIV2", label: "APIV2" },
                { value: "LMCS", label: "LMCS" },
                { value: "PUBLICAPIV2", label: "PUBLICAPIV2" },
                { value: "JSON EDI", label: "JSON EDI" },
                { value: "INTERNALAPIV2", label: "INTERNALAPIV2" },
                {
                  value: "CONTRACT_CALCULATION",
                  label: "CONTRACT_CALCULATION",
                },
                { value: "LEGACY_LONG_QUOTE", label: "LEGACY_LONG_QUOTE" },
                { value: "LEGACY_QUICK_QUOTE", label: "LEGACY_QUICK_QUOTE" },
              ],
              valueKey: "value",
              labelKey: "label",
            },
            person_id: {
              type: "autocomplete",
              data,
              valueKey: "id",
              labelKey: "name",
            },
            customer_id: {
              type: "autoFill",
              getData: (search) => searchCustomers(search, 0, 25),
              labelTransform: (t) => `${t.name}`,
              valueKey: "id",
              labelKey: "name",
            },
          }}
        >
          <Table
            data={quotes}
            noToolbar
            metaDataEndpoint="/v3/sales/quotes/searchMetadata"
            history={history}
            meta={[
              {
                path: "api_version",
                title: "Version",
              },
              {
                path: "id",
                title: "ID #",
              },
              {
                path: "load_id",
                title: "Load #",
              },
              {
                path: "csr_name",
                title: "CSR",
              },
              {
                path: "customer_name",
                title: "Customer",
              },
              {
                path: "shipper_city",
                title: "Shipper",
                render: (t, d) => (
                  <React.Fragment>
                    <Typography display="block">{d.shipper_city}</Typography>
                    <Typography
                      display="block"
                      variant="caption"
                      color="textSecondary"
                    >
                      {d.shipper_zip}
                    </Typography>
                  </React.Fragment>
                ),
              },
              {
                path: "consignee_city",
                title: "Consignee",
                render: (t, d) => (
                  <React.Fragment>
                    <Typography display="block">{d.consignee_city}</Typography>
                    <Typography
                      display="block"
                      variant="caption"
                      color="textSecondary"
                    >
                      {d.consignee_zip}
                    </Typography>
                  </React.Fragment>
                ),
              },
              {
                path: "pallets",
                title: "Pallets",
                render: (t, d) => (
                  <React.Fragment>
                    <Typography display="block">
                      {d.pallets} Pallet{d.pallets > 1 ? "s" : ""}
                    </Typography>
                    <Typography
                      display="block"
                      variant="caption"
                      color="textSecondary"
                    >
                      {d.weight} Lbs
                    </Typography>
                  </React.Fragment>
                ),
              },
              {
                path: "cost_total",
                title: "Total",
                transform: (t) => formattedPrice(t * 100),
              },
            ]}
            title={"Quotes"}
            onRowSelect={this.onSelect.bind(this)}
          />
        </AdvancedSearch>
      </div>
    );
  }
}

export default withStyles(styles)(QuotesList);
