export default () => ({
  root: {
    width: 886,
  },
  box: {
    border: "3px solid #C5C5C5",
    borderRadius: 10,
    width: 100,
    height: 100,
    textAlign: "center",
    position: "relative",
    cursor: "pointer",
  },

  disableBox: {
    border: "3px solid #C5C5C5",
    color: "#C5C5C5",
    borderRadius: 10,
    width: 100,
    height: 100,
    opacity: 0.5,
    textAlign: "center",
    position: "relative",
  },
  dayBox: {
    position: "relative",
    borderRadius: 10,
    width: 105,
    textAlign: "center",
  },
  date: {
    position: "absolute",
    bottom: 0,
    left: 4,
    right: 4,
  },
});
