import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { Component } from "react";
// constants
import { SERVICE_LEVEL_ICONS } from "../constants";
import { formattedPrice } from "../helpers";
// styles
import styles from "./styles";

class CostBreakdown extends Component {
  static propTypes = {
    costBreakdown: PropTypes.array,
  };

  constructor(...args) {
    super(...args);

    const costBreakdownObj = {
      guaranteed: {},
      premium: {},
      economy: {},
    };
    this.props.costBreakdown.forEach((item) => {
      if (costBreakdownObj[item.service_level][item.group]) {
        costBreakdownObj[item.service_level][item.group].paths.push(item);
        costBreakdownObj[item.service_level][item.group].total +=
          item.cost_amount;
        if (item.description === "Total Cost") {
          costBreakdownObj[item.service_level][item.group].total =
            item.cost_amount;
        }
      } else {
        costBreakdownObj[item.service_level][item.group] = {
          paths: [item],
          total: item.cost_amount,
        };
      }
    });

    const costBreakdown = [];

    for (const serviceLevel in costBreakdownObj) {
      if (costBreakdownObj.hasOwnProperty(serviceLevel)) {
        const sections = costBreakdownObj[serviceLevel];
        costBreakdown.push({
          name: serviceLevel,
          sections: Object.keys(sections)
            .map((k) => ({
              name: k,
              content: sections[k],
            }))
            .sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            }),
        });
      }
    }

    this.state = { costBreakdown };
  }

  render() {
    const { costBreakdown } = this.state;

    return (
      <Grid
        container
        spacing={2}
        style={{
          padding: 20,
        }}
      >
        {costBreakdown.map((serviceLevel) => (
          <Grid item xs={12} md={4} key={serviceLevel.name}>
            <Grid container spacing={2} alignItems="center" justify="center">
              <Grid item>
                <img
                  src={SERVICE_LEVEL_ICONS[serviceLevel.name].icon}
                  style={{
                    height: 30,
                  }}
                  alt={serviceLevel.name}
                />
              </Grid>
              <Grid item>
                <Typography
                  display="block"
                  variant="h6"
                  style={{ textTransform: "capitalize" }}
                >
                  {serviceLevel.name}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                {serviceLevel.sections.map((section) => (
                  <ExpansionPanel key={section.name}>
                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                      <Grid
                        container
                        justify="space-between"
                        alignItems="center"
                      >
                        <Grid item>
                          <Typography display="block" variant="subheading">
                            {section.name}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography display="block" color="primary">
                            <b>
                              {formattedPrice(section.content.total.toFixed(2))}
                            </b>
                          </Typography>
                        </Grid>
                      </Grid>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails>
                      <Table
                        data={section.content.paths}
                        noToolbar
                        noPaper
                        meta={[
                          {
                            path: "description",
                          },
                          {
                            path: "cost_amount",
                            transform: (t) => formattedPrice(t),
                          },
                        ]}
                      />
                    </ExpansionPanelDetails>
                  </ExpansionPanel>
                ))}
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
    );
  }
}

export default withStyles(styles)(CostBreakdown);
