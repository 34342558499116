// material-ui
import { withStyles } from "@material-ui/styles";
// reactor
import Loading from "components/Loading";
// Component
import Quote from "components/Quote";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperQuotePage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    debug: PropTypes.bool,
    askPickup: PropTypes.bool,

    // Quote
    quoteID: PropTypes.string,
    checkPallets: PropTypes.func,
    getAccessorials: PropTypes.func,
    costBreakdown: PropTypes.array,
    marketCosts: PropTypes.object,
    searchLocations: PropTypes.func,
    newLocation: PropTypes.func,
    newQuote: PropTypes.func,
    searchCustomers: PropTypes.func,
    getCommodityDescriptions: PropTypes.func,
    getHolidays: PropTypes.func,
    quote: PropTypes.object,
    getLocationById: PropTypes.func,
    updateLocation: PropTypes.func,
    restart: PropTypes.func,
    convertQuote: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      customer: Boolean(urlParams.customer),
      fab: [],
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ customer: Boolean(nextProps.urlParams.customer) });
  }

  render() {
    const {
      checkPallets,
      getAccessorials,
      searchLocations,
      newLocation,
      newQuote,
      searchCustomers,
      getHolidays,
      history,
      quote,
      debug,
      getLocationById,
      updateLocation,
      convertQuote,
      askPickup,
      quoteID,
      getCommodityDescriptions,
      costBreakdown,
      restart,
      marketCosts,
      loading,
    } = this.props;

    if (loading) return <Loading />;

    return (
      <Quote
        csr
        debug={debug}
        searchCustomers={searchCustomers}
        checkPallets={checkPallets}
        quoteID={quoteID}
        getAccessorials={getAccessorials}
        getHolidays={getHolidays}
        getCommodityDescriptions={getCommodityDescriptions}
        searchLocations={searchLocations}
        newLocation={newLocation}
        newQuote={newQuote}
        restart={restart}
        costBreakdown={costBreakdown}
        history={history}
        quote={quote}
        getLocationById={getLocationById}
        updateLocation={updateLocation}
        convertQuote={convertQuote}
        askPickup={askPickup}
        marketCosts={marketCosts}
        header={this.header}
      />
    );
  }
}
export default withStyles(styles)(WrapperQuotePage);
