import Paper from "@material-ui/core/Paper";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Card extends Component {
  static propTypes = {
    classes: PropTypes.object,
    disabled: PropTypes.bool,
    primaryColor: PropTypes.string,
    error: PropTypes.bool,
    nextClicked: PropTypes.bool,
    children: PropTypes.node,
  };

  render() {
    const {
      primaryColor,
      disabled,
      error,
      children,
      nextClicked,
      classes,
    } = this.props;

    let borderColor = primaryColor;

    if (nextClicked && error) {
      borderColor = "#f44336";
    }

    if (disabled) {
      borderColor = "#9e9e9e";
    }

    return (
      <Paper
        className={classes.container}
        style={{
          borderLeft: `solid 4px ${borderColor}`,
        }}
      >
        {children}
      </Paper>
    );
  }
}

export default withStyles(styles)(Card);
