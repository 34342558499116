import { Typography } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Help extends Component {
  static propTypes = {
    classes: PropTypes.object,
    children: PropTypes.node,
    explanation: PropTypes.string,
    image: PropTypes.string,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      cmd: false,
    };

    // hotkeys("*", (e) => {
    //   if (hotkeys.control) {
    //     this.setState({ cmd: true });
    //   }
    // });

    document.addEventListener("keyup", () => {
      this.setState({ cmd: false, show: false });
    });
  }

  render() {
    const { children, image, explanation } = this.props;
    const { cmd, show } = this.state;

    return (
      <div
        style={{
          background: cmd ? "rgba(33,150,243,0.1)" : undefined,
          padding: 4,
          marginLeft: -4,
          marginTop: -4,
          borderRadius: 6,
        }}
        onMouseEnter={() => {
          if (cmd) {
            this.setState({ show: true });
          }
        }}
        onMouseLeave={() => this.setState({ show: false })}
      >
        <div
          ref={(ref) => {
            this.ref = ref;
          }}
        >
          {children}
        </div>
        <Popper open={show} anchorEl={this.ref}>
          <Paper
            style={{
              background: "#2196f3",
              color: "white",
              maxWidth: 600,
              width: "100%",
              padding: 12,
            }}
          >
            {image ? (
              <img src={image} style={{ maxWidth: 600 }} alt="help" />
            ) : (
              []
            )}
            <Typography display="block" color="inherit" variant="body2">
              {explanation}
            </Typography>
          </Paper>
        </Popper>
      </div>
    );
  }
}

export default withStyles(styles)(Help);
