import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Portal from "@material-ui/core/Portal";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Restart from "@material-ui/icons/Autorenew";
import Bug from "@material-ui/icons/BugReport";
import Search from "@material-ui/icons/Search";
// material-ui
import { withStyles } from "@material-ui/styles";
import ErrorCatcher from "components/ErrorCatcher";
// component
import Loading from "components/Loading";
import Page from "components/Page";
// config
import { REACT_APP_FRONT } from "config";
// routes
import { QUOTE } from "constants/routes";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import Highlight from "react-highlight";
// internal
import AboutQuote from "./AboutQuote";
import { ADD, DEFAULT_BODY, DRY, LTL, REMOVE } from "./constants";
import { getQuoteBody } from "./helpers";
import Result from "./Result";
import Stops from "./Stops";
// styles
import styles from "./styles";

class Quote extends Component {
  static propTypes = {
    csr: PropTypes.bool,
    debug: PropTypes.bool,
    searchCustomers: PropTypes.func,
    classes: PropTypes.object,
    checkPallets: PropTypes.func,
    getAccessorials: PropTypes.func,
    getHolidays: PropTypes.func,
    searchLocations: PropTypes.func,
    newLocation: PropTypes.func,
    newQuote: PropTypes.func,
    quoteID: PropTypes.string,
    convertQuote: PropTypes.func,
    askPickup: PropTypes.bool,
    history: PropTypes.object,
    marketCosts: PropTypes.object,
    quote: PropTypes.object,
    header: PropTypes.any,
    getLocationById: PropTypes.func,
    getCommodityDescriptions: PropTypes.func,
    restart: PropTypes.func,
    updateLocation: PropTypes.func,
    costBreakdown: PropTypes.array,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    KeyboardEventCenter: PropTypes.object,
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { quote, debug, askPickup } = this.props;
    const { KeyboardEventCenter } = this.context;

    this.state = {
      body: quote
        ? getQuoteBody(quote)
        : JSON.parse(JSON.stringify(DEFAULT_BODY)),
      disabled: false,
      key: new Date().getTime(),
      loading: true,
      quoteLoading: false,
      nextClicked: false,
      accessorials: [],
      commodityDescriptions: [],
      errorsFunctions: [],
      pickupDate: moment().format("YYYY-MM-DD"),
      // quoteResult: fakeQuoteResult,
      askPickup,
      debug: debug || false,
      costBreakdownOpen: false,
    };

    KeyboardEventCenter.listen("alt+r", this.restart.bind(this));
    KeyboardEventCenter.listen("alt+f", this.find.bind(this));
    KeyboardEventCenter.listen("alt+c", this.openCostBreakdown.bind(this));
    KeyboardEventCenter.listen("alt+d", this.toggleDebug.bind(this));
  }

  componentWillMount() {
    this.init();
  }

  componentDidMount() {
    const { quote } = this.props;
    if (quote) {
      setTimeout(() => {
        if (ReactDOM.findDOMNode(this.quoteResult)) {
          ReactDOM.findDOMNode(this.quoteResult).scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
        }
      }, 500);
    }
  }

  onChange(bodyPart) {
    if (bodyPart.customerId) {
      this.getAccessorialsForCustomer(bodyPart.customerId);
    }
    this.setState((prevState) => ({
      body: {
        ...prevState.body,
        ...bodyPart,
      },
    }));
  }

  requote() {
    this.setState({
      quoteLoading: true,
    });
    this.handleCalculate();
  }

  checkForErrors() {
    const { NotificationCenter } = this.context;
    const { errorsFunctions } = this.state;
    let hasError = false;
    for (const k in errorsFunctions) {
      if (errorsFunctions.hasOwnProperty(k)) {
        const element = errorsFunctions[k];
        const elementHasError = element.errorFunction();
        hasError = hasError || elementHasError;
        if (elementHasError) {
          NotificationCenter.stack(
            {
              title: "The form is incomplete",
              subtitle: `Fix the red inputs in section '${element.section}' and try again.`,
              timestamp: new Date().getTime(),
              error: true,
            },
            {
              confirm: {
                label: "Ok",
                level: "error",
              },
            }
          );
          break;
        }
      }
    }
    this.setState({ nextClicked: hasError });
    return hasError;
  }

  handleCalculate() {
    const { body } = this.state;
    const hasError = this.checkForErrors();

    if (!hasError) {
      const _body = JSON.parse(JSON.stringify(body));

      if (_body.mode === DRY) {
        delete _body.reefer;
      }

      this.newQuote(_body);
    }
  }

  gatherErrorFunction(errorHandler, action) {
    const { errorsFunctions } = this.state;
    let i;

    switch (action) {
      case ADD:
        errorsFunctions.push(errorHandler);
        console.log("-----");
        console.log("Added", errorHandler.section);
        break;
      case REMOVE:
        i = errorsFunctions.findIndex(
          (e) => e.section === errorHandler.section
        );
        if (i !== undefined) {
          errorsFunctions.splice(i, 1);
          console.log("-----");
          console.log("Removed", errorHandler.section);
        }
        break;
      default:
        break;
    }
  }

  async init() {
    const { getAccessorials, getHolidays, getCommodityDescriptions } =
      this.props;
    const resps = await Promise.all([
      getAccessorials(),
      getHolidays(),
      getCommodityDescriptions(),
    ]);

    this.setState({
      accessorials: resps[0].payload,
      holidays: resps[1].payload,
      commodityDescriptions: resps[2].payload,
      loading: false,
    });
  }

  getAccessorialsForCustomer(_customerId) {
    const { getAccessorials } = this.props;
    getAccessorials(_customerId).then((r) => {
      this.setState({ accessorials: r.payload });
    });
  }

  async newQuote(body) {
    const { newQuote } = this.props;
    this.showCaculateMessage();
    const resp = await newQuote(body);
    if (resp.success && this.quoteLoading) {
      this.setState({
        quoteLoading: false,
        disabled: false,
      });
      this.showResult();
    } else {
      this.setState({
        quoteLoading: false,
        disabled: false,
      });
    }
  }

  find() {
    this.setState({ findOpen: true });
    setTimeout(() => {
      this.findQuoteTextField && this.findQuoteTextField.focus();
    }, 400);
  }

  restart() {
    const { history, restart } = this.props;
    this.setState({
      key: new Date().getTime(),
      body: JSON.parse(JSON.stringify(DEFAULT_BODY)),
      nextClicked: false,
      disabled: false,
      quoteLoading: false,
    });
    history.push({
      search: "",
    });
    restart();
    setTimeout(() => {
      ReactDOM.findDOMNode(this.quoteForm) &&
        ReactDOM.findDOMNode(this.quoteForm).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
    }, 200);
  }

  showCaculateMessage() {
    this.setState({
      quoteLoading: true,
      disabled: true,
    });
    setTimeout(() => {
      ReactDOM.findDOMNode(this.quoteLoading) &&
        ReactDOM.findDOMNode(this.quoteLoading).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
    }, 200);
  }

  showResult() {
    setTimeout(() => {
      ReactDOM.findDOMNode(this.quoteResult) &&
        ReactDOM.findDOMNode(this.quoteResult).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
    }, 200);
  }

  toggleDebug() {
    const { debug } = this.state;
    console.log("toggleDebug");
    const { MessageCenter } = this.context;
    if (debug) {
      this.setState({ debug: false }, () => {
        console.log("closing drawer");
        MessageCenter.close("right");
      });
    } else {
      this.setState({ debug: true }, () => {
        console.log("opening drawer");
        MessageCenter.open("right");
      });
    }
  }

  openCostBreakdown() {
    const { costBreakdown } = this.props;
    if (costBreakdown) {
      this.setState({ costBreakdownOpen: true });
    }
  }

  render() {
    const {
      classes,
      checkPallets,
      searchLocations,
      newLocation,
      history,
      searchCustomers,
      csr,
      getLocationById,
      updateLocation,
      quoteID,
      convertQuote,
      quote,
      costBreakdown,
      marketCosts,
    } = this.props;

    const {
      loading,
      disabled,
      key,
      body,
      accessorials,
      holidays,
      askPickup,
      nextClicked,
      quoteLoading,
      pickupDate,
      commodityDescriptions,
      debug,
    } = this.state;

    const { MessageCenter } = this.context;

    if (loading) {
      return <Loading message="Initializing..." />;
    }

    return (
      <Page
        helmet="Quote"
        loadingMessage={"Loading Interface"}
        loading={loading}
        header={
          <Grid
            container
            justify="space-between"
            alignItems="center"
            style={{ padding: 4 }}
          >
            <Grid item>
              {/* <Typography display="block" color="textSecondary">
                Need help ? Press the <b>control</b> key.
              </Typography> */}
            </Grid>
            <Grid item>
              <Grid container>
                <Grid item style={{ paddingRight: 4 }}>
                  <Chip
                    label="Restart Quote"
                    onClick={this.restart.bind(this)}
                    avatar={
                      <Avatar>
                        <Restart />
                      </Avatar>
                    }
                  />
                </Grid>
                <Grid item style={{ paddingRight: 8 }}>
                  <Chip
                    onClick={this.find.bind(this)}
                    label="Find Quote"
                    avatar={
                      <Avatar>
                        <Search />
                      </Avatar>
                    }
                  />
                </Grid>
                <Grid item style={{ paddingRight: 8 }}>
                  <Chip
                    onClick={this.toggleDebug.bind(this)}
                    label="Debug"
                    avatar={
                      <Avatar>
                        <Bug />
                      </Avatar>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        }
      >
        <div className={classes.container}>
          <Grid
            container
            spacing={2}
            justify="space-between"
            style={{ marginTop: 20 }}
            ref={(c) => {
              this.quoteForm = c;
            }}
          >
            <Grid item>
              <Typography
                display="block"
                variant="h4"
                classes={{
                  h4: classes.h4,
                }}
              >
                New Quote
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grow in>
                <AboutQuote
                  key={key}
                  disabled={disabled}
                  checkPallets={checkPallets}
                  onChange={this.onChange.bind(this)}
                  service={body.service}
                  commodityDescriptionId={body.commodityDescriptionId}
                  units={body.units}
                  reefer={body.reefer}
                  freightClass={body.freightClass}
                  commodityDescriptions={commodityDescriptions}
                  mode={body.mode}
                  dryVanOnly={body.dryVanOnly}
                  customerId={body.customerId}
                  nextClicked={nextClicked}
                  gatherErrorFunction={this.gatherErrorFunction.bind(this)}
                  csr={csr}
                  searchCustomers={searchCustomers}
                  customerName={body.customerName}
                />
              </Grow>
            </Grid>
            <Grid item xs={12}>
              <Grow in>
                <Stops
                  csr={csr}
                  key={key}
                  requote={this.requote.bind(this)}
                  disabled={disabled}
                  service={body.service || LTL}
                  accessorials={accessorials}
                  searchLocations={searchLocations}
                  onChange={this.onChange.bind(this)}
                  shippers={body.shippers}
                  consignees={body.consignees}
                  nextClicked={nextClicked}
                  newLocation={newLocation}
                  holidays={holidays}
                  gatherErrorFunction={this.gatherErrorFunction.bind(this)}
                  getLocationById={getLocationById}
                  updateLocation={updateLocation}
                  quoteAccessorials={quote && quote.accessorials}
                />
              </Grow>
            </Grid>
            {disabled !== true ? (
              <Grid item xs={12}>
                <Grid container className={classes.next} justify="center">
                  <Grid item>
                    <Tooltip title={"Calculate the Quote"}>
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={
                          askPickup
                            ? () => {
                                if (!this.checkForErrors()) {
                                  convertQuote(quoteID, { pickupDate });
                                  this.setState({ askPickup: false });
                                }
                              }
                            : this.handleCalculate.bind(this)
                        }
                      >
                        {askPickup ? "View Prices" : "Calculate"}
                      </Button>
                    </Tooltip>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <div />
            )}
          </Grid>
          {quoteLoading ? (
            <Grow in>
              <Grid
                id="quoteLoading"
                container
                style={{ height: "100vh" }}
                justify={"center"}
                alignItems={"center"}
                ref={(c) => {
                  this.quoteLoading = c;
                }}
              >
                <Grid item style={{ textAlign: "center" }}>
                  <CircularProgress />
                  <br />
                  <Typography display="block">Calculating Quote ...</Typography>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      this.setState({
                        quoteLoading: false,
                        disabled: false,
                      })
                    }
                  >
                    Cancel
                  </Typography>
                </Grid>
              </Grid>
            </Grow>
          ) : (
            <div />
          )}
          {quote && quote.stops && quote.stops[0].date ? (
            <Grid
              container
              spacing={2}
              id="quoteResult"
              ref={(c) => {
                this.quoteResult = c;
              }}
            >
              <Grid item>
                <Typography
                  display="block"
                  variant="h4"
                  classes={{
                    h4: classes.h4,
                  }}
                >
                  Quote Result
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grow in>
                  <Result
                    quoteResult={quote}
                    marketCosts={marketCosts}
                    costBreakdown={costBreakdown}
                    history={history}
                    quoteAccessorials={quote.accessorials}
                    csr={csr}
                  />
                </Grow>
              </Grid>
            </Grid>
          ) : (
            <div />
          )}
          <Dialog
            open={this.state.findOpen}
            onClose={() => this.setState({ findOpen: false })}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <Grid item>
                  <img
                    alt="quote"
                    src={"https://storage.googleapis.com/archer-app/quote.png"}
                  />
                </Grid>
                <Grid item>
                  <Typography display="block" variant="h6">
                    Find a Saved Quote
                  </Typography>
                </Grid>
                <Grid item style={{ minWidth: 300 }}>
                  <TextField
                    inputRef={(c) => {
                      this.findQuoteTextField = c;
                    }}
                    label="Quote Id"
                    onKeyPress={(e) => {
                      if (e.key === "Enter")
                        window.location.replace(
                          `${REACT_APP_FRONT}${QUOTE}?id=${this.state.quoteId}${
                            debug ? "&debug=true" : ""
                          }`
                        );
                    }}
                    className={classes.textField}
                    value={this.state.quoteId}
                    onChange={(e) => this.setState({ quoteId: e.target.value })}
                    margin="normal"
                    fullWidth
                  />
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    Note: Quotes version 1 & 2 will be converted to version 3.
                    <br />
                    The pickup date may be asked.
                  </Typography>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => this.setState({ findOpen: false })}
                color="primary"
              >
                Close
              </Button>
              <Button
                onClick={() => {
                  window.location.replace(
                    `${REACT_APP_FRONT}${QUOTE}?id=${this.state.quoteId}${
                      debug ? "&debug=true" : ""
                    }`
                  );
                }}
                color="primary"
                autoFocus
                variant="raised"
              >
                Find
              </Button>
            </DialogActions>
          </Dialog>
          {/* <Dialog
              open={askPickup}
              onClose={() => this.setState({ findOpen: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent>
                <Grid container direction="column" justify="center" alignItems="center">
                  <Grid item>
                    <img
                      alt="quote"
                      src={'https://storage.googleapis.com/archer-app/convert.png'}
                    />
                  </Grid>
                  <Grid item>
                    <Typography display="block" variant="h6">
                      Pickup Date Required for Quote #{quoteID}
                    </Typography>
                  </Grid>
                  <Grid item style={{ minWidth: 300 }}>
                    <TextField
                      label="Pickup Date"
                      // onKeyPress={(e) => { if (e.key === 'Enter') window.location.replace(`${REACT_APP_FRONT}${QUOTE}?id=${this.state.quoteId}${debug ? '&debug=true' : ''}`); }}
                      className={classes.textField}
                      value={pickupDate}
                      type="date"
                      onChange={e => this.setState({ pickupDate: e.target.value })}
                      margin="normal"
                      fullWidth
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => {
                    this.setState({ askPickup: false });
                    history.push({
                      search: '',
                    });
                  }}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    convertQuote(quoteID, { pickupDate });
                    this.setState({ askPickup: false });
                  }}
                  color="primary"
                  autoFocus
                  variant="raised"
                >
                  Set Date
                </Button>
              </DialogActions>
            </Dialog> */}
          {debug ? (
            <Portal container={MessageCenter.getPortalContainer("right")}>
              <Grid
                container
                style={{
                  width: 320,
                  height: "100vh",
                  overflow: "scroll",
                  background: "#282C34",
                  color: "white",
                  padding: 10,
                }}
              >
                <Grid item>
                  <Typography display="block" color="inherit" variant="h6">
                    Request Body {this.props.quote ? " (Restored)" : ""}
                  </Typography>
                  <div>
                    <link
                      rel="stylesheet"
                      href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.13.1/styles/atom-one-dark.min.css"
                    />
                    <Highlight language={"json"} className={classes.highlight}>
                      {JSON.stringify(body, null, 2)}
                    </Highlight>
                  </div>
                  {quote ? (
                    <React.Fragment>
                      <Typography display="block" color="inherit" variant="h6">
                        Quote Result
                      </Typography>
                      <div>
                        <link
                          rel="stylesheet"
                          href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.13.1/styles/atom-one-dark.min.css"
                        />
                        <Highlight
                          language={"json"}
                          className={classes.highlight}
                        >
                          {JSON.stringify(quote, null, 2)}
                        </Highlight>
                      </div>
                    </React.Fragment>
                  ) : (
                    []
                  )}
                </Grid>
              </Grid>
            </Portal>
          ) : (
            []
          )}
        </div>
      </Page>
    );
  }
}

export default withStyles(styles)(Quote);
