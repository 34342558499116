import { GET } from "constants/methods";

const service = "v1";
const personNames = `/${service}/person_names`;

export function getPersonNames() {
  const url = `${personNames}?filter=%5B%7B%22property%22%3A%22company_id%22%2C%22value%22%3A1%7D%2C%7B%22property%22%3A%22active%22%2C%22value%22%3Atrue%7D%5D`;
  return {
    method: GET,
    url,
  };
}
