import { DAY_SIZE } from "../../constants";

export default (theme) => ({
  container: {
    background: "rgba(155,155,155,0.2)",
    width: DAY_SIZE - 8,
    height: DAY_SIZE - 8,
    display: "flex",
    justifyContent: "center",
    position: "relative",
    alignItems: "center",
    color: theme.palette.type === "dark" ? "white" : "#607d8b",
    transition: "width 0.1s, height 0.1s",
    transitionTimingFunction: "ease-in",
    userSelect: "none",
    // '&:hover': {
    //   width: DAY_SIZE + 5,
    //   height: DAY_SIZE + 5,
    // },
  },
  rightBorderPatch: {
    width: 2,
    height: DAY_SIZE - 12,
    position: "absolute",
    background: "white",
    right: -2,
    top: 0,
    zIndex: 99,
  },
  leftBorderPatch: {
    width: 2,
    height: DAY_SIZE - 12,
    position: "absolute",
    background: "white",
    left: -2,
    top: 0,
    zIndex: 99,
  },
});
