import Fab from "@material-ui/core/Fab";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import AddIcon from "@material-ui/icons/Add";
// material-ui
import withStyles from "@material-ui/styles/withStyles";
import FormLoad from "components/FormLoad";
// components
import LoadsList from "components/LoadsList";
import Page from "components/Page";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperLoadsPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    refresh: PropTypes.func,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,
    loading: PropTypes.bool,

    //loads
    loads: PropTypes.array,
    getLoadById: PropTypes.func,
    createLoad: PropTypes.func,
    deleteLoad: PropTypes.func,
    updateLoad: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
      selectedLoad: undefined,
      formLoadOpen: false,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const { loads } = this.props;

    switch (index) {
      case 0:
        return (
          <LoadsList
            loads={loads}
            onSelect={(selectedLoad) =>
              this.setState({
                selectedLoad,
                formLoadOpen: true,
              })
            }
          />
        );
      default:
    }
  }

  render() {
    const {
      classes,
      history,
      loading,
      createLoad,
      deleteLoad,
      updateLoad,
      refresh,
    } = this.props;

    const { index, selectedLoad, formLoadOpen } = this.state;

    return (
      <Page
        helmet={"Loads"}
        loadingMessage={"Loading Document"}
        loading={loading}
        fab={
          <Fab
            variant="fab"
            color="primary"
            aria-label="Add"
            className={classes.floatingActionButton}
            onClick={() => this.setState({ formLoadOpen: true })}
          >
            <AddIcon />
          </Fab>
        }
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({ index: v });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="Loads"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
        <FormLoad
          open={formLoadOpen}
          load={selectedLoad}
          close={() => {
            this.setState({
              formLoadOpen: false,
            });
            setTimeout(
              () =>
                this.setState({
                  selectedLoad: undefined,
                }),
              200
            );
          }}
          createLoad={createLoad}
          deleteLoad={deleteLoad}
          updateLoad={updateLoad}
          refresh={refresh}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperLoadsPage);
