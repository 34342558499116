import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Business from "@material-ui/icons/Business";
import Close from "@material-ui/icons/Close";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// images
import FindMap from "./find.png";
// styles
import styles from "./styles";

class CustomerFinder extends Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    searchCustomers: PropTypes.func,
    defaultValue: PropTypes.string,
    onChange: PropTypes.func,
    error: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    const { defaultValue } = this.props;
    this.state = {
      searchText: defaultValue && defaultValue.name ? defaultValue.name : "",
      selected: defaultValue,
      archerSuggestions: [],
      customerLoading: false,
      open: false,
    };
  }

  async onCustomerSelect(customer, type) {
    const { onChange } = this.props;
    this.handleClose();
    if (type === "archer") {
      this.setState({
        selected: customer,
        searchText: customer.name,
      });
      onChange({ target: { value: customer.id } });
    }
  }

  getLatLgn(address, cb) {
    this.geocoder.geocode({ address }, (results, status) => {
      cb(results, status);
    });
  }

  getMenuItems() {
    const { theme, maxcustomerAutocompleteItems } = this.props;
    const { archerSuggestions, selected } = this.state;
    const menuItems = [];

    const primaryColor = theme.palette.primary.color[500];

    for (const k in archerSuggestions) {
      if (archerSuggestions.hasOwnProperty(k)) {
        const s = archerSuggestions[k];
        if (s.is_active) {
          menuItems.push(
            <MenuItem
              selected={selected && selected.id === s.id}
              key={s.id}
              onClick={() => this.onCustomerSelect(s, "archer")}
            >
              <Avatar style={{ background: primaryColor, marginRight: 8 }}>
                <Business />
              </Avatar>
              <ListItemText
                primary={`${s.name}`}
                secondary={`${s.line1} ${s.state} ${s.zip}${
                  s.credit_on_hold ? " - (Credit on hold)" : ""
                }${s.inactivity_hold ? " - (Inactivity hold)" : ""}`}
              />
            </MenuItem>
          );
        }
      }
    }

    return menuItems.slice(0, maxcustomerAutocompleteItems);
  }

  handleClick = (event) => {
    const { disabled } = this.props;
    if (disabled) return;
    const { currentTarget } = event;
    this.setState((state) => ({
      anchorEl: currentTarget,
      open: !state.open,
    }));
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleChange(e) {
    const { searchCustomers } = this.props;
    const searchText = e.target.value;
    this.setState({
      searchText,
      customerLoading: true,
      open: true,
    });

    if (searchText === "") {
      this.setState({
        archerSuggestions: [],
        customerLoading: false,
      });
      return;
    }

    // Archer search
    searchCustomers(searchText, 0, 25).then((resp) => {
      this.setState({
        archerSuggestions: resp.payload,
        customerLoading: false,
      });
    });
  }

  render() {
    const { disabled, error, onChange, helperText } = this.props;

    const {
      searchText,
      open,
      anchorEl,
      customerLoading,
      selected,
    } = this.state;

    return (
      <div>
        <Tooltip
          title={
            disabled && selected === undefined
              ? "← A valid Zipcode is Required"
              : ""
          }
        >
          <Grid container spacing={1} alignItems="center">
            {selected ? (
              <Grid item>
                <Business color="primary" />
              </Grid>
            ) : (
              []
            )}
            <Grid
              item
              style={{ width: selected ? "calc(100% - 88px)" : "100%" }}
            >
              <TextField
                error={error}
                aria-owns={open ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={this.handleClick.bind(this)}
                onFocus={this.handleClick.bind(this)}
                disabled={disabled}
                id="zip"
                label="Customer"
                placeholder="Enter Customer"
                fullWidth
                helperText={helperText}
                value={searchText}
                onChange={this.handleChange.bind(this)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {selected ? (
              <Grid item>
                <IconButton
                  disabled={disabled}
                  size="small"
                  onClick={() => {
                    this.setState({
                      selected: undefined,
                      searchText: "",
                      archerSuggestions: [],
                      googleSuggestions: [],
                    });
                    onChange({ target: { value: undefined } });
                  }}
                >
                  <Close />
                </IconButton>
              </Grid>
            ) : (
              []
            )}
          </Grid>
        </Tooltip>
        <Popper
          open={open}
          anchorEl={anchorEl}
          transition
          disablePortal
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: false,
              boundariesElement: "scrollParent",
            },
          }}
          style={{ zIndex: 999, position: "absolute" }}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper
                style={{ width: 480, maxHeight: 274, overflowY: "scroll" }}
                elevation={12}
              >
                <ClickAwayListener onClickAway={this.handleClose.bind(this)}>
                  <MenuList anchorEl={anchorEl}>
                    {!customerLoading ? this.getMenuItems() : <div />}
                    {customerLoading ? (
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justify="center"
                      >
                        <Grid
                          item
                          style={{
                            textAlign: "center",
                            marginTop: 77,
                            marginBottom: 77,
                          }}
                        >
                          <Typography display="block" variant="h6">
                            Loading customers...
                          </Typography>
                          <CircularProgress />
                        </Grid>
                      </Grid>
                    ) : (
                      <div />
                    )}
                    {searchText.length === 0 && !customerLoading ? (
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justify="center"
                      >
                        <Grid
                          item
                          style={{
                            textAlign: "center",
                            marginTop: 67,
                            marginBottom: 67,
                          }}
                        >
                          <img alt="find customer" src={FindMap} height={60} />
                          <Typography display="block" variant="h6">
                            Type a Customer Name...
                          </Typography>
                        </Grid>
                      </Grid>
                    ) : (
                      <div />
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(CustomerFinder);
