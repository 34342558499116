// constants
import { DAY_SIZE } from "../constants";

export default (theme) => ({
  container: {
    padding: 5,
    display: "flex",
    alignItems: "center",
    width: DAY_SIZE * 7,
    flexWrap: "wrap",
  },
  flexEnd: {
    justifyContent: "flex-end",
  },
  wrapper: {
    color: theme.palette.type === "dark" ? "white" : "#607d8b",
    padding: "0px 10px 0px 10px",
  },
});
