import { QUOTE } from "constants/routes";

export function redirectAccordingToRole(accessLevel) {
  // const firstRole = accessLevel[Object.keys(accessLevel)[0]][0];
  // console.log(accessLevel);
  // debugger;
  // console.log(firstRole);
  //
  // try {
  //   let redirectRoute;
  //
  //   switch (firstRole) {
  //     case 'superadmin':
  //       redirectRoute = PROJECT;
  //       break;
  //     case 'basicUser':
  //       redirectRoute = MY_ACCOUNT;
  //       break;
  //     case 'anon':
  //       redirectRoute = MY_ACCOUNT;
  //       break;
  //     default:
  //       redirectRoute = '';
  //   }
  //
  //   return redirectRoute;
  // } catch (e) {
  //
  // }

  return QUOTE;
}
