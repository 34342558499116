import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Portal from "@material-ui/core/Portal";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import Highlight from "react-highlight";
// internal
import AboutLoad from "./AboutLoad";
import Commodities from "./Commodities";
// constants
import {
  ADD,
  CONSIGNEE,
  DEFAULT_BODY,
  LTL,
  REMOVE,
  SHIPPER,
} from "./constants";
import Result from "./Result";
import Stop from "./Stop";
// styles
import styles from "./styles";

class Tender extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    quote: PropTypes.object,
    selected: PropTypes.object,
    searchLocations: PropTypes.func,
    getLoadFiles: PropTypes.func,
    newLocation: PropTypes.func,
    palletCommodities: PropTypes.func,
    holidays: PropTypes.array,
    cancelTender: PropTypes.func,
    tender: PropTypes.func,
    uploadLoadFile: PropTypes.func,
    searchCustomers: PropTypes.func,
    getLocationById: PropTypes.func,
    updateLocation: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
    MessageCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { debug } = this.props;
    const { MessageCenter } = this.context;

    if (debug) {
      MessageCenter.open("right");
    }

    this.state = this.init();
  }

  onChange(bodyPart, callback) {
    this.setState(
      (prevState) => ({
        body: {
          ...prevState.body,
          ...bodyPart,
        },
      }),
      callback
    );
  }

  onStopChange(value, field, stopIndex) {
    const { selected } = this.props;
    const service = selected.element.serviceLevel;
    const { body } = this.state;
    const _body = body;

    if (field === "timeFrom") {
      if (service === "guaranteed") {
        _body.tenderStops[stopIndex].timeTo = value;
      }
      if (service === "premium") {
        const time = moment(value, ["h:m a", "H:m"]);
        if (time._isValid) {
          _body.tenderStops[stopIndex].timeTo = time
            .add(4, "hours")
            .format("HH:mm");
        } else {
          _body.tenderStops[stopIndex].timeTo = "";
        }
      }
    }
    console.log(_body, stopIndex, field, value);

    _body.tenderStops[stopIndex][field] = value;
    this.state.body = _body; //eslint-disable-line
    this.setState({ body: _body });
  }

  handleNext(step) {
    const { errorsFunctions, body } = this.state;
    const { saveForLater } = body;
    if (errorsFunctions[step].errorFunction() && !saveForLater) {
      this.setState({ nextClicked: true });
      setTimeout(() => {
        // console.log('scrolling to ', `step${step}`);
        // console.log(ReactDOM.findDOMNode(this[`step${step}`]));
        ReactDOM.findDOMNode(this[`step${step}`]) &&
          ReactDOM.findDOMNode(this[`step${step}`]).scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
      }, 200);
    } else {
      this.setState((prevState) => ({
        step: prevState.step + 1,
        nextClicked: false,
      }));
      setTimeout(() => {
        // console.log('scrolling to next');
        // console.log(ReactDOM.findDOMNode(this.next));
        ReactDOM.findDOMNode(this.next) &&
          ReactDOM.findDOMNode(this.next).scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
      }, 200);
    }
  }

  init() {
    const { selected, quote } = this.props;
    const body = JSON.parse(JSON.stringify(DEFAULT_BODY));
    body.quoteNumber = selected.element.id;
    body.customerId = quote.customerId;

    for (const k in quote.stops) {
      if (quote.stops.hasOwnProperty(k)) {
        const stop = quote.stops[k];
        const _stop = {
          type: stop.type === "shipper" ? SHIPPER : CONSIGNEE,
          shipConId: stop.id,
          name: stop.name,
          locationId: stop.address.locationId,
          line1: stop.address.line1,
          city: stop.address.city,
          state: stop.address.state,
          zip: stop.address.zip,
          referenceNumber: "",
          confimationNumber: "",
          date: stop.date,
          timeFrom: "",
          timeTo: "",
          contactName: "",
          phone: "",
          notes: "",
        };

        if (selected.element.grouped && stop.type === "consignee") {
          _stop.grouped = true;
          _stop.dateFrom = selected.element.from;
          _stop.dateTo = selected.element.to;
        }

        if (stop.type === "consignee" && !selected.element.grouped) {
          _stop.date = selected.element.from;
        }
        body.tenderStops.push(_stop);
      }
    }
    const stops = body.tenderStops;
    const paths = [];
    const palletDimensions = quote.units.palletDimensions;
    const pickups = stops.filter((s) => s.type === SHIPPER);
    const dropoffs = stops.filter((s) => s.type === CONSIGNEE);

    pickups.forEach((from) => {
      dropoffs.forEach((to) => {
        const _palletDimensions = JSON.parse(JSON.stringify(palletDimensions));
        _palletDimensions.forEach((p) => (p.count = 0));
        paths.push({
          from,
          to,
          notes: "",
          pieces: "",
          pallets:
            stops.length === 2
              ? JSON.parse(JSON.stringify(palletDimensions))
              : _palletDimensions,
        });
      });
    });

    const initState = {
      paths,
      body,
      disabled: false,
      key: new Date().getTime(),
      loading: true,
      tenderLoading: false,
      nextClicked: false,
      errorsFunctions: {},
      step: 0,
      tenderResult: undefined,
      customerName: quote.customerName,
    };

    this.setState(initState);
    return initState;
  }

  gatherErrorFunction(errorHandler, action, step) {
    const { errorsFunctions } = this.state;

    switch (action) {
      case ADD:
        errorsFunctions[step] = errorHandler;
        break;
      case REMOVE:
        delete errorsFunctions[step];
        break;
      default:
        break;
    }
  }

  async tender() {
    const { quote, palletCommodities, tender } = this.props;

    const { errorsFunctions, step, paths, body } = this.state;

    if (errorsFunctions[step].errorFunction()) {
      this.setState({ nextClicked: true });
      setTimeout(() => {
        ReactDOM.findDOMNode(this[`step${step}`]) &&
          ReactDOM.findDOMNode(this[`step${step}`]).scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
      }, 200);
      return;
    }

    const commoditiesCheckerBody = {
      commoditySet: [],
      total: {
        feet: quote.feet,
        weight: Number(quote.units.totalDimensions.totalWeight),
      },
    };

    for (const k in paths) {
      if (paths.hasOwnProperty(k)) {
        const path = paths[k];
        commoditiesCheckerBody.commoditySet.push(
          path.pallets.filter((p) => p.count > 0)
        );
      }
    }
    body.tenderCommodities = [];
    const resp = await palletCommodities(commoditiesCheckerBody);
    if (resp.success) {
      const verifiedPalletCommodities = resp.payload;
      for (const k in paths) {
        if (paths.hasOwnProperty(k)) {
          const path = paths[k];
          const verifiedPallet = verifiedPalletCommodities[k];
          body.tenderCommodities.push({
            shipperID: path.from.shipConId,
            consigneeID: path.to.shipConId,
            pallets: verifiedPallet.pallets,
            feet: verifiedPallet.feet,
            weight:
              verifiedPallet.weight > 0 ? verifiedPallet.weight : undefined,
            pieces: path.pieces,
            notes: path.notes,
          });
        }
      }
    }
    this.setState({
      tenderLoading: true,
      disabled: true,
    });
    this.showTenderMessage();
    const tenderResp = await tender(body);
    if (tenderResp.success) {
      this.showTenderMessage();
      this.setState({
        tenderResult: tenderResp.payload,
      });
      setTimeout(() => {
        ReactDOM.findDOMNode(this.tenderResult) &&
          ReactDOM.findDOMNode(this.tenderResult).scrollIntoView({
            behavior: "smooth",
            block: "nearest",
          });
      }, 400);
    } else {
      this.setState({
        tenderLoading: false,
      });
    }
  }

  showTenderMessage() {
    this.setState({
      tenderLoading: true,
    });
    setTimeout(() => {
      ReactDOM.findDOMNode(this.tenderLoading) &&
        ReactDOM.findDOMNode(this.tenderLoading).scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
    }, 200);
  }

  render() {
    const {
      quote,
      classes,
      selected,
      searchLocations,
      newLocation,
      cancelTender,
      uploadLoadFile,
      history,
      holidays,
      getLoadFiles,
      searchCustomers,
      debug,
      getLocationById,
      updateLocation,
    } = this.props;

    const { MessageCenter } = this.context;

    const {
      key,
      disabled,
      body,
      nextClicked,
      step,
      paths,
      tenderLoading,
      tenderResult,
      customerName,
    } = this.state;

    return (
      <div className={classes.container}>
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={6}>
            <Typography
              display="block"
              variant="h4"
              classes={{
                h4: classes.h4,
              }}
            >
              Tender Load
            </Typography>
            <Typography
              display="block"
              variant="subtitle1"
              color="textSecondary"
            >
              From Quote #{quote.id}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Grid
              container
              direction="row-reverse"
              justify="flex-start"
              alignItems="center"
            >
              <Grid item>
                <Typography
                  display="block"
                  variant="h6"
                  style={{
                    color: selected.element.color,
                    textTransform: "capitalize",
                  }}
                >
                  {selected.element.serviceLevel}
                </Typography>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  {selected.element.description}
                </Typography>
              </Grid>
              <Grid item>
                <img
                  src={selected.element.topLeftIcon}
                  alt="Service Icon"
                  style={{
                    height: 40,
                    paddingRight: 10,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grow in>
              <AboutLoad
                ref={(c) => {
                  this.step0 = c;
                }}
                key={key}
                step={0}
                disabled={disabled}
                onChange={this.onChange.bind(this)}
                nextClicked={nextClicked}
                referenceNumber={body.referenceNumber}
                customerId={body.customerId}
                customerName={customerName}
                notes={body.notes}
                gatherErrorFunction={this.gatherErrorFunction.bind(this)}
                searchCustomers={searchCustomers}
              />
            </Grow>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {body.tenderStops.map((s, index) => {
              return (
                <div
                  key={s.id}
                  ref={(c) => {
                    this[`step${1 + Number(index)}`] = c;
                  }}
                >
                  {step >= Number(index) + 1 ? (
                    <Stop
                      {...s}
                      ltl={quote.type === LTL}
                      holidays={holidays}
                      newLocation={newLocation}
                      nextClicked={nextClicked}
                      searchLocations={searchLocations}
                      disabled={disabled}
                      index={Number(index)}
                      onChange={this.onStopChange.bind(this)}
                      service={selected.element.serviceLevel}
                      gatherErrorFunction={this.gatherErrorFunction.bind(this)}
                      step={1 + Number(index)}
                      getLocationById={getLocationById}
                      updateLocation={updateLocation}
                    />
                  ) : (
                    <div />
                  )}
                </div>
              );
            })}
          </Grid>
          {step > body.tenderStops.length ? (
            <Grid
              item
              xs={12}
              ref={(c) => {
                this[`step${1 + body.tenderStops.length}`] = c;
              }}
            >
              <Commodities
                paths={paths}
                onChange={this.onChange.bind(this)}
                disabled={disabled}
                nextClicked={nextClicked}
                gatherErrorFunction={this.gatherErrorFunction.bind(this)}
                step={1 + Number(body.tenderStops.length)}
                palletDimensions={quote.units.palletDimensions}
                totalDimensions={quote.units.totalDimensions}
              />
            </Grid>
          ) : (
            <div />
          )}
          <Grid item xs={12} style={{ textAlign: "center" }}>
            <Typography display="block" variant="caption" color="textSecondary">
              Inaccurate tender instructions, location address, date, and time
              will void online tender discounts and could lead to additional
              charges such as truck order not used
            </Typography>
          </Grid>
          {disabled !== true ? (
            <Grid item xs={12}>
              <Grid
                container
                className={classes.next}
                justify="center"
                spacing={1}
              >
                <Grid item>
                  <Button
                    ref={(c) => {
                      this.next = c;
                    }}
                    variant="outlined"
                    onClick={() => {
                      if (step === body.tenderStops.length + 1) {
                        this.tender();
                      } else {
                        this.handleNext(step);
                      }
                    }}
                  >
                    {step === body.tenderStops.length + 1
                      ? body.saveForLater
                        ? "Save for Later"
                        : "Tender"
                      : "Next"}
                  </Button>
                </Grid>
                {nextClicked ? (
                  <Grid item>
                    <Button
                      ref={(c) => {
                        this.next = c;
                      }}
                      style={{
                        color: "#f44336",
                        borderColor: "#f44336",
                      }}
                      variant="outlined"
                      onClick={() => {
                        this.onChange({ saveForLater: true }, () =>
                          this.handleNext(step)
                        );
                      }}
                    >
                      Proceed Anyway
                    </Button>
                  </Grid>
                ) : (
                  []
                )}
              </Grid>
            </Grid>
          ) : (
            <div />
          )}
        </Grid>
        {tenderLoading && tenderResult === undefined ? (
          <Grow in>
            <Grid
              id="tenderLoading"
              container
              style={{ height: "100vh" }}
              justify={"center"}
              alignItems={"center"}
              ref={(c) => {
                this.tenderLoading = c;
              }}
            >
              <Grid item style={{ textAlign: "center" }}>
                <CircularProgress />
                <br />
                <Typography display="block">Tendering Load ...</Typography>
              </Grid>
            </Grid>
          </Grow>
        ) : (
          <div />
        )}
        {tenderResult ? (
          <div
            ref={(c) => {
              this.tenderResult = c;
            }}
            style={{
              height: "calc(100vh - 40px)",
              padding: 20,
            }}
          >
            <Result
              quote={quote}
              tenderResult={tenderResult}
              selected={selected}
              cancelTender={cancelTender}
              uploadLoadFile={uploadLoadFile}
              history={history}
              getLoadFiles={getLoadFiles}
            />
          </div>
        ) : (
          <div />
        )}
        {debug ? (
          <Portal container={MessageCenter.getPortalContainer("right")}>
            <Grid
              container
              style={{
                width: 320,
                height: "100vh",
                overflow: "scroll",
                background: "#282C34",
                color: "white",
                padding: 10,
              }}
            >
              <Grid item>
                <Typography display="block" color="inherit" variant="h6">
                  Request Body
                </Typography>
                <div>
                  <link
                    rel="stylesheet"
                    href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.13.1/styles/atom-one-dark.min.css"
                  />
                  <Highlight language={"json"} className={classes.highlight}>
                    {JSON.stringify(body, null, 2)}
                  </Highlight>
                </div>
                {tenderResult ? (
                  <React.Fragment>
                    <Typography display="block" color="inherit" variant="h6">
                      Quote Result
                    </Typography>
                    <div>
                      <link
                        rel="stylesheet"
                        href="https://cdnjs.cloudflare.com/ajax/libs/highlight.js/9.13.1/styles/atom-one-dark.min.css"
                      />
                      <Highlight
                        language={"json"}
                        className={classes.highlight}
                      >
                        {JSON.stringify(tenderResult, null, 2)}
                      </Highlight>
                    </div>
                  </React.Fragment>
                ) : (
                  []
                )}
              </Grid>
            </Grid>
          </Portal>
        ) : (
          []
        )}
      </div>
    );
  }
}

export default withStyles(styles)(Tender);
