import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

// component
import WrapperTenderPage from "../component/WrapperTenderPage";

import FAKE_QUOTE_RESULT from "./FakeQuoteResult.json";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  getQuoteById: (...args) => dispatch(Actions.getQuoteById(...args)),
  searchLocations: (...args) => dispatch(Actions.searchLocations(...args)),
  newLocation: (...args) => dispatch(Actions.newLocation(...args)),
  palletCommodities: (...args) => dispatch(Actions.palletCommodities(...args)),
  tender: (...args) => dispatch(Actions.tender(...args)),
  cancelTender: (...args) => dispatch(Actions.cancelTender(...args)),
  uploadLoadFile: (...args) => dispatch(Actions.uploadLoadFile(...args)),
  getHolidays: (...args) => dispatch(Actions.getHolidays(...args)),
  getLoadFiles: (...args) => dispatch(Actions.getLoadFiles(...args)),
  searchCustomers: (...args) => dispatch(Actions.searchCustomers(...args)),
  getLocationById: (...args) => dispatch(Actions.getLocationById(...args)),
  updateLocation: (...args) => dispatch(Actions.updateLocation(...args)),
});
class TenderPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,
    searchLocations: PropTypes.func,
    newLocation: PropTypes.func,
    palletCommodities: PropTypes.func,
    tender: PropTypes.func,
    cancelTender: PropTypes.func,
    uploadLoadFile: PropTypes.func,
    getQuoteById: PropTypes.func,
    getHolidays: PropTypes.func,
    getLoadFiles: PropTypes.func,
    searchCustomers: PropTypes.func,
    getLocationById: PropTypes.func,
    updateLocation: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location } = this.props;
    const urlParams = getJsonFromUrl(location);

    this.state = {
      loading: true,
      quoteID: Number(urlParams.quoteID),
      debug: urlParams.debug === "true",
      selected: JSON.parse(decodeURIComponent(urlParams.selected)),
    };

    this.refresh();
  }

  getQuoteById() {
    return new Promise((resolve) => {
      setTimeout(
        () =>
          resolve({
            success: true,
            payload: FAKE_QUOTE_RESULT,
          }),
        800
      );
    });
  }

  async refresh() {
    const { getQuoteById, getHolidays } = this.props;
    const { quoteID } = this.state;
    const resps = await Promise.all([getQuoteById(quoteID), getHolidays()]);

    const quote = resps[0].payload;
    const holidays = resps[1].payload;
    quote.units.palletDimensions.forEach((p, i) => (p.id = i));
    this.setState({
      loading: false,
      quote,
      holidays,
    });
  }

  render() {
    const {
      location,
      history,
      searchLocations,
      newLocation,
      palletCommodities,
      tender,
      cancelTender,
      uploadLoadFile,
      getLoadFiles,
      searchCustomers,
      getLocationById,
      updateLocation,
    } = this.props;

    const { urlParams, quote, selected, loading, holidays, debug } = this.state;

    return (
      <WrapperTenderPage
        palletCommodities={palletCommodities}
        searchLocations={searchLocations}
        newLocation={newLocation}
        history={history}
        location={location}
        urlParams={urlParams}
        quote={quote}
        selected={selected}
        loading={loading}
        tender={tender}
        cancelTender={cancelTender}
        uploadLoadFile={uploadLoadFile}
        getLoadFiles={getLoadFiles}
        searchCustomers={searchCustomers}
        holidays={holidays}
        getLocationById={getLocationById}
        updateLocation={updateLocation}
        debug={debug}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TenderPage);
