import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { formattedPrice } from "../helpers";
// styles
import styles from "./styles";

class MarketCosts extends Component {
  static propTypes = {
    classes: PropTypes.object,
    marketCosts: PropTypes.object,
  };

  render() {
    const { marketCosts } = this.props;
    console.log(marketCosts);

    return (
      <Grid
        container
        spacing={2}
        style={{
          padding: 20,
        }}
      >
        <Grid item xs={12} md={4} lg={3}>
          <Grid container justify="space-between">
            <Grid item xs={8}>
              <Typography display="block">
                <b>+</b> Linehaul Cost
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <Typography display="block" color="primary">
                <b>{formattedPrice(marketCosts.linehaul_cost.toFixed(2))}</b>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography display="block">
                <b>+</b> Fuel Cost
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <Typography display="block" color="primary">
                <b>{formattedPrice(marketCosts.fuel_cost.toFixed(2))}</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={8}>
              <Typography display="block">
                <b>=</b> TL Total Cost
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <Typography display="block" color="primary">
                <b>
                  {formattedPrice(marketCosts.truckload_total_cost.toFixed(2))}
                </b>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography display="block">
                <b>x</b> LTL Pallet Percentage
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <Typography display="block" color="primary">
                <b>{marketCosts.ltl_pallet_percentage}</b>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography display="block">
                <b>+</b> Stop Off Fees
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <Typography display="block" color="primary">
                <b>{formattedPrice(marketCosts.stop_fees.toFixed(2))}</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={8}>
              <Typography display="block">
                <b>=</b> Total Cost
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <Typography display="block" color="primary">
                <b>{formattedPrice(marketCosts.total_cost.toFixed(2))}</b>
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography display="block">
                <b>+</b> Margin
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <Typography display="block" color="primary">
                <b>{formattedPrice(marketCosts.margin.toFixed(2))}</b>
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={8}>
              <Typography display="block">
                <b>=</b> Total Cost With Margin
              </Typography>
            </Grid>
            <Grid item xs={4} style={{ textAlign: "right" }}>
              <Typography display="block" color="primary">
                <b>
                  {formattedPrice(
                    marketCosts.total_cost_with_margin.toFixed(2)
                  )}
                </b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        {marketCosts.market_data ? (
          <Grid item xs={12} md={4}>
            <Grid container justify="space-between">
              <Grid item xs={8}>
                <Typography display="block">• Miles</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography display="block" color="primary">
                  <b>{marketCosts.otr_miles}</b>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography display="block">• Fuel Rate per Mile</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography display="block" color="primary">
                  <b>
                    {formattedPrice(
                      marketCosts.market_data.fuel_rate.toFixed(2)
                    )}
                  </b>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography display="block">
                  • Line Haul Rate per Mile
                </Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography display="block" color="primary">
                  <b>
                    {formattedPrice(
                      marketCosts.market_data.linehaul_rate.toFixed(2)
                    )}
                  </b>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography display="block">• Line Haul Total</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography display="block" color="primary">
                  <b>
                    {formattedPrice(
                      marketCosts.market_data.linehaul_total.toFixed(2)
                    )}
                  </b>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography display="block">• Market Type</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography display="block" color="primary">
                  <b>{marketCosts.market_data.market_type}</b>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography display="block">• Market Lane</Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: "right" }}>
                <Typography display="block" color="primary">
                  <b>{marketCosts.market_data.market_lane}</b>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography display="block">• Market Days</Typography>
              </Grid>
              <Grid item xs={4} style={{ textAlign: "right" }}>
                <Typography display="block" color="primary">
                  <b>{marketCosts.market_data.market_days}</b>
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          []
        )}
      </Grid>
    );
  }
}

export default withStyles(styles)(MarketCosts);
