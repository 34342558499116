export default (theme) => ({
  container: {
    margin: "auto",
    maxWidth: 1200,
  },
  h4: {
    fontWeight: 700,
  },
  fab: {
    position: "fixed",
    right: 20,
    bottom: 20,
  },
  highlight: {
    width: 290,
  },
  speedDialAction: {
    background: "#212121",
    color: "white",
    "&:hover": {
      background: "#424242",
      color: "white",
    },
  },
  shortcut: {
    fontSize: 12,
    color: "#424242",
    background: "white",
    borderRadius: 12,
    padding: "2px 4px 2px 4px",
  },
});
