import { LOADS } from "constants/routes";
import { checkAccess } from "helpers/auth";
import LoadsPage from "./container/LoadsPage";

export default function getRoute(userAuth) {
  const requireAuth = {};
  return {
    path: LOADS,
    requireAuth,
    onEnter: () => checkAccess(requireAuth, userAuth),
    withSidebar: true,
    withAppBar: true,
    component: LoadsPage,
  };
}
