import { GET } from "constants/methods";

const service = "v1";
const quotes = `/${service}/quote_extra_lites`;
const commodityDescriptions = `/${service}/commodity_descriptions`;
const path = `/${service}/quote_path_edge_costs`;
const costs = `/${service}/quotes`;

export function getQuotes(filter) {
  const url = `${quotes}?page=1&start=0&limit=36${
    filter ? `&filter=${JSON.stringify(filter)}` : ""
  }`;
  return {
    method: GET,
    url,
  };
}

export function getCostBreakdown(quoteID) {
  const url = `${path}?filter=[{"property":"quote_path_edges.quote_id","value":${quoteID}}]`;
  return {
    method: GET,
    url,
  };
}

export function getMarketCosts(quoteID) {
  const url = `${costs}/${quoteID}/market_costs`;
  return {
    method: GET,
    url,
  };
}

export function getCommodityDescriptions() {
  const url = `${commodityDescriptions}`;
  return {
    method: GET,
    url,
  };
}
