export default (theme) => ({
  container: {
    padding: 5,
  },
  stop: {
    width: 230,
  },
  manage: {
    width: 70,
  },
});
