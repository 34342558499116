export const routeNames = [
  "Default",
  "MyAccount",
  "Signin",
  "Demo",
  "Debug",
  "Quote",
  "Quotes",
  "Tender",
];
