export const DEFAULT_BODY = {
  quoteNumber: "",
  referenceNumber: "",
  notes: "",
  tenderStops: [],
  tenderCommodities: [],
};
export const REMOVE = "REMOVE";
export const ADD = "ADD";
export const CONSIGNEE = "Drop-off";
export const SHIPPER = "Pick-up";
export const TL = "TL";
export const LTL = "LTL";
export const STANDARD_IM_SERVICE = "Standard IM Service";
export const MAX_LOCATION_AUTOCOMPLETE_ITEMS = 8;
