import { networkAction } from "helpers/network/networkAction";

import {
  GET_QUOTE_BY_ID,
  NEW_QUOTE,
  CONVERT_QUOTE,
  GET_COST_BREAKDOWN,
  GET_MARKET_COSTS,
  GET_QUOTES_V3,
  GET_COMMODITY_DESCRIPTIONS,
} from "constants/quotes";

import * as Api from "api";

export const getQuoteById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_QUOTE_BY_ID, Api.getQuoteById, [id]);

export const getQuotesV3 = (filters, offset, limit) => async (dispatch) =>
  networkAction(dispatch, GET_QUOTES_V3, Api.getQuotesV3, [
    filters,
    offset,
    limit,
  ]);

export const newQuote = (body) => async (dispatch) =>
  networkAction(dispatch, NEW_QUOTE, Api.newQuote, [body]);

export const convertQuote = (quoteID, body) => async (dispatch) =>
  networkAction(dispatch, CONVERT_QUOTE, Api.convertQuote, [quoteID, body]);

export const getCostBreakdown = (quoteID) => async (dispatch) =>
  networkAction(dispatch, GET_COST_BREAKDOWN, Api.getCostBreakdown, [quoteID]);

export const getMarketCosts = (quoteID) => async (dispatch) =>
  networkAction(dispatch, GET_MARKET_COSTS, Api.getMarketCosts, [quoteID]);

export const getCommodityDescriptions = () => async (dispatch) =>
  networkAction(
    dispatch,
    GET_COMMODITY_DESCRIPTIONS,
    Api.getCommodityDescriptions,
    []
  );
