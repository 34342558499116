import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Paper from "@material-ui/core/Paper";
import Select from "@material-ui/core/Select";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import AttachFile from "@material-ui/icons/AttachFile";
import AttachMoney from "@material-ui/icons/AttachMoney";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Receipt from "@material-ui/icons/Receipt";
// material-ui
import { withStyles } from "@material-ui/styles";
// components
import Help from "components/Help";
import { QUOTE } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// internal
import Card from "../Card";
// helpers
import { formattedPrice } from "../helpers";
// styles
import styles from "./styles";

class Result extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
    theme: PropTypes.object,
    tenderResult: PropTypes.object,
    selected: PropTypes.object,
    quote: PropTypes.object,
    cancelTender: PropTypes.func,
    getLoadFiles: PropTypes.func,
    uploadLoadFile: PropTypes.func,
  };

  static contextTypes = {
    UploadCenter: PropTypes.object,
    NotificationCenter: PropTypes.object,
  };

  state = {
    dialogOpen: false,
    uploadedFiles: [],
  };

  componentWillMount() {
    const { UploadCenter } = this.context;
    UploadCenter.register(
      this.upload.bind(this),
      this.userDidDrop.bind(this),
      this.callback.bind(this),
      ""
    );
    this.getLoadFiles();
  }

  async getLoadFiles() {
    const { getLoadFiles, tenderResult } = this.props;
    const resp = await getLoadFiles(tenderResult.loadId);
    if (resp.success) {
      this.setState({ uploadedFiles: resp.payload });
    }
  }

  userDidDrop(files) {
    files.forEach((f) => (f.description = ""));
    return new Promise((resolve) => {
      this.setState({
        resolve,
        files,
        dialogOpen: true,
      });
    });
  }

  async upload() {
    const { uploadLoadFile, tenderResult } = this.props;
    const { files } = this.state;
    this.closeDialog();

    await new Promise(async (r) => {
      const funcs = [];
      for (const k in files) {
        if (files.hasOwnProperty(k)) {
          const file = files[k];
          funcs.push(
            uploadLoadFile(tenderResult.loadId, file, file.description)
          );
        }
      }

      await Promise.all(funcs);
      r();
    });
    this.getLoadFiles();
  }

  callback() {
    const { NotificationCenter } = this.context;
    const { files } = this.state;
    NotificationCenter.sweetAlert(
      {
        title: "Success",
        success: true,
        body: `Your file${files.length > 1 ? "s" : ""} ${
          files.length > 1 ? "have" : "has"
        } been uploaded`,
        timestamp: new Date().getTime(),
      },
      {
        confirm: {
          label: "Ok",
          level: "success",
        },
      }
    );

    setTimeout(NotificationCenter.hide, 2000);
  }

  cancelTender() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "Your load will be canceled. You won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Dismiss",
          level: "default",
        },
        confirm: {
          label: "Yes, Cancel it",
          level: "error",
          callback: this.confirmCancelLoad.bind(this),
        },
      }
    );
  }

  async confirmCancelLoad() {
    const { cancelTender, tenderResult } = this.props;
    const { NotificationCenter } = this.context;
    const resp = await cancelTender(tenderResult.id);
    if (resp.success) {
      NotificationCenter.sweetAlert(
        {
          title: `Load #${tenderResult.loadId} (${tenderResult.referenceNumber}) has been canceled.`,
          timestamp: new Date().getTime(),
          success: true,
        },
        {
          cancel: {
            label: "Close",
            level: "default",
          },
        }
      );
    }
  }

  openDialog() {
    this.setState({ dialogOpen: true });
  }

  closeDialog() {
    this.setState({ dialogOpen: false, resolve: undefined });
  }

  render() {
    const { classes, tenderResult, selected, theme, history } = this.props;

    const { UploadCenter } = this.context;

    const { dialogOpen, files, resolve, uploadedFiles } = this.state;

    const primaryColor = theme.palette.primary.color[500];
    const disabled =
      files && files.find((f) => f.description === "") !== undefined;

    return (
      <Grow in>
        <Grid id="tenderResut" container spacing={2} justify="center">
          <Grid item xs={12}>
            <Typography
              display="block"
              variant="h4"
              classes={{
                h4: classes.h4,
              }}
            >
              You are all set !
            </Typography>
            <Typography
              display="block"
              variant="subtitle1"
              color="textSecondary"
            >
              Load #{tenderResult.loadId} ({tenderResult.referenceNumber}) have
              been created.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              nextClicked={false}
              error={false}
              primaryColor={primaryColor}
              disabled={false}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <AttachMoney />
                </Grid>
                <Grid item>
                  <Typography display="block" variant="h6">
                    Initial Quote
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} justify="space-between">
                <Grid item xs={6}>
                  <Typography display="block">Base Rate</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography display="block">
                    {formattedPrice(selected.element.baseRate)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography display="block">Fuel Rate</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography display="block">
                    {formattedPrice(selected.element.fuelRate)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <hr />
                </Grid>
                <Grid item xs={6}>
                  <Typography display="block">Total Rate</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography display="block" variant="title">
                    {formattedPrice(selected.element.totalRate)}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} md={6}>
            <Card
              nextClicked={false}
              error={false}
              primaryColor={primaryColor}
              disabled={false}
            >
              <Grid container spacing={2} alignItems="center">
                <Grid item>
                  <Receipt />
                </Grid>
                <Grid item>
                  <Typography display="block" variant="h6">
                    Tender
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} justify="space-between">
                <Grid item xs={6}>
                  <Typography display="block">Base Rate</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography display="block">
                    {formattedPrice(tenderResult.baseRate)}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography display="block">Fuel Rate</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography display="block">
                    {formattedPrice(tenderResult.fuelRate)}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <hr />
                </Grid>
                <Grid item xs={6}>
                  <Typography display="block">Total Rate</Typography>
                </Grid>
                <Grid item xs={6} style={{ textAlign: "right" }}>
                  <Typography display="block" variant="title">
                    {formattedPrice(tenderResult.totalRate)}
                  </Typography>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          {uploadedFiles.length ? (
            <Grid item md={12}>
              <Card
                nextClicked={false}
                error={false}
                primaryColor={primaryColor}
                disabled={false}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <AttachFile />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography display="block" variant="h6">
                      Uploaded Files
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell align="right">Description</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {uploadedFiles.map((f) => (
                          <TableRow key={f.id}>
                            <TableCell component="th" scope="row">
                              {f.name}
                            </TableCell>
                            <TableCell align="right">{f.description}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          ) : (
            <div />
          )}
          <Help explanation="Legacy Archer is tab where CSR's would upload all necessary documents before finalizing Load.">
            <Grid
              item
              xs={6}
              style={{
                border: "dotted 1px #9e9e9e",
                borderRadius: 5,
                textAlign: "center",
                minWidth: 400,
              }}
            >
              <CloudUpload
                style={{ width: 60, height: 60 }}
                color="textSecondary"
              />
              <br />
              <Typography display="block" variant="h6">
                Having files to send ?
              </Typography>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                Drag and drop them on the page.
              </Typography>
              <Chip
                label="Browse Files"
                onClick={UploadCenter.openFileDialog}
              />
            </Grid>
          </Help>
          <Grid item xs={12} style={{ paddingTop: 30 }}>
            <Grid container justify="center" spacing={2}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={this.cancelTender.bind(this)}
                >
                  Cancel Load
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push(QUOTE)}
                >
                  New Quote
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Dialog
            open={dialogOpen}
            onClose={this.closeDialog.bind(this)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Uploading {files && files.length} File
              {files && files.length > 1 ? "s" : ""}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                For each file, please specify the document type :
              </DialogContentText>
              {files &&
                files.map((f, k) => (
                  <Paper
                    key={f.name}
                    style={{
                      padding: 10,
                      marginBottom: 10,
                    }}
                  >
                    <Grid
                      container
                      spacing={2}
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={12}>
                        {f.name}
                      </Grid>
                      <Grid item xs={12}>
                        <FormControl className={classes.formControl} fullWidth>
                          <InputLabel htmlFor="file-simple">
                            Document Type
                          </InputLabel>
                          <Select
                            inputProps={{
                              name: "Document Type",
                              id: "file-simple",
                            }}
                            value={f.description}
                            onChange={(e) => {
                              files[k].description = e.target.value;
                              this.setState({ files });
                            }}
                          >
                            <MenuItem value={"Work Order"}>Work Order</MenuItem>
                            <MenuItem value={"Rate Confirmation"}>
                              Rate Confirmation
                            </MenuItem>
                            <MenuItem value={"PickUp Document"}>
                              PickUp Document
                            </MenuItem>
                            <MenuItem value={"POD"}>POD</MenuItem>
                            <MenuItem value={"Daily POD"}>Daily POD</MenuItem>
                            <MenuItem value={"Pending POD"}>
                              Pending POD
                            </MenuItem>
                            <MenuItem value={"Invoice"}>Invoice</MenuItem>
                            <MenuItem value={"Revised Invoice"}>
                              Revised Invoice
                            </MenuItem>
                            <MenuItem value={"Accounting"}>Accounting</MenuItem>
                            <MenuItem value={"Accessorial Confirmation Backup"}>
                              Accessorial Confirmation Backup
                            </MenuItem>
                            <MenuItem value={"Revised Load Tender Backup"}>
                              Revised Load Tender Backup
                            </MenuItem>
                            <MenuItem value={"Load Pictures"}>
                              Load Pictures
                            </MenuItem>
                            <MenuItem value={"Packing List"}>
                              Packing List
                            </MenuItem>
                            <MenuItem value={"Claims - Documents"}>
                              Claims - Documents
                            </MenuItem>
                            <MenuItem value={"Claims - Acknowledgement Letter"}>
                              Claims - Acknowledgement Letter
                            </MenuItem>
                            <MenuItem value={"Claims - Denial Letter"}>
                              Claims - Denial Letter
                            </MenuItem>
                            <MenuItem value={"Claims - Release Letter"}>
                              Claims - Release Letter
                            </MenuItem>
                            <MenuItem value={"Claims - Damage Pictures"}>
                              Claims - Damage Pictures
                            </MenuItem>
                            <MenuItem value={"Claims - Disposition Letter"}>
                              Claims - Disposition Letter
                            </MenuItem>
                            <MenuItem value={"Claims - Donation Letter"}>
                              Claims - Donation Letter
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  this.setState({
                    files: undefined,
                    resolve: undefined,
                    dialogOpen: false,
                  });
                }}
              >
                Cancel
              </Button>
              <Button
                onClick={resolve}
                color="primary"
                variant="contained"
                autoFocus
                disabled={disabled}
              >
                Upload
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grow>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Result);
