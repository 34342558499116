import { POST, GET, PUT } from "constants/methods";

const service = "v3/quote-service";
const quotes = `/${service}/quotes`;

export function newQuote(body) {
  const url = `${quotes}/multistop`;
  return {
    service,
    method: POST,
    body,
    url,
  };
}

export function getQuotesV3(filters, offset, limit) {
  const url = `/v3/sales/quotes${
    filters ? `?filters=${JSON.stringify(filters)}` : ""
  }${offset ? `&offset=${offset}` : ""}${
    limit ? `&limit=${limit}` : "&limit=100"
  }`;
  return {
    service,
    method: GET,
    url,
  };
}

export function getQuoteById(quoteID) {
  const url = `${quotes}/${quoteID}`;
  return {
    service,
    method: GET,
    url,
  };
}

export function convertQuote(quoteID, body) {
  const url = `${quotes}/${quoteID}/pickupDate`;
  return {
    service,
    method: PUT,
    url,
    body,
  };
}
