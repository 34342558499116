// material-ui
import { withStyles } from "@material-ui/styles";
// custom
import Table from "components/Table";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class LoadsList extends Component {
  static propTypes = {
    classes: PropTypes.object,
    loads: PropTypes.array,
    onSelect: PropTypes.func,
  };

  onSelect(selectedLoad) {
    const { onSelect } = this.props;
    this.setState({ selectedLoad });
    if (onSelect) {
      onSelect(selectedLoad);
    }
  }

  render() {
    const { loads } = this.props;

    return (
      <Table
        data={loads}
        meta={[
          {
            path: "name",
            title: "Name",
            numeric: false,
            hidden: { mdDown: true },
          },
        ]}
        title={"Loads"}
        onRowSelect={this.onSelect.bind(this)}
      />
    );
  }
}

export default withStyles(styles)(LoadsList);
