import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// routes
import { QUOTE } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperDefaultPage extends Component {
  static propTypes = {
    classes: PropTypes.object,
    history: PropTypes.object,
  };

  render() {
    const { classes, history } = this.props;

    return (
      <Grid
        container
        alignItems="center"
        justify="center"
        className={classes.container}
      >
        <Grid item style={{ textAlign: "center" }}>
          <Typography display="block" variant="h1">
            <span role="img" aria-label="tata" aria-labelledby="tata">
              💁🏻‍♂️
            </span>
          </Typography>
          <Typography display="block" variant="h2" style={{ fontWeight: 600 }}>
            Oooooops !
          </Typography>
          <Typography display="block" variant="h6">
            Quote not found.
          </Typography>
          <br />
          <Typography display="block">
            The intern must have lost it again !!!
          </Typography>
          <br />
          <Button
            onClick={() => history.push(QUOTE)}
            variant="outlined"
            color="primary"
          >
            Back to Quote Form
          </Button>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(WrapperDefaultPage);
