import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import Typography from "@material-ui/core/Typography";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import CalendarIcon from "@material-ui/icons/CalendarToday";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Info from "@material-ui/icons/Info";
import InsertChart from "@material-ui/icons/InsertChart";
import ListAlt from "@material-ui/icons/ListAlt";
import LocalShipping from "@material-ui/icons/LocalShipping";
// material-ui
import { withStyles } from "@material-ui/styles";
// components
import Help from "components/Help";
// constant
import { TENDER } from "constants/routes";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
// reactor
import Calendar from "../Calendar";
import Card from "../Card";
import CostBreakdown from "../CostBreakdown";
// helpers
import { formattedPrice } from "../helpers";
import MarketCosts from "../MarketCosts";
// styles
import styles from "./styles";

const serviceConfig = {
  guaranteed: {
    color: "#f44336",
    topLeftIcon: "https://storage.googleapis.com/archer-app/guaranteed.png",
  },
  premium: {
    color: "#ffc107",
    topLeftIcon: "https://storage.googleapis.com/archer-app/premium.png",
  },
  economy: {
    color: "#4caf50",
    topLeftIcon: "https://storage.googleapis.com/archer-app/economy.png",
  },
};

class Result extends Component {
  static propTypes = {
    theme: PropTypes.object,
    disabled: PropTypes.bool,
    quoteResult: PropTypes.object,
    history: PropTypes.object,
    quoteAccessorials: PropTypes.array,
    costBreakdown: PropTypes.array,
    csr: PropTypes.bool,
    marketCosts: PropTypes.object,
  };

  state = {
    selected: undefined,
    flexible: undefined,
    floorLoaded: undefined,
    appoitnment: undefined,
  };

  getFilterResult() {
    const { quoteResult } = this.props;

    const { floorLoaded, flexible, appoitnment } = this.state;

    const days = [];

    if (flexible === "Yes" || appoitnment === "Strict") {
      // Guaranteed Only
      for (const k in quoteResult.serviceLevels) {
        if (quoteResult.serviceLevels.hasOwnProperty(k)) {
          const service = quoteResult.serviceLevels[k];
          const config = serviceConfig[service.serviceLevel];
          if (service.serviceLevel === "guaranteed") {
            days.push({ ...service, ...config });
          }
        }
      }
      return days;
    }

    if (floorLoaded === "Yes" || appoitnment === "4h") {
      // Guaranteed & Premium Only
      for (const k in quoteResult.serviceLevels) {
        if (quoteResult.serviceLevels.hasOwnProperty(k)) {
          const service = quoteResult.serviceLevels[k];
          const config = serviceConfig[service.serviceLevel];
          if (service.serviceLevel !== "economy") {
            days.push({ ...service, ...config });
          }
        }
      }
      return days;
    }

    for (const k in quoteResult.serviceLevels) {
      if (quoteResult.serviceLevels.hasOwnProperty(k)) {
        const service = quoteResult.serviceLevels[k];
        const config = serviceConfig[service.serviceLevel];
        service.grouped = service.flexible;
        days.push({ ...service, ...config });
      }
    }

    return days;
  }

  render() {
    const {
      disabled,
      quoteResult,
      history,
      csr,
      marketCosts,
      quoteAccessorials,
      costBreakdown,
    } = this.props;

    const { floorLoaded, flexible, appoitnment } = this.state;

    const { selected } = this.state;
    const firstDropOff = quoteResult.stops.find((s) => s.type === "consignee");

    const days = this.getFilterResult();

    console.log(marketCosts);

    return (
      <div style={{ paddingBottom: "50vh" }}>
        {costBreakdown && costBreakdown.length ? (
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container alignItems="center">
                <Grid item>
                  <ListAlt />
                </Grid>
                <Grid item>
                  <Typography display="block" variant="h6">
                    Cost Breakdown
                  </Typography>
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <CostBreakdown costBreakdown={costBreakdown} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : (
          []
        )}
        {marketCosts ? (
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container alignItems="center">
                <Grid item>
                  <InsertChart />
                </Grid>
                <Grid item>
                  <Typography display="block" variant="h6">
                    Market Costs
                  </Typography>
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <MarketCosts marketCosts={marketCosts} />
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : (
          []
        )}
        {quoteAccessorials ? (
          <ExpansionPanel>
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Grid container alignItems="center">
                <Grid item>
                  <AddBox />
                </Grid>
                <Grid item>
                  <Typography display="block" variant="h6">
                    Accessorials
                  </Typography>
                </Grid>
              </Grid>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container style={{ width: "100%" }}>
                {quoteAccessorials.map((qa) => (
                  <Grid item>
                    <Typography display="block" variant="caption">
                      {qa.stopName}
                    </Typography>
                    <Chip
                      label={`${qa.name}, ${formattedPrice(qa.costAmount)}`}
                    />
                  </Grid>
                ))}
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        ) : (
          []
        )}
        <br />
        <Card
          nextClicked={false}
          error={false}
          primaryColor={"#FFFFFF"}
          disabled={disabled}
        >
          <Grid container spacing={2} justify="space-between">
            <Grid item>
              <Grid container alignItems="center">
                <Grid item>
                  <CalendarIcon />
                </Grid>
                <Grid item>
                  <Typography display="block" variant="h6">
                    Drop-off Date
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Typography display="block">
                Quote ID: <b>{quoteResult.id}</b>
              </Typography>
            </Grid>
          </Grid>
          <Help explanation="Optional questions that help you to identify the correct service level you need.">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  display="block"
                  variant="body"
                  color="textSecondary"
                >
                  Before choosing the drop-off date, please answer the following
                  questions:
                </Typography>
              </Grid>
              <Grid item>
                <Chip label="Is Your Load Floor Loaded ?" />
              </Grid>
              <Grid item>
                <Chip
                  color="primary"
                  label="Yes"
                  onClick={() => this.setState({ floorLoaded: "Yes" })}
                  variant={floorLoaded === "Yes" ? "contained" : "outlined"}
                />
              </Grid>
              <Grid item>
                <Chip
                  color="primary"
                  label="No"
                  onClick={() => this.setState({ floorLoaded: "No" })}
                  variant={floorLoaded === "No" ? "contained" : "outlined"}
                />
              </Grid>
              <Grid item>
                <Chip
                  color="primary"
                  label="Don't Know"
                  onClick={() => this.setState({ floorLoaded: "None" })}
                  variant={floorLoaded === "None" ? "contained" : "outlined"}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <Chip label="Is the Drop-off Date flexible" />
              </Grid>
              <Grid item>
                <Chip
                  color="primary"
                  label="Yes"
                  onClick={() => this.setState({ flexible: "No" })}
                  variant={flexible === "No" ? "contained" : "outlined"}
                />
              </Grid>
              <Grid item>
                <Chip
                  color="primary"
                  label="No"
                  onClick={() => this.setState({ flexible: "Yes" })}
                  variant={flexible === "Yes" ? "contained" : "outlined"}
                />
              </Grid>
              <Grid item>
                <Chip
                  color="primary"
                  label="Don't Know"
                  onClick={() => this.setState({ flexible: "None" })}
                  variant={flexible === "None" ? "contained" : "outlined"}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item>
                <Chip label="What Kind of Appointment Do You Need ?" />
              </Grid>
              <Grid item>
                <Chip
                  color="primary"
                  label="FCFS (Cheapest)"
                  onClick={() => this.setState({ appoitnment: "FCFS" })}
                  variant={appoitnment === "FCFS" ? "contained" : "outlined"}
                />
              </Grid>
              <Grid item>
                <Chip
                  color="primary"
                  label="4h"
                  onClick={() => this.setState({ appoitnment: "4h" })}
                  variant={appoitnment === "4h" ? "contained" : "outlined"}
                />
              </Grid>
              <Grid item>
                <Chip
                  color="primary"
                  label="Strict (More Expensive)"
                  onClick={() => this.setState({ appoitnment: "Strict" })}
                  variant={appoitnment === "Strict" ? "contained" : "outlined"}
                />
              </Grid>
              <Grid item>
                <Chip
                  color="primary"
                  label="Don't Know"
                  onClick={() => this.setState({ appoitnment: "None" })}
                  variant={appoitnment === "None" ? "contained" : "outlined"}
                />
              </Grid>
            </Grid>
          </Help>
          {(flexible && appoitnment && floorLoaded) || csr ? (
            <Grow in>
              <Grid
                container
                spacing={2}
                alignItems="center"
                style={{ marginTop: 20 }}
              >
                {/* <Calendar
                        serviceLevel={days[1]}
                        pickupDate={quoteResult.stops[0].date}
                        onSelect={(s) => {
                          this.setState({ selected: s });
                          setTimeout(() => {
                            ReactDOM.findDOMNode(this.result).scrollIntoView({ behavior: 'smooth', block: 'nearest' });
                          }, 200);
                        }}
                        selected={selected}
                      /> */}
                {days.map((d) => (
                  <Grid item key={d.id}>
                    <Help explanation="Click on the day or date range that fit.">
                      <div
                        style={{
                          opacity:
                            selected &&
                            selected.element.serviceLevel !== d.serviceLevel
                              ? 0.3
                              : undefined,
                        }}
                      >
                        <Calendar
                          serviceLevel={d}
                          pickupDate={quoteResult.stops[0].date}
                          onSelect={(s) => {
                            this.setState({ selected: s });
                            setTimeout(() => {
                              ReactDOM.findDOMNode(this.result) &&
                                ReactDOM.findDOMNode(
                                  this.result
                                ).scrollIntoView({
                                  behavior: "smooth",
                                  block: "nearest",
                                });
                            }, 200);
                          }}
                          selected={selected}
                        />
                      </div>
                    </Help>
                  </Grid>
                ))}
              </Grid>
            </Grow>
          ) : (
            <div />
          )}
        </Card>
        {selected ? (
          <Card
            nextClicked={false}
            error={false}
            primaryColor={"#FFFFFF"}
            disabled={disabled}
            ref={(c) => {
              this.result = c;
            }}
          >
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <LocalShipping />
              </Grid>
              <Grid item>
                <Typography display="block" variant="h6">
                  Summary
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography display="block" variant="subtitle1" color="primary">
                  About
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Quote ID
                </Typography>
                <Typography display="block">{quoteResult.id}</Typography>
              </Grid>
              <Grid item>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Service
                </Typography>
                <Typography display="block">{quoteResult.type}</Typography>
              </Grid>
              <Grid item>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Mode
                </Typography>
                <Typography display="block">
                  {quoteResult.temp && quoteResult.temp.length
                    ? "Reefer"
                    : "Dry"}
                </Typography>
              </Grid>
              {quoteResult.temp && quoteResult.temp.length ? (
                <Grid item>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    Temperature
                  </Typography>
                  <Typography display="block">{quoteResult.temp}</Typography>
                </Grid>
              ) : (
                <div />
              )}
              <Grid item>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Commodity Description
                </Typography>
                <Typography display="block">
                  {quoteResult.commodityDescription}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Total Weight
                </Typography>
                <Typography display="block">
                  {quoteResult.weight} Lbs
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Freight Class
                </Typography>
                <Typography display="block">
                  {quoteResult.freightClass}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Feet
                </Typography>
                <Typography display="block">{quoteResult.feet} ft</Typography>
              </Grid>
              <Grid item>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Pallets
                </Typography>
                <Typography display="block">{quoteResult.pallets}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography display="block" variant="subtitle1" color="primary">
                  Stops
                </Typography>
              </Grid>
              <Grid item>
                <Grid container alignItems="stretch">
                  {quoteResult.stops.map((s, l) => (
                    <Grid item style={{ padding: 16 }}>
                      <Typography
                        display="block"
                        variant="caption"
                        color="textSecondary"
                      >
                        {s.type === "shipper" ? "From" : "To"}
                      </Typography>
                      <Grid container alignItems="center">
                        <Grid item>
                          {s.type === "shipper" ? (
                            <ArrowUpward />
                          ) : (
                            <ArrowDownward />
                          )}
                        </Grid>
                        <Grid item>
                          <Typography display="block" variant="h6">
                            {s.name ? s.name : s.zip}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Typography display="block">
                        {s.address.line1 ? s.address.line1 : "Unknown Location"}
                      </Typography>
                      <Typography display="block">
                        {s.address.zip} {s.address.city} {s.address.state}
                      </Typography>
                      {s.date ? (
                        <Chip
                          label={moment(s.date).format("MMMM Do YYYY")}
                          avatar={
                            <Avatar>
                              <CalendarIcon />
                            </Avatar>
                          }
                        />
                      ) : (
                        <div />
                      )}
                      {s.id === firstDropOff.id && selected ? (
                        <Chip
                          label={
                            selected.element.grouped
                              ? `${moment(selected.element.from).format(
                                  "MMMM Do YYYY"
                                )} - ${moment(selected.element.to).format(
                                  "MMMM Do YYYY"
                                )}`
                              : moment(selected.date).format("MMMM Do YYYY")
                          }
                          avatar={
                            <Avatar>
                              <CalendarIcon />
                            </Avatar>
                          }
                        />
                      ) : (
                        <div />
                      )}
                    </Grid>
                  ))}
                  <Grid item>
                    <Grid
                      container
                      justify="center"
                      alignItems="center"
                      style={{ height: "100%", paddingLeft: 20 }}
                    >
                      <Grid
                        item
                        style={{
                          background: "#DBDBDB",
                          borderRadius: 5,
                          padding: 10,
                        }}
                      >
                        <Typography display="block" variant="h6">
                          <Info /> {quoteResult.totalMiles} miles
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Typography display="block" variant="subtitle1" color="primary">
                  Costs
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Service Level
                </Typography>
                <Typography display="block">
                  <img
                    src={selected.element.topLeftIcon}
                    alt={"top-left-icon"}
                    height={15}
                  />{" "}
                  {selected.element.serviceLevel}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Description
                </Typography>
                <Typography display="block">
                  {selected.element.description}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Base Rate
                </Typography>
                <Typography display="block">
                  {formattedPrice(selected.element.baseRate)}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Fuel Rate
                </Typography>
                <Typography display="block">
                  {formattedPrice(selected.element.fuelRate)}
                </Typography>
              </Grid>
              {selected.element.surcharges ? (
                <Grid item>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    Surcharges
                  </Typography>
                  <Typography display="block">
                    {formattedPrice(selected.element.surcharges)}
                  </Typography>
                </Grid>
              ) : (
                <div />
              )}
              {selected.element.extra ? (
                <Grid item>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    Extra
                  </Typography>
                  <Typography display="block">
                    {formattedPrice(selected.element.extra)}
                  </Typography>
                </Grid>
              ) : (
                <div />
              )}
              <Grid item xs={12} style={{ textAlign: "right" }}>
                <Help explanation="To go to the next step and tender the load, click on this button.">
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    Total
                  </Typography>
                  <Typography display="block" variant="h4">
                    {formattedPrice(selected.element.totalRate)}
                  </Typography>
                  <Button
                    variant="container"
                    size="large"
                    style={{
                      background: selected.element.color,
                      color: "white",
                    }}
                    onClick={() =>
                      history.push(
                        `${TENDER}?quoteID=${
                          quoteResult.id
                        }&selected=${encodeURIComponent(
                          JSON.stringify(selected)
                        )}`
                      )
                    }
                  >
                    <img
                      alt={"service-level-icon"}
                      src={selected.element.topLeftIcon}
                      height={20}
                      style={{ paddingRight: 10 }}
                    />{" "}
                    Tender Load
                  </Button>
                </Help>
              </Grid>
            </Grid>
          </Card>
        ) : (
          <div />
        )}
      </div>
    );
  }
}
export default withStyles(styles, { withTheme: true })(Result);
