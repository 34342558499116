import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
// material-ui
import withStyles from "@material-ui/styles/withStyles";
// reactor
import Page from "components/Page";
// custom
import QuotesList from "components/QuotesList";
// constants
import { QUOTE } from "constants/routes";
import PropTypes from "prop-types";
import React from "react";
// styles
import styles from "./styles";

class WrapperQuotePage extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    urlParams: PropTypes.object,
    history: PropTypes.object,
    loading: PropTypes.bool,
    quotes: PropTypes.array,
    filters: PropTypes.array,
    location: PropTypes.object,
    searchCustomers: PropTypes.func,
    getQuotesV3: PropTypes.func,
    personNames: PropTypes.array,
  };

  constructor(...args) {
    super(...args);
    const { urlParams } = this.props;
    this.state = {
      index: urlParams.index ? Number(urlParams.index) : 0,
    };
  }

  getStepContent() {
    const { index } = this.state;
    const {
      quotes,
      history,
      filters,
      location,
      personNames,
      searchCustomers,
      getQuotesV3,
    } = this.props;

    switch (index) {
      case 0:
        return (
          <QuotesList
            quotes={quotes}
            filters={filters}
            advancedSearch
            location={location}
            searchCustomers={searchCustomers}
            getQuotesV3={getQuotesV3}
            history={history}
            personNames={personNames}
            onSelect={(q) => history.push(`${QUOTE}?id=${q.id}`)}
          />
        );
      default:
    }

    return [];
  }

  render() {
    const { classes, history, loading } = this.props;

    const { index } = this.state;

    return (
      <Page
        helmet="Quotes"
        loading={loading}
        loadingMessage={"Fetching Quotes"}
        tabs={
          <Tabs
            value={index}
            onChange={(e, v) => {
              this.setState({
                index: v,
              });
              history.push(`?index=${v}`);
            }}
            indicatorColor="primary"
            textColor="primary"
            scrollable
            scrollButtons="auto"
            classes={{
              root: classes.tabsRoot,
              indicator: classes.tabsIndicator,
            }}
          >
            <Tab
              label="All"
              classes={{
                root: classes.tabRoot,
                selected: classes.tabSelected,
              }}
            />
          </Tabs>
        }
      >
        {this.getStepContent()}
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperQuotePage);
