// material-ui
import { withStyles } from "@material-ui/styles";
// reactor
import Page from "components/Page";
// custom
import Tender from "components/Tender";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class WrapperTenderPage extends Component {
  static propTypes = {
    history: PropTypes.object,
    loading: PropTypes.bool,
    quote: PropTypes.object,
    selected: PropTypes.object,
    searchLocations: PropTypes.func,
    newLocation: PropTypes.func,
    palletCommodities: PropTypes.func,
    tender: PropTypes.func,
    cancelTender: PropTypes.func,
    getLoadFiles: PropTypes.func,
    uploadLoadFile: PropTypes.func,
    searchCustomers: PropTypes.func,
    holidays: PropTypes.array,
    getLocationById: PropTypes.func,
    updateLocation: PropTypes.func,
    debug: PropTypes.bool,
  };

  render() {
    const {
      loading,
      quote,
      selected,
      searchLocations,
      newLocation,
      palletCommodities,
      tender,
      cancelTender,
      uploadLoadFile,
      history,
      getLoadFiles,
      holidays,
      searchCustomers,
      getLocationById,
      updateLocation,
      debug,
    } = this.props;

    return (
      <Page
        helmet="Tender"
        loadingMessage={"Loading Interface"}
        loading={loading}
      >
        <Tender
          tender={tender}
          holidays={holidays}
          getLoadFiles={getLoadFiles}
          history={history}
          cancelTender={cancelTender}
          uploadLoadFile={uploadLoadFile}
          quote={quote}
          selected={selected}
          searchLocations={searchLocations}
          newLocation={newLocation}
          palletCommodities={palletCommodities}
          searchCustomers={searchCustomers}
          getLocationById={getLocationById}
          updateLocation={updateLocation}
          debug={debug}
        />
      </Page>
    );
  }
}
export default withStyles(styles)(WrapperTenderPage);
