// import moment from 'moment';

// export const COMMODITIES = [
//   { name: 'Dairy', id: 1 },
//   { name: 'Machinery', id: 2 },
//   { name: 'Dry F.A.K', id: 4 },
//   { name: 'Food Product', id: 5 },
//   { name: 'Alcohol', id: 6 },
//   { name: 'Vitamins', id: 7 },
//   { name: 'Electronics', id: 8 },
//   { name: 'Tote', id: 9 },
// ];
export const FREIGHT_CLASS = [
  "500",
  "400",
  "300",
  "250",
  "200",
  "175",
  "150",
  "125",
  "110",
  "100",
  "92",
  "85",
  "77",
  "70",
  "65",
  "60",
  "55",
  "50",
];
export const STRIKE_COMMODITIES = ["Haz Mat", "Ice Cream"];
export const REMOVE = "REMOVE";
export const ADD = "ADD";
export const INCHES = "inches";
export const LTL = "LTL";
export const STANDARD_IM_SERVICE = "Standard IM Service";
export const EXPEDITED_IM_SERVICE = "Expedited IM Service";
export const TL = "TL";
export const FEET = "feet";
export const DRY = "Dry";
export const REEFER = "Reefer";
export const CONSIGNEE = "Drop-off";
export const SHIPPER = "Pick-up";
export const TL_TEMPERATURE =
  /^((-20)|(80)|(-[1,0]?[0-9]{1})|([0-7]?[0-9]{1}))$/;
export const LTL_TEMPERATURE = /^((80)|([4-7]{1}[0-9]{1}))$/;
export const NUMBER = /^-?\d*\.?\d*$/;
export const QUOTE_MAX_STOPS = 10;
export const MAX_LOCATION_AUTOCOMPLETE_ITEMS = 8;
export const DEFAULT_BODY = {
  service: LTL,
  commodityDescriptionId: 4,
  freightClass: "",
  mode: DRY,
  dryVanOnly: false,
  shippers: [
    {
      zip: "",
      accessorialIds: [],
      date: null,
    },
  ],
  consignees: [
    {
      zip: "",
      accessorialIds: [],
    },
  ],
  units: {
    palletDimensions: [],
    totalDimensions: {
      totalWeight: "",
    },
  },
  reefer: {
    reeferTemp: "",
    reeferCont: false,
  },
};

export const SERVICE_LEVEL_ICONS = {
  guaranteed: {
    color: "#f44336",
    icon: "https://storage.googleapis.com/archer-app/guaranteed.png",
  },
  premium: {
    color: "#ffc107",
    icon: "https://storage.googleapis.com/archer-app/premium.png",
  },
  economy: {
    color: "#4caf50",
    icon: "https://storage.googleapis.com/archer-app/economy.png",
  },
};

export const DAY_SIZE = 35;
