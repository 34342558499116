import { networkAction } from "helpers/network/networkAction";

import {
  GET_LOADS,
  GET_LOAD_BY_ID,
  DELETE_LOAD,
  UPDATE_LOAD,
  CREATE_LOAD,
  UPLOAD_LOAD_FILE,
  GET_LOAD_FILES,
} from "constants/loads";

import * as Api from "api";

export const getLoads = () => async (dispatch) =>
  networkAction(dispatch, GET_LOADS, Api.getLoads, []);

export const uploadLoadFile = (loadID, file, descriptionID) => async (
  dispatch
) =>
  networkAction(dispatch, UPLOAD_LOAD_FILE, Api.uploadLoadFile, [
    loadID,
    file,
    descriptionID,
  ]);

export const getLoadFiles = (loadID) => async (dispatch) =>
  networkAction(dispatch, GET_LOAD_FILES, Api.getLoadFiles, [loadID]);

export const getLoadById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_LOAD_BY_ID, Api.getLoadById, [id]);

export const deleteLoad = (id) => async (dispatch) =>
  networkAction(dispatch, DELETE_LOAD, Api.deleteLoad, [id]);

export const createLoad = (body) => async (dispatch) =>
  networkAction(dispatch, CREATE_LOAD, Api.createLoad, [body]);

export const updateLoad = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_LOAD, Api.updateLoad, [id, body]);
