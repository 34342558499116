// material-ui
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Heading extends Component {
  static propTypes = {
    level: PropTypes.number,
    children: PropTypes.node,
    classes: PropTypes.object,
  };

  render() {
    const { level, children, classes } = this.props;

    return (
      <Typography
        display="block"
        gutterBottom
        variant={`h${level}`}
        classes={{
          h1: classes.h1,
          h2: classes.h2,
          h3: classes.h3,
          h4: classes.h4,
          h5: classes.h5,
          h6: classes.h6,
        }}
      >
        {children}
      </Typography>
    );
  }
}

export default withStyles(styles)(Heading);
