export default (theme) => ({
  container: {
    padding: 16,
  },
  strikethrough: {
    textDecoration: "line-through",
  },
  line: {
    paddingBottom: 20,
  },
  next: {
    marginTop: 20,
  },
  notes: {
    background: "#fff9c4",
    borderRadius: 4,
    border: "solid 1px #ffee58",
  },
});
