import AppBar from "@material-ui/core/AppBar";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import AccountCircle from "@material-ui/icons/AccountCircle";
import AppsIcon from "@material-ui/icons/Apps";
import ArrowDropDown from "@material-ui/icons/ArrowDropDown";
import BrightnessHigh from "@material-ui/icons/BrightnessHigh";
import BrightnessLow from "@material-ui/icons/BrightnessLow";
import Home from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import PowerSettingsNew from "@material-ui/icons/PowerSettingsNew";
import Public from "@material-ui/icons/Public";
import Eye from "@material-ui/icons/RemoveRedEye";
// material-ui
import { withStyles } from "@material-ui/styles";
import App from "components/App";
// reactor
import Apps from "components/Apps";
import { REACT_APP_FRONT_BASE, REACT_APP_LOGO } from "config";
import { MY_ACCOUNT } from "constants/routes";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withCookies } from "react-cookie";
// styles
import styles from "./styles";

class Bar extends Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    cookies: PropTypes.object,
    onMenuOpen: PropTypes.func,
    user: PropTypes.object,
    userApps: PropTypes.object,
    history: PropTypes.object,
    app: PropTypes.object,
    setDarkMode: PropTypes.func,
    stopImpersonate: PropTypes.func,
    setOrganizationByDefault: PropTypes.func,
    joinOrganization: PropTypes.func,
    getCurrentUser: PropTypes.func,
    signout: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  state = {
    anchorEl: null,
    appsOpen: false,
  };

  getMenu() {
    const {
      signout,
      history,
      app,
      setDarkMode,
      cookies,
      user,
      stopImpersonate,
      classes,
    } = this.props;

    const { anchorEl } = this.state;
    const accountOpen = Boolean(anchorEl);

    return (
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        disableAutoFocusItem
        open={accountOpen}
        onClose={this.handleAppBarClose}
      >
        <Hidden smUp>
          {user && user.impersonate ? (
            <MenuItem
              onClick={async () => {
                await stopImpersonate();
                window.location.reload();
              }}
              style={{
                background: "#f44336",
                color: "white",
              }}
            >
              <ListItemIcon>
                <Eye style={{ color: "white" }} />
              </ListItemIcon>
              <ListItemText
                inset
                classes={{
                  primary: classes.white,
                }}
                primary={`Stop Impersonating ${user.firstName} ${user.lastName}`}
              />
            </MenuItem>
          ) : (
            <div />
          )}
        </Hidden>
        <Hidden smUp>
          <MenuItem onClick={() => this.setState({ appsOpen: true })}>
            <ListItemIcon>
              <AppsIcon />
            </ListItemIcon>
            <ListItemText inset primary="Apps" />
          </MenuItem>
        </Hidden>
        <MenuItem onClick={() => history.push(MY_ACCOUNT)}>
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText inset primary="My Account" />
        </MenuItem>
        <Hidden smUp>
          <MenuItem
            onClick={() => {
              setDarkMode(!app.darkMode);
              cookies.set("darkMode", !app.darkMode, { path: "/" });
            }}
          >
            <ListItemIcon>
              {app.darkMode ? <BrightnessLow /> : <BrightnessHigh />}
            </ListItemIcon>
            <ListItemText
              inset
              primary={app.darkMode ? "Light Mode" : "Dark Mode"}
            />
          </MenuItem>
        </Hidden>
        <MenuItem
          onClick={async () => {
            await signout();
            window.location.replace(`${REACT_APP_FRONT_BASE}/signin`);
          }}
        >
          <ListItemIcon>
            <PowerSettingsNew />
          </ListItemIcon>
          <ListItemText inset primary="Sign out" />
        </MenuItem>
      </Menu>
    );
  }

  handleAppBarMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleAppBarClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      classes,
      theme,
      onMenuOpen,
      user,
      userApps,
      history,
      app,
      setDarkMode,
      cookies,
      stopImpersonate,
      setOrganizationByDefault,
    } = this.props;

    const { anchorEl, appsOpen, anchorOrganization } = this.state;
    const accountOpen = Boolean(anchorEl);
    const organizationOpen = Boolean(anchorOrganization);

    let currentOrganization = { name: "No Organization" };
    if (user.organizations && user.organizations.length) {
      currentOrganization = user.organizations.find(
        (o) => o.id === user.organizationID
      );
      if (currentOrganization === undefined) {
        currentOrganization = user.organizations[0];
      }
    }

    return (
      <AppBar
        position="static"
        className={classes.appBar}
        style={{ background: "#303030" }}
      >
        <Grid
          container
          justify="space-between"
          className={classes.appGrid}
          alignItems="center"
        >
          <Grid item>
            {/* Top Left Menu : Logo and Menu Icon */}
            <Grid container alignItems="center">
              <Grid item>
                <Hidden mdUp implementation="css">
                  <IconButton
                    color="inherit"
                    aria-label="Menu"
                    onClick={onMenuOpen}
                  >
                    <MenuIcon fontSize="small" />
                  </IconButton>
                </Hidden>
              </Grid>
              <Hidden xsDown implementation="css">
                <Grid item>
                  <img
                    alt="logo"
                    src={REACT_APP_LOGO}
                    height="40"
                    style={{ marginLeft: 10, marginRight: 10 }}
                  />
                </Grid>
              </Hidden>
              <Grid item>
                <App
                  fullName
                  color={theme.palette.primary.color[500]}
                  name={"Sales"}
                  size={30}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            {/* Desktop Top Right Menu */}
            <Hidden xsDown>
              <Grid container alignItems="center">
                {user && user.organizations && user.organizations.length > 1 ? (
                  <Grid item>
                    <Chip
                      label={currentOrganization.name}
                      style={{
                        background: "rgba(155,155,155,0.3)",
                        color: "white",
                      }}
                      avatar={
                        <Avatar
                          style={{
                            background: "none",
                            color: "white",
                          }}
                        >
                          <Public />
                        </Avatar>
                      }
                      deleteIcon={<ArrowDropDown style={{ color: "white" }} />}
                      onDelete={(e) =>
                        this.setState({ anchorOrganization: e.currentTarget })
                      }
                      onClick={(e) =>
                        this.setState({ anchorOrganization: e.currentTarget })
                      }
                    />
                    <Menu
                      id="simple-menu"
                      anchorEl={anchorOrganization}
                      open={organizationOpen}
                      onClose={() =>
                        this.setState({ anchorOrganization: null })
                      }
                    >
                      {user &&
                        user.organizations &&
                        user.organizations.map((o) => (
                          <MenuItem
                            onClick={async () => {
                              await setOrganizationByDefault(o.id);
                              window.location.reload();
                            }}
                            selected={o.id === currentOrganization.id}
                          >
                            {o.name}
                          </MenuItem>
                        ))}
                    </Menu>
                  </Grid>
                ) : (
                  []
                )}
                <Grid item>
                  <Apps
                    userApps={userApps}
                    history={history}
                    app={app}
                    baseHostname={REACT_APP_FRONT_BASE}
                  />
                </Grid>
                <Grid item>
                  <Tooltip
                    title={app.darkMode ? "Set Light Mode" : "Set Dark Mode"}
                  >
                    <IconButton
                      aria-label="DarkMode"
                      onClick={() => {
                        setDarkMode(!app.darkMode);
                        cookies.set("darkMode", !app.darkMode, { path: "/" });
                      }}
                    >
                      {app.darkMode ? (
                        <BrightnessLow />
                      ) : (
                        <BrightnessHigh style={{ color: "white" }} />
                      )}
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item>
                  {user && user.impersonate ? (
                    <Chip
                      label={`Impersonating ${user.firstName} ${user.lastName}`}
                      avatar={
                        <Avatar
                          style={{
                            background: "#d32f2f",
                            color: "white",
                          }}
                        >
                          <Eye />
                        </Avatar>
                      }
                      style={{
                        background: "#f44336",
                        color: "white",
                      }}
                      onDelete={async () => {
                        await stopImpersonate();
                        window.location.reload();
                      }}
                    />
                  ) : (
                    <Typography
                      display="block"
                      variant="body2"
                      className={classes.AppBarGreetings}
                    >
                      {`${user.firstName} ${user.lastName}`}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <IconButton
                    aria-owns={accountOpen ? "menu-appbar" : null}
                    aria-haspopup="true"
                    onClick={this.handleAppBarMenu}
                    color="inherit"
                  >
                    <AccountCircle />
                  </IconButton>
                  {this.getMenu()}
                </Grid>
              </Grid>
            </Hidden>
            {/* Mobile Top Right Menu */}
            <Hidden smUp>
              <Grid container>
                <Grid item>
                  <IconButton
                    aria-owns={accountOpen ? "menu-appbar" : null}
                    aria-haspopup="true"
                    onClick={this.handleAppBarMenu}
                    color="inherit"
                    style={{
                      color: user && user.impersonate ? "#f44336" : undefined,
                    }}
                  >
                    <AccountCircle />
                  </IconButton>
                  {this.getMenu()}
                </Grid>
              </Grid>
              <Dialog
                fullScreen
                open={appsOpen}
                onClose={() => this.setState({ appsOpen: false })}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogTitle id="responsive-dialog-title">
                  {"Select the app you want to navigate:"}
                </DialogTitle>
                <DialogContent>
                  <Grid container>
                    {userApps.map((a) => (
                      <Grid item xs={3}>
                        <App {...a} key={a.id} size={50} withName />
                      </Grid>
                    ))}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() =>
                      window.location.replace(REACT_APP_FRONT_BASE)
                    }
                    variant="outlined"
                  >
                    <Home /> Home
                  </Button>
                  <Button
                    onClick={() => this.setState({ appsOpen: false })}
                    color="primary"
                  >
                    Close
                  </Button>
                </DialogActions>
              </Dialog>
            </Hidden>
          </Grid>
        </Grid>
      </AppBar>
    );
  }
}

export default withCookies(withStyles(styles, { withTheme: true })(Bar));
