import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

// component
import WrapperLoadsPage from "../component/WrapperLoadsPage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  loads: state.loads,
});

const mapDispatchToProps = (dispatch) => ({
  getLoads: () => dispatch(Actions.getLoads()),
  getLoadById: (...args) => dispatch(Actions.getLoadById(...args)),
  createLoad: (...args) => dispatch(Actions.createLoad(...args)),
  deleteLoad: (...args) => dispatch(Actions.deleteLoad(...args)),
  updateLoad: (...args) => dispatch(Actions.updateLoad(...args)),
});
class LoadsPage extends Component {
  static propTypes = {
    session: PropTypes.object,
    history: PropTypes.object,
    location: PropTypes.object,

    loads: PropTypes.array,
    getLoads: PropTypes.func,
    getLoadById: PropTypes.func,
    createLoad: PropTypes.func,
    deleteLoad: PropTypes.func,
    updateLoad: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, loads } = this.props;

    this.state = {
      urlParams: getJsonFromUrl(location),
      loading: loads.length === 0,
    };
  }

  componentDidMount() {
    console.log("componentDidMount");
    this.refresh();
  }

  async refresh() {
    const { getLoads } = this.props;
    await Promise.all([getLoads()]);

    this.setState({ loading: false });
  }

  render() {
    const {
      loads,
      getLoadById,
      createLoad,
      deleteLoad,
      updateLoad,
    } = this.props;

    const { urlParams, loading } = this.state;

    return (
      <WrapperLoadsPage
        refresh={this.refresh.bind(this)}
        loading={loading}
        loads={loads}
        getLoadById={getLoadById}
        createLoad={createLoad}
        deleteLoad={deleteLoad}
        updateLoad={updateLoad}
        urlParams={urlParams}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(LoadsPage);
