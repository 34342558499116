export default (theme) => ({
  post: {
    color: "white",
    fontWeight: "0.5",
    background: "#01B48F",
    padding: 4,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 5,
  },
  get: {
    color: "white",
    fontWeight: "0.5",
    background: "#44C7F4",
    padding: 4,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 5,
  },
  put: {
    color: "white",
    fontWeight: "0.5",
    background: "#C5862B",
    padding: 4,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 5,
  },
  del: {
    color: "white",
    fontWeight: "0.5",
    background: "#f44336",
    padding: 4,
    paddingLeft: 8,
    paddingRight: 8,
    borderRadius: 5,
  },
});
