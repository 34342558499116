// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Link extends Component {
  static propTypes = {
    classes: PropTypes.object,
    href: PropTypes.object,
    children: PropTypes.node,
  };

  render() {
    const { classes, href, children } = this.props;

    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={classes.link}
      >
        {children}
      </a>
    );
  }
}

export default withStyles(styles)(Link);
