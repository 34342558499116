import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import Sort from "@material-ui/icons/Sort";
// material-ui
import { withStyles } from "@material-ui/styles";
// core
import Pallet from "components/Pallet";
import PropTypes from "prop-types";
import React, { Component } from "react";
// internal
import Card from "../Card";
// constants
import { ADD, REMOVE } from "../constants";
import FormCommodities from "../FormCommodities";
// styles
import styles from "./styles";

class Commodities extends Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    disabled: PropTypes.bool,
    nextClicked: PropTypes.bool,
    gatherErrorFunction: PropTypes.func,
    step: PropTypes.number,
    onChange: PropTypes.func,
    palletDimensions: PropTypes.array,
    totalDimensions: PropTypes.object,
  };

  state = {
    commoditiesOpen: false,
    selectedPathIndex: undefined,
  };

  constructor(...args) {
    super(...args);
    const { gatherErrorFunction, step } = this.props;
    gatherErrorFunction(
      {
        errorFunction: this.hasError.bind(this),
        section: "Commodities",
      },
      ADD,
      step
    );
  }

  componentWillUnmount() {
    const { gatherErrorFunction, step } = this.props;
    gatherErrorFunction(
      {
        errorFunction: this.hasError.bind(this),
        section: "Commodities",
      },
      REMOVE,
      step
    );
  }

  getRemainingPallets() {
    console.log("getRemaining");
    const { palletDimensions, paths } = this.props;
    const _palletDimensions = JSON.parse(JSON.stringify(palletDimensions));

    for (const k in paths) {
      if (paths.hasOwnProperty(k)) {
        const pallets = paths[k].pallets;
        for (const l in pallets) {
          if (pallets.hasOwnProperty(l)) {
            const pallet = pallets[l];
            const palletToUpdate = _palletDimensions.find(
              (p) => p.id === pallet.id
            );
            palletToUpdate.count -= pallet.count;
          }
        }
      }
    }

    return _palletDimensions;
  }

  getRemainingPalletsCount() {
    const _palletDimensions = this.getRemainingPallets();
    let remainingPalletCount = 0;
    _palletDimensions.forEach((p) => (remainingPalletCount += p.count));
    return remainingPalletCount;
  }

  hasError() {
    return this.getRemainingPalletsCount() > 0;
  }

  openCommodities() {
    this.setState({ commoditiesOpen: true });
  }

  closeCommodities() {
    this.setState({ commoditiesOpen: false, selectedPathIndex: undefined });
  }

  render() {
    const {
      nextClicked,
      disabled,
      theme,
      classes,
      paths,
      onChange,
    } = this.props;

    const { commoditiesOpen, selectedPathIndex } = this.state;

    const primaryColor = theme.palette.primary.color[500];
    const remainingPallets = this.getRemainingPallets();
    const remainingPalletsCount = this.getRemainingPalletsCount();

    return (
      <Card
        nextClicked={nextClicked}
        error={this.hasError()}
        primaryColor={primaryColor}
        disabled={disabled}
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item style={{ paddingRight: 8 }}>
                <Sort />
              </Grid>
              <Grid item>
                <Typography display="block" variant="h6">
                  Commodities
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.stop}>Fom</TableCell>
              <TableCell className={classes.stop}>To</TableCell>
              <TableCell>Pallets</TableCell>
              <TableCell className={classes.manage} />
            </TableRow>
          </TableHead>
          <TableBody>
            {paths.map((p, k) => (
              <TableRow>
                <TableCell className={classes.stop}>
                  <Typography display="block">{p.from.name}</Typography>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    {p.from.line1}
                  </Typography>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    {p.from.zip} {p.from.city} {p.from.state}
                  </Typography>
                </TableCell>
                <TableCell className={classes.stop}>
                  <Typography display="block">{p.to.name}</Typography>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    {p.to.line1}
                  </Typography>
                  <Typography
                    display="block"
                    variant="caption"
                    color="textSecondary"
                  >
                    {p.to.zip} {p.to.city} {p.to.state}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Grid container spacing={1} alignItems="center">
                    {p.pallets.map((pallet, i) => {
                      if (pallet.count > 0) {
                        return (
                          <Grid item key={`Pallet${i}`}>
                            <Pallet {...pallet} />
                          </Grid>
                        );
                      }
                      return <div />;
                    })}
                  </Grid>
                </TableCell>
                <TableCell className={classes.manage}>
                  <div>
                    {!disabled ? (
                      <div>
                        {p.pallets.find((w) => w.count > 0) !== undefined ? (
                          <Chip
                            label="Manage Pallets"
                            onClick={() => {
                              this.openCommodities();
                              this.setState({
                                selectedPathIndex: k,
                              });
                            }}
                            className={classes.chip}
                            variant="outlined"
                            color="primary"
                            style={{
                              border:
                                nextClicked && this.hasError()
                                  ? "solid 1px #f44336"
                                  : undefined,
                              color:
                                nextClicked && this.hasError()
                                  ? "#f44336"
                                  : undefined,
                            }}
                          />
                        ) : (
                          <Chip
                            label="Add Pallets to Lane"
                            onClick={() => {
                              this.openCommodities();
                              this.setState({
                                selectedPathIndex: k,
                              });
                            }}
                            className={classes.chip}
                            variant="contained"
                            color="primary"
                            style={{
                              background:
                                nextClicked && this.hasError()
                                  ? "#f44336"
                                  : undefined,
                            }}
                          />
                        )}
                      </div>
                    ) : (
                      <div />
                    )}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <br />
        <br />
        {remainingPalletsCount ? (
          <div>
            <Typography display="block">
              You need to dispatch these remaning pallets in a lane:
            </Typography>
            <Grid container spacing={1}>
              {remainingPallets.map((p) => {
                if (p.count) {
                  return (
                    <Grid item>
                      <Pallet {...p} remaining />
                    </Grid>
                  );
                }
                return <div />;
              })}
            </Grid>
          </div>
        ) : (
          <div />
        )}
        <FormCommodities
          open={commoditiesOpen}
          close={this.closeCommodities.bind(this)}
          selectedPathIndex={selectedPathIndex}
          paths={paths}
          remainingPallets={remainingPallets}
          onCommodityChange={(p) => {
            onChange({ paths: p });
          }}
        />
      </Card>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Commodities);
