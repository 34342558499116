import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import Add from "@material-ui/icons/Add";
import ArrowForward from "@material-ui/icons/ArrowForward";
import Remove from "@material-ui/icons/Remove";
// material-ui
import { withStyles } from "@material-ui/styles";
// core
import Pallet from "components/Pallet";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormCommodities extends Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    fullScreen: PropTypes.bool.isRequired,
    open: PropTypes.bool,
    close: PropTypes.func,
    selectedPathIndex: PropTypes.object,
    paths: PropTypes.array,
    remainingPallets: PropTypes.array,
    onCommodityChange: PropTypes.func,
  };

  render() {
    const {
      fullScreen,
      open,
      close,
      selectedPathIndex,
      remainingPallets,
      onCommodityChange,
      paths,
      classes,
    } = this.props;

    const selectedPath = paths[selectedPathIndex];

    if (selectedPath === undefined) return <div />;

    const { from, to } = selectedPath;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={close}
        aria-labelledby="responsive-dialog-title"
        style={{
          minWidth: fullScreen ? undefined : 900,
        }}
      >
        <DialogTitle id="responsive-dialog-title">
          Pallets for Path
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Typography display="block">From {from.name}</Typography>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                {from.line1}
              </Typography>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                {from.zip} {from.city} {from.state}
              </Typography>
            </Grid>
            <Grid item>
              <ArrowForward />
            </Grid>
            <Grid item>
              <Typography display="block">To {to.name}</Typography>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                {to.line1}
              </Typography>
              <Typography
                display="block"
                variant="caption"
                color="textSecondary"
              >
                {to.zip} {to.city} {to.state}
              </Typography>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            Let Google help apps determine location. This means sending anonymous location data to
            Google, even when no apps are running.
          </DialogContentText> */}
          <List dense>
            {remainingPallets.map((p) => {
              const commodityPallet = selectedPath.pallets.find(
                (a) => a.id === p.id
              );
              return (
                <ListItem key={`listItem_pallet#${p.id}`} divider>
                  <Pallet {...p} remaining />
                  <ListItemSecondaryAction>
                    <Grid container spacing={1} alignItems="center">
                      <Grid item>
                        <IconButton
                          aria-label="Delete"
                          color="primary"
                          onClick={() => {
                            if (commodityPallet.count > 0) {
                              commodityPallet.count -= 1;
                            }
                            onCommodityChange(paths);
                          }}
                        >
                          <Remove />
                        </IconButton>
                      </Grid>
                      <Grid item>
                        <div className={classes.avatar}>
                          <TextField
                            id="pieces"
                            InputLabelProps={{
                              classes: {
                                root: classes.root,
                                // focused: classes.cssFocused,
                              },
                            }}
                            InputProps={{
                              disableUnderline: true,
                              classes: {
                                root: classes.root,
                                input: classes.root,
                              },
                            }}
                            value={commodityPallet.count}
                            onChange={(e) => {
                              if (p.count >= Number(e.target.value)) {
                                commodityPallet.count = Number(e.target.value);
                                onCommodityChange(paths);
                              }
                            }}
                          />
                        </div>
                      </Grid>
                      <Grid item>
                        <IconButton
                          aria-label="Delete"
                          color="primary"
                          onClick={() => {
                            if (p.count > 0) {
                              commodityPallet.count += 1;
                            }
                            onCommodityChange(paths);
                          }}
                        >
                          <Add />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </List>
          <div className={classes.pieces}>
            <TextField
              id="pieces"
              label="Pieces"
              placeholder={"Optional number of pieces for the commodity."}
              fullWidth
              value={selectedPath.notes}
              onChange={(e) => {
                selectedPath.notes = e.target.value;
                onCommodityChange(paths);
              }}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </div>
          <div className={classes.notes}>
            <TextField
              id="notes"
              label="Notes"
              placeholder={
                "Any notes for the commodity. Any relevant ID numbers can go here as well."
              }
              fullWidth
              value={selectedPath.pieces}
              onChange={(e) => {
                selectedPath.pieces = e.target.value;
                onCommodityChange(paths);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              rows={2}
              multiline
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="primary" autoFocus variant="contained">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withMobileDialog()(
  withStyles(styles, { withTheme: true })(FormCommodities)
);
