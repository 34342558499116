import { POST, DELETE } from "constants/methods";

const service = "v3/quote-service";
const tender = `/${service}/quotes/tender`;

export function tenderLoad(body) {
  const url = `${tender}`;
  return {
    service,
    method: POST,
    body,
    url,
  };
}

export function cancelTender(tenderID) {
  const url = `${tender}/${tenderID}`;
  return {
    service,
    method: DELETE,
    url,
  };
}
