import { Popover } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import CircularProgress from "@material-ui/core/CircularProgress";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
import Place from "@material-ui/icons/Place";
// material-ui
import { withStyles } from "@material-ui/styles";
import FormLocation from "components/FormLocation";
import Help from "components/Help";
// core
import LocationsByAddress from "components/LocationsByAddress";
import { REACT_APP_LOGO } from "config";
import PropTypes from "prop-types";
import React, { Component } from "react";
// images
import FindMap from "./find.png";
// styles
import styles from "./styles";

const google = window.google;

class LocationFinder extends Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    searchLocations: PropTypes.func,
    helperText: PropTypes.string,
    value: PropTypes.string,
    zip: PropTypes.string,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    service: PropTypes.string,
    onChange: PropTypes.func,
    newLocation: PropTypes.func,
    maxLocationAutocompleteItems: PropTypes.number,
    getLocationById: PropTypes.func,
    updateLocation: PropTypes.func,
    locationId: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.geocoder = new google.maps.Geocoder();

    this.state = {
      searchText: "",
      selected: undefined,
      archerSuggestions: [],
      googleSuggestions: [],
      locationsByAddressOpen: false,
      locationLoading: false,
      open: false,
      bounds: undefined,
      newLocationOpen: false,
    };

    // Documentation for AutocompleteService
    // https://developers.google.com/maps/documentation/javascript/places-autocomplete#place_autocomplete_service
    this.service = new google.maps.places.AutocompleteService(null);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.zip) {
      this.geocoder.geocode({ address: nextProps.zip }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();
          const bounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(lat, lng),
            new google.maps.LatLng(lat, lng)
          );
          this.setState({
            bounds,
          });
        }
      });
    }
  }

  async onLocationSelect(location, type) {
    const { onChange } = this.props;
    this.setState({ anchorEl: null });
    if (type === "archer") {
      this.setState({
        selected: location,
        searchText: location.name,
      });
      onChange({ target: { value: location } });
    }

    if (type === "google") {
      this.geocoder.geocode({ address: location.description }, (results) => {
        const selected = results[0];
        this.setState({
          selected,
        });
        this.openLocationsByAddress();
      });
    }
  }

  getLatLgn(address, cb) {
    this.geocoder.geocode({ address }, (results, status) => {
      cb(results, status);
    });
  }

  getMenuItems() {
    const { theme, maxLocationAutocompleteItems } = this.props;
    const { archerSuggestions, googleSuggestions, selected } = this.state;
    const menuItems = [];

    const primaryColor = theme.palette.primary.color[500];

    for (const k in archerSuggestions) {
      if (archerSuggestions.hasOwnProperty(k)) {
        const s = archerSuggestions[k];
        menuItems.push(
          <MenuItem
            selected={selected && selected.id === s.id}
            key={s.id}
            onClick={() => this.onLocationSelect(s, "archer")}
          >
            <Avatar style={{ background: primaryColor, marginRight: 8 }}>
              <Place />
            </Avatar>
            <ListItemText
              primary={s.name}
              secondary={s.address && s.address.formattedAddress}
            />
          </MenuItem>
        );
      }
    }

    for (const k in googleSuggestions) {
      if (googleSuggestions.hasOwnProperty(k)) {
        const s = googleSuggestions[k];
        menuItems.push(
          <MenuItem
            selected={selected && selected.id === s.id}
            key={s.id}
            onClick={() => this.onLocationSelect(s, "google")}
          >
            <Avatar style={{ marginRight: 8 }}>
              <Place />
            </Avatar>
            <ListItemText
              primary={s.structured_formatting.main_text}
              secondary={s.structured_formatting.secondary_text}
            />
          </MenuItem>
        );
      }
    }

    return menuItems.slice(0, maxLocationAutocompleteItems);
  }

  handleClick = (event) => {
    const { disabled } = this.props;
    if (disabled) return;
    const { currentTarget } = event;
    this.setState((state) => ({
      anchorEl: currentTarget,
    }));
  };

  handleChange(e) {
    const { searchLocations, zip } = this.props;
    const { bounds } = this.state;
    const searchText = e.target.value;
    this.setState({
      searchText,
      locationLoading: true,
    });

    if (searchText === "") {
      this.setState({
        archerSuggestions: [],
        googleSuggestions: [],
        locationLoading: false,
      });
      return;
    }

    // Google search
    this.service.getPlacePredictions(
      {
        input: searchText,
        componentRestrictions: { country: "us" },
        types: ["address"],
        bounds,
      },
      (googleSuggestions) => this.setState({ googleSuggestions })
    );

    // Archer search
    searchLocations(`?zip=${zip}&location=${searchText}`).then((resp) => {
      this.setState({
        archerSuggestions: resp.payload,
        locationLoading: false,
      });
    });
  }

  closeLocationsByAddress() {
    this.setState({ locationsByAddressOpen: false });
  }

  openLocationsByAddress() {
    this.setState({ locationsByAddressOpen: true });
  }

  closeNewLocation() {
    this.setState({ newLocationOpen: false });
  }

  openNewLocation() {
    this.setState({ newLocationOpen: true });
  }

  render() {
    const {
      classes,
      disabled,
      error,
      searchLocations,
      service,
      theme,
      onChange,
      newLocation,
      helperText,
      value,
      getLocationById,
      updateLocation,
      locationId,
    } = this.props;

    const {
      searchText,
      anchorEl,
      locationLoading,
      selected,
      locationsByAddressOpen,
      newLocationOpen,
    } = this.state;

    const primaryColor = theme.palette.primary.color[500];

    return (
      <div>
        <Tooltip
          title={
            disabled && selected === undefined
              ? "← A valid Zipcode is Required"
              : ""
          }
        >
          <Grid container spacing={1} alignItems="center">
            {selected || value ? (
              <Grid item>
                <Place color="primary" />
              </Grid>
            ) : (
              []
            )}
            <Grid
              item
              style={{
                width: selected || value ? "calc(100% - 100px)" : "100%",
              }}
            >
              <TextField
                error={error}
                aria-haspopup="true"
                onClick={this.handleClick.bind(this)}
                // onFocus={this.handleClick.bind(this)}
                disabled={disabled}
                id="zip"
                label="Facility"
                placeholder="Enter Location or Address"
                fullWidth
                helperText={helperText}
                value={searchText && searchText.length ? searchText : value}
                onChange={this.handleChange.bind(this)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            {selected || locationId ? (
              <Grid item>
                <Help
                  image="https://storage.googleapis.com/archer-app-dev/help/Edit%20location.png"
                  explanation="Legacy Archer when you edit location gives you also more details about that location."
                >
                  <IconButton
                    disabled={disabled}
                    size="small"
                    style={{ padding: 5 }}
                    onClick={async () => {
                      const resp = await getLocationById(locationId);
                      this.setState({
                        selected: resp.payload,
                        newLocationOpen: true,
                      });
                    }}
                  >
                    <Edit style={{ fontSize: 17 }} />
                  </IconButton>
                </Help>
                <IconButton
                  disabled={disabled}
                  size="small"
                  style={{ padding: 5 }}
                  onClick={() => {
                    this.setState({
                      selected: undefined,
                      searchText: "",
                      archerSuggestions: [],
                      googleSuggestions: [],
                    });
                    onChange({ target: { value: undefined } });
                  }}
                >
                  <Close style={{ fontSize: 17 }} />
                </IconButton>
              </Grid>
            ) : (
              []
            )}
          </Grid>
        </Tooltip>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          key={Boolean(anchorEl) ? "simple-popover" : undefined}
          onClose={() => {
            this.setState({ anchorEl: null });
          }}
          classes={{
            paper: classes.paper,
          }}
          disableAutoFocus
          disableEnforceFocus
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
        >
          <MenuList anchorEl={anchorEl}>
            {!locationLoading ? this.getMenuItems() : <div />}
            {locationLoading ? (
              <Grid container spacing={2} alignItems="center" justify="center">
                <Grid
                  item
                  style={{
                    textAlign: "center",
                    marginTop: 77,
                    marginBottom: 77,
                  }}
                >
                  <Typography display="block" variant="h6">
                    Loading Locations...
                  </Typography>
                  <CircularProgress />
                </Grid>
              </Grid>
            ) : (
              <div />
            )}
            {searchText.length === 0 && !locationLoading ? (
              <Grid container spacing={2} alignItems="center" justify="center">
                <Grid
                  item
                  style={{
                    textAlign: "center",
                    marginTop: 67,
                    marginBottom: 67,
                  }}
                >
                  <img alt="find Location" src={FindMap} height={60} />
                  <Typography display="block" variant="h6">
                    Type a Location Name or an Address...
                  </Typography>
                </Grid>
              </Grid>
            ) : (
              <div />
            )}
            <Divider />
            <Grid
              container
              spacing={1}
              alignItems="center"
              justify="center"
              style={{ paddingTop: 8 }}
            >
              <Grid item>
                <Typography
                  display="block"
                  variant="caption"
                  color="textSecondary"
                >
                  Powered by <b>Google</b> and
                </Typography>
              </Grid>
              <Grid item>
                <div
                  style={{
                    background: "#424242",
                    borderRadius: 20,
                    opacity: 0.8,
                  }}
                >
                  <img src={REACT_APP_LOGO} alt="Archer" height="15" />
                </div>
              </Grid>
            </Grid>
          </MenuList>
        </Popover>
        <LocationsByAddress
          open={locationsByAddressOpen}
          close={this.closeLocationsByAddress.bind(this)}
          searchLocations={searchLocations}
          selected={selected}
          service={service}
          primaryColor={primaryColor}
          onSelect={(location) => {
            this.setState({
              selected: location,
              searchText: location.name,
            });
            onChange({ target: { value: location } });
          }}
          newLocationRequested={this.openNewLocation.bind(this)}
          onCancel={() => {
            this.setState({
              selected: undefined,
              searchText: "",
            });
          }}
        />
        <FormLocation
          open={newLocationOpen}
          close={this.closeNewLocation.bind(this)}
          onCancel={() => {
            this.closeNewLocation();
            this.setState({
              selected: undefined,
              searchText: "",
              archerSuggestions: [],
              googleSuggestions: [],
            });
          }}
          newLocation={newLocation}
          location={selected}
          updateLocation={updateLocation}
          onNewLocation={(location) => {
            this.setState({
              selected: location,
              searchText: location.name,
            });
            onChange({ target: { value: location } });
          }}
        />
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(LocationFinder);
