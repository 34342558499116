import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";

// component
import WrapperQuotePage from "../component/WrapperQuotePage";

// helpers
import { getJsonFromUrl } from "helpers";

// actions
import * as Actions from "actions";

const mapStateToProps = (state) => ({
  quotes: state.quotes,
  personNames: state.personNames,
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({
  getQuotesV3: (...args) => dispatch(Actions.getQuotesV3(...args)),
  getPersonNames: () => dispatch(Actions.getPersonNames()),
  searchCustomers: (...args) => dispatch(Actions.searchCustomers(...args)),
});
class QuotePage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    getPersonNames: PropTypes.func,
    personNames: PropTypes.array,

    searchCustomers: PropTypes.func,

    user: PropTypes.object,

    quotes: PropTypes.array,
    getQuotesV3: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { location, quotes, history } = this.props;
    const urlParams = getJsonFromUrl(location);

    const filters = urlParams.filters
      ? JSON.parse(urlParams.filters)
      : [
          {
            name: "quotes.created_at",
            comparison: "like",
            value: moment().format("YYYY-MM-DD"),
          },
        ];

    urlParams.filters = JSON.stringify(filters);
    delete urlParams[""];
    const search = Object.keys(urlParams)
      .map(
        (k) => `${encodeURIComponent(k)}=${encodeURIComponent(urlParams[k])}`
      )
      .join("&");
    history.push({
      search,
    });

    this.state = {
      urlParams,
      // filters,
      loading: quotes.length === 0,
      init: false,
    };

    this.refresh();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.location.search !== this.props.location.search) {
      const urlParams = getJsonFromUrl(nextProps.location);
      this.setState(
        {
          filters: urlParams.filters ? JSON.parse(urlParams.filters) : [],
          limit: urlParams.limit,
          offset: urlParams.offset,
        },
        this.refresh.bind(this)
      );
    }
  }

  async refresh() {
    const { getQuotesV3, getPersonNames } = this.props;
    const { filters, init, limit, offset } = this.state;

    if (!init) {
      await Promise.all([getPersonNames()]);
      this.state.init = true;
    }

    if (filters && filters.length) {
      await Promise.all([getQuotesV3(filters, limit, offset)]);
    }

    this.setState({ loading: false, init: true });
  }

  render() {
    const {
      history,
      location,
      quotes,
      personNames,
      searchCustomers,
      getQuotesV3,
    } = this.props;

    const { urlParams, loading, filters } = this.state;

    return (
      <WrapperQuotePage
        history={history}
        location={location}
        refresh={this.refresh.bind(this)}
        getQuotesV3={getQuotesV3}
        loading={loading}
        quotes={quotes}
        urlParams={urlParams}
        filters={filters}
        personNames={personNames}
        searchCustomers={searchCustomers}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(QuotePage);
