/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
// styles
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
// material-ui
import { withStyles } from "@material-ui/styles";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
// component
import DayBox from "./DayBox";
import styles from "./styles";

class ServiceSelector extends React.Component {
  static propTypes = {
    createdAt: PropTypes.string,
    selected: PropTypes.string,
    days: PropTypes.array,
    onSelect: PropTypes.func,
    classes: PropTypes.object,
  };

  getFirstSunday(createdAt) {
    const date = moment(createdAt).toDate();
    const day = date.getDay();
    const diff = date.getDate() - day; // adjust when day is sunday
    return moment(date.setDate(diff)).toDate();
  }

  // getSaturday(){}

  getBoxType(currentDayStr, pickUpDayStr, firstSundayStr, id) {
    const { days, selected, onSelect } = this.props;
    const firstSunday = moment(firstSundayStr).toDate();
    const currentDay = moment(currentDayStr).toDate();
    const pickUpDay = moment(pickUpDayStr).toDate();
    const firstDeliveryDay = moment(days[0].from).toDate();
    const diff = firstSunday.getDate() + 20;
    const lastCalendarDay = moment(firstSunday.setDate(diff)).toDate();
    const result = [];
    let grouped = false;

    if (this.containDate(currentDay, pickUpDay, pickUpDay)) {
      result.push(<DayBox currentDay={currentDay} kind="pickUpBox" />);
    }

    /**
     * If currentDay > pickUpDay && currentDay < firstDeliveryDay
     * then generate a DotBox
     */
    if (
      this.isBefore(pickUpDay, currentDay) &&
      this.isBefore(currentDay, firstDeliveryDay)
    ) {
      result.push(<DayBox currentDay={currentDay} kind="dotBox" />);
    }
    /**
     * if currentDay < pickUpDay
     * then generate a DisableBox
     */
    if (this.isBefore(currentDay, pickUpDay)) {
      result.push(<DayBox currentDay={currentDay} disable />);
    }

    /**
     * Parse Days object
     */
    for (let i = 0; i < days.length; i++) {
      const element = days[i];
      const fromDay = moment(element.from).toDate();
      const toDay = moment(element.to).toDate();

      /**
       *  IF element is on two weeks
       */
      if (element.grouped && this.containSaturday(fromDay, toDay)) {
        const totalDuration = this.duration(fromDay, toDay);
        const firstDuration = 6 - fromDay.getDay();
        const secondDuration = totalDuration - firstDuration - 1;
        const firstDay = moment(fromDay).toDate();
        const seconDate = moment(
          firstDay.setDate(firstDay.getDate() + firstDuration + 1)
        ).toDate();
        if (this.containDate(currentDay, fromDay, fromDay)) {
          result.push(
            <DayBox
              totalRate={element.totalRate}
              color={element.color}
              topLeftIcon={element.topLeftIcon}
              duration={firstDuration}
              currentDay={currentDay}
              selected={selected && selected.getTime() === fromDay.getTime()}
              onSelect={onSelect}
              twoWeeks={fromDay}
              element={element}
            />
          );
          break;
        } else if (this.containDate(currentDay, seconDate, seconDate)) {
          result.push(
            <DayBox
              totalRate={element.totalRate}
              color={element.color}
              topLeftIcon={element.topLeftIcon}
              duration={secondDuration}
              currentDay={seconDate}
              selected={selected && selected.getTime() === fromDay.getTime()}
              onSelect={onSelect}
              twoWeeks={fromDay}
              element={element}
            />
          );
          break;
        }
      }
      /**
       * IF currentDay === element.from
       */
      if (this.containDate(currentDay, fromDay, fromDay)) {
        result.push(
          <DayBox
            totalRate={element.totalRate}
            color={element.color}
            topLeftIcon={element.topLeftIcon}
            duration={element.grouped ? this.duration(fromDay, toDay) : 0}
            currentDay={currentDay}
            selected={selected && selected.getTime() === currentDay.getTime()}
            onSelect={onSelect}
            element={element}
          />
        );
        break;
      }
      /**
       * If current is contained between from and to day
       */
      if (this.containDate(currentDay, fromDay, toDay)) {
        if (element.grouped) {
          grouped = true;
          break;
        }
        result.push(
          <DayBox
            totalRate={element.totalRate}
            color={element.color}
            topLeftIcon={element.topLeftIcon}
            duration={element.grouped ? this.duration(fromDay, toDay) : 0}
            currentDay={currentDay}
            selected={selected && selected.getTime() === currentDay.getTime()}
            onSelect={onSelect}
            element={element}
          />
        );
        break;
      }
    }
    if (result.length > 0 || grouped) {
      return result;
    }
    /**
     * If result is alays empty but is contained
     * between the firstDeliveryDay and the lastCalendarDay
     * then generate A disable box
     */
    if (this.containDate(currentDay, firstDeliveryDay, lastCalendarDay)) {
      result.push(<DayBox currentDay={currentDay} disable />);
    }
    return result;
  }
  generateCalendar() {
    const table = [];
    const { createdAt, days } = this.props;
    const pickUpDay = moment(createdAt).toDate();
    const firstSunday = this.getFirstSunday(createdAt);
    const currentDay = this.getFirstSunday(createdAt);
    let id = 0;
    console.log(isNaN(pickUpDay));
    console.log(this.isValid(days));

    if (isNaN(pickUpDay) || !this.isValid(days)) {
      return "Invalid  dates : Check the API";
    }
    // 3 WEEKS = 3 LINES
    for (let i = 0; i < 3; i++) {
      const children = [];
      // 7 DAYS = 7 COLUMNS
      for (let j = 0; j < 7; j++) {
        const boxType = this.getBoxType(currentDay, pickUpDay, firstSunday, id);
        if (boxType !== null) {
          children.push(boxType);
        }
        currentDay.setDate(currentDay.getDate() + 1);
        id++;
      }

      // Create the parent and add the children
      table.push(
        <Grid
          container
          spacing={24}
          alignItems="center"
          style={{ paddingBottom: 5 }}
        >
          {children}
        </Grid>
      );
    }
    return table;
  }
  duration(start, to) {
    const day = moment(start).toDate();
    let i = 0;
    while (day.getDate() !== to.getDate()) {
      day.setDate(day.getDate() + 1);
      i++;
    }
    return i;
  }
  containSaturday(start, to) {
    const date = moment(start).toDate();
    while (date.getTime() !== to.getTime()) {
      if (date.getDay() === 6) {
        return true;
      }
      date.setDate(date.getDate() + 1);
    }
    return false;
  }
  containDate(date, start, to) {
    if (date.getTime() >= start.getTime() && date.getTime() <= to.getTime()) {
      return true;
    }
    return false;
  }

  isBefore(start, to) {
    if (start.getTime() < to.getTime() && start.getTime() !== to.getTime()) {
      return true;
    }
    return false;
  }

  /**
   *
   */
  isValid(days) {
    for (let i = 0; i < days.length; i++) {
      const element = days[i];
      const fromDay = moment(element.from).toDate();
      const toDay = moment(element.to).toDate();
      if (isNaN(fromDay) || isNaN(toDay)) {
        return false;
      }
    }
    return true;
  }
  render() {
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Grid container alignItems="center" spacing={24}>
          {days.map((value) => (
            <Grid key={value} item>
              <div className={classes.dayBox}>
                <Typography
                  display="block"
                  variant="h6"
                  color="textSecondary"
                  align="center"
                >
                  {value}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
        {this.generateCalendar()}
      </div>
    );
  }
}
export default withStyles(styles)(ServiceSelector);
