import { networkAction } from "helpers/network/networkAction";

import { GET_PERSON_NAMES, GET_QUOTES } from "constants/v1";

import * as Api from "api";

export const getPersonNames = () => async (dispatch) =>
  networkAction(dispatch, GET_PERSON_NAMES, Api.getPersonNames, []);

export const getQuotes = (filters) => async (dispatch) =>
  networkAction(dispatch, GET_QUOTES, Api.getQuotes, [filters]);
