import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import Grow from "@material-ui/core/Grow";
import IconButton from "@material-ui/core/IconButton";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Add from "@material-ui/icons/AddCircleOutline";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Close from "@material-ui/icons/Close";
import Info from "@material-ui/icons/Info";
// material-ui
import { withStyles } from "@material-ui/styles";
import DatePicker from "components/DatePicker";
import Help from "components/Help";
// custom
import LocationFinder from "components/LocationFinder";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Card from "../Card";
import {
  ADD,
  CONSIGNEE,
  LTL,
  MAX_LOCATION_AUTOCOMPLETE_ITEMS,
  REMOVE,
  SHIPPER,
  TL,
} from "../constants";
// styles
import styles from "./styles";

class Stop extends Component {
  static propTypes = {
    accessorials: PropTypes.object,
    csr: PropTypes.bool,
    accessorialIds: PropTypes.array,
    holidays: PropTypes.array,
    quoteAccessorials: PropTypes.array,
    theme: PropTypes.object,
    onDelete: PropTypes.func,
    onChange: PropTypes.func,
    searchLocations: PropTypes.func,
    type: PropTypes.string,
    locationId: PropTypes.string,
    index: PropTypes.number,
    disabled: PropTypes.bool,
    zip: PropTypes.string,
    service: PropTypes.string,
    date: PropTypes.string,
    nextClicked: PropTypes.bool,
    newLocation: PropTypes.func,
    name: PropTypes.string,
    address: PropTypes.object,
    gatherErrorFunction: PropTypes.func,
    getLocationById: PropTypes.func,
    updateLocation: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { gatherErrorFunction, type, index } = this.props;
    this.state = {
      anchorEl: undefined,
    };

    gatherErrorFunction(
      {
        errorFunction: this.hasError.bind(this),
        section: `${type} #${index + 1}`,
      },
      ADD
    );
  }

  componentWillUnmount() {
    const { gatherErrorFunction, type, index } = this.props;
    gatherErrorFunction(
      {
        errorFunction: this.hasError.bind(this),
        section: `${type} #${index + 1}`,
      },
      REMOVE
    );
  }

  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  hasError() {
    const {
      zip,
      locationId,
      type,
      date,
      // service,
      csr,
    } = this.props;

    // if ((service === TL) && (locationId === undefined) && !csr) return true;
    if (zip === "") return true;
    if (type === SHIPPER && (date === null || date === undefined)) return true;
    if (type === SHIPPER && locationId === undefined && !csr) return true;

    return false;
  }

  render() {
    const {
      type,
      onDelete,
      index,
      disabled,
      zip,
      nextClicked,
      onChange,
      newLocation,
      service,
      date,
      theme,
      accessorials,
      accessorialIds,
      searchLocations,
      locationId,
      csr,
      holidays,
      name,
      address,
      getLocationById,
      quoteAccessorials,
      updateLocation,
    } = this.props;

    const { NotificationCenter } = this.context;

    const { anchorEl } = this.state;

    const primaryColor = theme.palette.primary.color[500];

    let filteredAccessorials = [];
    if (service === LTL) {
      filteredAccessorials = accessorials.ltlAccessorials;
    }
    if (service === TL) {
      filteredAccessorials = accessorials.tlAccessorials;
    }

    // overwrite accessorial costAmount with whats the quote result provides.
    for (const key in filteredAccessorials) {
      if (filteredAccessorials.hasOwnProperty(key)) {
        const a = filteredAccessorials[key];
        const o =
          quoteAccessorials &&
          quoteAccessorials.find((qa) => qa.accessorialId === a.accessorialId);
        if (o) {
          a.costAmount = o.costAmount;
        }
      }
    }

    const accessorialsToAdd = filteredAccessorials.filter((a) => {
      return (
        accessorialIds &&
        accessorialIds.find((aid) => aid === a.accessorialId) === undefined &&
        a.quoteVisible
      );
    });

    return (
      <Card
        nextClicked={nextClicked}
        error={this.hasError()}
        primaryColor={primaryColor}
        disabled={disabled}
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item style={{ paddingRight: 8 }}>
                {type === SHIPPER ? <ArrowUpward /> : <ArrowDownward />}
              </Grid>
              <Grid item>
                <Typography display="block" variant="h6">
                  {type} #{index + 1}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container alignItems="center">
              {accessorialsToAdd.length ? (
                <Grid item>
                  <Help explanation="Add optional accessorials to the load">
                    <Chip
                      avatar={
                        <Avatar style={{ background: "none" }}>
                          <Add />
                        </Avatar>
                      }
                      variant="outlined"
                      label="Accessorials"
                      aria-owns={anchorEl ? "simple-menu" : undefined}
                      aria-haspopup="true"
                      onClick={(e) => {
                        if (disabled !== true) {
                          this.handleClick(e);
                        }
                      }}
                      style={{
                        opacity: disabled ? 0.5 : 1,
                      }}
                    />
                  </Help>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose}
                  >
                    {accessorialsToAdd.map((accessorial) => {
                      if (accessorial.quoteVisible) {
                        return (
                          <MenuItem
                            onClick={() => {
                              const newAccessorialIds = accessorialIds.slice();
                              newAccessorialIds.push(accessorial.accessorialId);
                              const e = {
                                target: {
                                  value: newAccessorialIds,
                                },
                              };
                              onChange(e, "accessorialIds");
                              this.handleClose();
                            }}
                            key={accessorial.accessorialId}
                          >
                            <ListItemText
                              primary={accessorial.name}
                              secondary={`$${accessorial.costAmount} / ${accessorial.unitType}`}
                            />
                          </MenuItem>
                        );
                      }
                      return [];
                    })}
                  </Menu>
                </Grid>
              ) : (
                []
              )}
              {onDelete && (
                <Grid item>
                  <Tooltip title={`Remove ${type}`}>
                    <IconButton
                      aria-label="Delete"
                      onClick={() => onDelete(type, index)}
                    >
                      <Close fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={12} md={type === CONSIGNEE ? 6 : 4}>
            <TextField
              disabled={disabled}
              id="zip"
              label="Location"
              placeholder={"Zipcode or City, State"}
              onClick={() =>
                NotificationCenter.snack(
                  <span>
                    Inaccurate locations may lead to a higher price and may void
                    the quote discount
                    <Typography
                      display="block"
                      variant="caption"
                      color="textSecondary"
                      style={{ color: "rgba(255,255,255,0.56)" }}
                    >
                      You should select a facility in addition to the zipcode.
                    </Typography>
                  </span>,
                  <Info />
                )
              }
              fullWidth
              helperText={
                (csr ? zip.length === 0 : zip.length < 5) ? "Required" : ""
              }
              value={zip}
              onChange={(e) => onChange(e, "zip")}
              InputLabelProps={{
                shrink: true,
              }}
              error={zip === "" && nextClicked}
            />
          </Grid>
          <Grid item xs={12} md={type === CONSIGNEE ? 6 : 4}>
            <Help explanation="Providing a facility helps to have an accurate price. Facilities are provided by Archer and Google Maps.">
              <LocationFinder
                maxLocationAutocompleteItems={MAX_LOCATION_AUTOCOMPLETE_ITEMS}
                error={
                  (locationId === undefined && nextClicked && service === TL) ||
                  (type === SHIPPER &&
                    nextClicked &&
                    locationId === undefined &&
                    !csr)
                } // eslint-disable-line
                // helperText={((type === SHIPPER) || (service === TL)) && !csr ? 'Required' : ''}
                helperText={type === SHIPPER && !csr ? "Required" : ""}
                zip={zip.length >= 5 ? zip : undefined}
                searchLocations={searchLocations}
                service={service}
                onChange={(e) => {
                  if (e.target.value) {
                    const z =
                      e.target.value.address && e.target.value.address.zip;
                    if (z) onChange({ target: { value: z } }, "zip");
                    onChange(
                      { target: { value: e.target.value.id } },
                      "locationId"
                    );
                  } else {
                    onChange({ target: { value: undefined } }, "locationId");
                  }
                }}
                newLocation={newLocation}
                value={address && locationId ? name : ""}
                getLocationById={getLocationById}
                updateLocation={updateLocation}
                locationId={locationId}
              />
            </Help>
          </Grid>
          {type === SHIPPER && (
            <Grid item xs={12} md={4}>
              <DatePicker
                csr={csr}
                ltl={service === LTL}
                holidays={holidays}
                label={type === SHIPPER ? "Pickup Date" : "Drop-off Date"}
                value={date || null}
                helperText={
                  type === SHIPPER && (date === null || date === undefined)
                    ? "Required"
                    : ""
                }
                onChange={(e) => onChange({ target: { value: e } }, "date")}
                renderDay={this.renderDay}
                error={(date === null || date === undefined) && nextClicked}
              />
            </Grid>
          )}
          {/* {
            type === SHIPPER
            && (
              <Grid item xs={12} md={3}>
                <TextField
                  disabled={disabled}
                  id="time"
                  label={`${type} Time`}
                  placeholder="Chose Time"
                  type="time"
                  fullWidth
                  value={time}
                  onChange={e => onChange(e, 'time')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
            )
          } */}
          <Grid item xs={12} style={{ paddingLeft: 8, paddingTop: 8 }}>
            {accessorialIds && accessorialIds.length ? (
              <Typography display="block" variant="subtitle" gutterBottom>
                Accessorials:
              </Typography>
            ) : (
              <div />
            )}
            <Grid container spacing={2}>
              {accessorialIds &&
                accessorialIds.map((aId, i) => {
                  const accessorial = filteredAccessorials.find(
                    (a) => a.accessorialId === aId
                  );
                  if (accessorial) {
                    return (
                      <Grid item key={`accessorials_${aId}`}>
                        <Grow in>
                          <Chip
                            key={`accessorials_${aId}`}
                            label={`${accessorial.name}, $${accessorial.costAmount} / ${accessorial.unitType}`}
                            onDelete={() => {
                              const newAccessorialIds = accessorialIds.slice();
                              newAccessorialIds.splice(Number(i), 1);
                              const e = {
                                target: {
                                  value: newAccessorialIds,
                                },
                              };
                              onChange(e, "accessorialIds");
                              this.handleClose();
                            }}
                          />
                        </Grow>
                      </Grid>
                    );
                  }
                  return [];
                })}
            </Grid>
          </Grid>
        </Grid>
      </Card>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Stop);
