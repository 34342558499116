import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
// constants
import { REACT_APP_SUPPORT_EMAIL } from "config";
import PropTypes from "prop-types";
import React, { Component } from "react";
import Highlight from "react-highlight";
// images
import errorImg from "./error.png";
// styles
import styles from "./styles";

class ErrorCatcher extends Component {
  static propTypes = {
    children: PropTypes.node,
    additionalDebugInfo: PropTypes.string,
    history: PropTypes.object,
  };

  state = {
    hasError: false,
  };

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({
      hasError: true,
      error,
      info,
    });

    console.log("********ComponentDidCatch********");
    console.log(error);
    console.log(info);
    console.log("*********************************");
  }

  render() {
    const { children, history, additionalDebugInfo } = this.props;
    const { hasError, error, info } = this.state;

    if (hasError) {
      const debugString = `${`${error.toString()}\r\n-------\r\nStack Trace:\r\n`}
      ${info.componentStack}
      ${`\r\n-------\r\nLocation Href:\r\n${window.location.href}`}
      ${`\r\n-------\r\nNavigator:\r\n${window.navigator.appName}\r\n${window.navigator.appCodeName}\r\n${window.navigator.platform} - ${window.navigator.userAgent}`}
      ${
        additionalDebugInfo
          ? `\r\n-------\r\nAdditional Debug Info:\r\n${additionalDebugInfo}`
          : ""
      }`;

      return (
        <Grid
          container
          direction="column"
          style={{
            display: "flex",
            flexGrow: 1,
          }}
          alignItems="center"
          justify="center"
        >
          <Grid item style={{ textAlign: "center" }}>
            <img
              src={errorImg}
              alt="truck"
              style={{ width: "80%", maxWidth: 500 }}
            />
            <Typography display="block" variant="h2">
              Oh no!
            </Typography>
            <Typography display="block" variant="h6">
              Something went wrong.
            </Typography>
            <Typography display="block" variant="body" color="textSecondary">
              A critical error just occurs on the frontend. More information are
              available below.
            </Typography>
          </Grid>
          <Grid item style={{ display: "flex", marginTop: 20 }}>
            <Button
              variant="outlined"
              style={{ marginRight: 20 }}
              onClick={() => history.push("/")}
            >
              Retry
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() =>
                window.open(
                  `mailto:${REACT_APP_SUPPORT_EMAIL}?subject=Frontend%20Crash%20Report&body=${`${encodeURI(
                    `PLEASE DESCRIBE HOW IT HAPPENED\r\n\r\n\r\nDO NOT REMOVE THE FOLLOWING:\r\n------------------\r\n${debugString}`
                  )}`}`,
                  "_blank"
                )
              }
            >
              Report to Developers
            </Button>
          </Grid>
          <Grid
            item
            style={{
              marginTop: 20,
              background: "#212121",
              padding: 8,
              borderRadius: 4,
              color: "white",
            }}
          >
            <Highlight language={"js"}>{debugString}</Highlight>
          </Grid>
        </Grid>
      );
    }

    return children;
  }
}

export default withStyles(styles)(ErrorCatcher);
