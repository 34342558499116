// actions
import * as Actions from "actions";
// helpers
import { getJsonFromUrl } from "helpers";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
// component
import WrapperQuotePage from "../component/WrapperQuotePage";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  checkPallets: (...args) => dispatch(Actions.checkPallets(...args)),
  getAccessorials: (...args) => dispatch(Actions.getAccessorials(...args)),
  searchLocations: (...args) => dispatch(Actions.searchLocations(...args)),
  newLocation: (...args) => dispatch(Actions.newLocation(...args)),
  newQuote: (...args) => dispatch(Actions.newQuote(...args)),
  searchCustomers: (...args) => dispatch(Actions.searchCustomers(...args)),
  getHolidays: (...args) => dispatch(Actions.getHolidays(...args)),
  getQuoteById: (...args) => dispatch(Actions.getQuoteById(...args)),
  getLocationById: (...args) => dispatch(Actions.getLocationById(...args)),
  updateLocation: (...args) => dispatch(Actions.updateLocation(...args)),
  convertQuote: (...args) => dispatch(Actions.convertQuote(...args)),
  getCostBreakdown: (...args) => dispatch(Actions.getCostBreakdown(...args)),
  getMarketCosts: (...args) => dispatch(Actions.getMarketCosts(...args)),
  getCommodityDescriptions: (...args) =>
    dispatch(Actions.getCommodityDescriptions(...args)),
});
class QuotePage extends Component {
  static propTypes = {
    history: PropTypes.object,
    location: PropTypes.object,

    // Quote
    checkPallets: PropTypes.func,
    getAccessorials: PropTypes.func,
    searchLocations: PropTypes.func,
    newLocation: PropTypes.func,
    newQuote: PropTypes.func,
    searchCustomers: PropTypes.func,
    getHolidays: PropTypes.func,
    getQuoteById: PropTypes.func,
    getLocationById: PropTypes.func,
    updateLocation: PropTypes.func,
    convertQuote: PropTypes.func,
    getCostBreakdown: PropTypes.func,
    getMarketCosts: PropTypes.func,
    getCommodityDescriptions: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const {
      location,
      getQuoteById,
      getCostBreakdown,
      getMarketCosts,
    } = this.props;
    const urlParams = getJsonFromUrl(location);
    const quoteID = urlParams.id;
    this.state = {
      loading: false,
      debug: urlParams.debug === "true",
      askPickup: false,
      quoteID,
    };

    if (quoteID) {
      this.state.loading = true;

      try {
        getQuoteById(quoteID).then((resp) => {
          if (resp.payload) {
            const quote = resp.payload;
            this.setState({
              quote,
              askPickup: quote.stops[0].date === undefined,
              loading: false,
            });
            getCostBreakdown(quoteID).then((r) => {
              if (r.success) {
                const costBreakdown = r.payload;
                this.setState({ costBreakdown });
              }
            });
            getMarketCosts(quoteID).then((r) => {
              if (r.success) {
                const marketCosts = r.payload;
                this.setState({ marketCosts });
              }
            });
          } else {
            this.setState({
              loading: false,
            });
          }
        });
      } catch (error) {
        console.log("Error");
      }
    }
  }

  async convertQuote(quoteID, body) {
    const { convertQuote, getCostBreakdown, getMarketCosts } = this.props;
    this.setState({
      loading: true,
      askPickup: false,
    });
    const resp = await convertQuote(quoteID, body);
    if (resp.success) {
      getCostBreakdown(quoteID).then((r) => {
        if (r.success) {
          const costBreakdown = r.payload;
          this.setState({ costBreakdown });
        }
      });
      getMarketCosts(quoteID).then((r) => {
        if (r.success) {
          const marketCosts = r.payload;
          this.setState({ marketCosts });
        }
      });
      this.setState({
        quote: resp.payload,
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  }

  async newQuote(...args) {
    const { newQuote, getCostBreakdown, getMarketCosts } = this.props;
    this.setState({ costBreakdown: undefined, marketCosts: undefined });
    const resp = await newQuote(...args);
    const quote = resp.payload;
    this.setState({ quote });
    if (resp.success) {
      getCostBreakdown(quote.id).then((r) => {
        const costBreakdown = r.payload;
        this.setState({ costBreakdown });
      });
      getMarketCosts(quote.id).then((r) => {
        if (r.success) {
          const marketCosts = r.payload;
          this.setState({ marketCosts });
        }
      });
    }
    return resp;
  }

  restart() {
    this.setState({
      quote: undefined,
      costBreakdown: undefined,
    });
  }

  render() {
    const {
      location,
      history,
      checkPallets,
      getCommodityDescriptions,
      getAccessorials,
      searchLocations,
      newLocation,
      searchCustomers,
      getHolidays,
      getLocationById,
      updateLocation,
    } = this.props;

    const {
      loading,
      quote,
      debug,
      askPickup,
      quoteID,
      costBreakdown,
      marketCosts,
    } = this.state;

    return (
      <WrapperQuotePage
        csr
        history={history}
        location={location}
        urlParams={getJsonFromUrl(location)}
        quoteID={quoteID}
        checkPallets={checkPallets}
        getAccessorials={getAccessorials}
        getCommodityDescriptions={getCommodityDescriptions}
        searchLocations={searchLocations}
        newLocation={newLocation}
        newQuote={this.newQuote.bind(this)}
        restart={this.restart.bind(this)}
        askPickup={askPickup}
        searchCustomers={searchCustomers}
        getHolidays={getHolidays}
        getLocationById={getLocationById}
        updateLocation={updateLocation}
        costBreakdown={costBreakdown}
        marketCosts={marketCosts}
        loading={loading}
        quote={quote}
        convertQuote={this.convertQuote.bind(this)}
        debug={debug}
        key={quote && quote.id}
      />
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(QuotePage);
