// creativeTim
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import Typography from "@material-ui/core/Typography";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorIcon from "@material-ui/icons/Error";
import InfoIcon from "@material-ui/icons/Info";
import WarningIcon from "@material-ui/icons/Warning";
// material-ui
import withStyles from "@material-ui/styles/withStyles";
import {
  dangerColor,
  infoColor,
  successColor,
  warningColor,
} from "assets/jss/material-kit-react";
import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";
// open source
import SweetAlert from "react-bootstrap-sweetalert";
// styles
import styles from "./styles";

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

class NotificationCenter extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    errors: PropTypes.object,
    children: PropTypes.node,
  };

  static childContextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      alert: null,
      stackBarOpen: false,
    };
  }

  getChildContext() {
    return {
      NotificationCenter: {
        sweetAlert: this.sweetAlert.bind(this),
        stack: this.stack.bind(this),
        snack: this.snack.bind(this),
        hide: this.hide.bind(this),
      },
    };
  }

  componentWillReceiveProps(nextProps) {
    const { errors } = this.props;
    const nextErrors = nextProps.errors;

    try {
      if (nextErrors.notification) {
        if (errors && errors.notification) {
          if (
            errors.notification.message.timestamp !==
            nextErrors.notification.message.timestamp
          ) {
            this.stack(
              nextErrors.notification.message,
              nextErrors.notification.actions
            );
          }
        } else {
          this.stack(
            nextErrors.notification.message,
            nextErrors.notification.actions
          );
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  sweetAlert(message, actions) {
    const { classes } = this.props;
    let confirmLevel = "default";
    let cancelLevel = "default";

    if (actions && actions.confirm && actions.confirm.level) {
      confirmLevel = actions.confirm.level;
    }

    if (actions && actions.cancel && actions.cancel.level) {
      cancelLevel = actions.cancel.level;
    }

    this.setState({
      alert: (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={message.title}
          success={message.success}
          warning={message.warning}
          error={message.error}
          info={message.info}
          onConfirm={() => {
            this.hide();
            if (actions && actions.confirm && actions.confirm.callback) {
              actions.confirm.callback();
            }
          }}
          onCancel={() => {
            this.hide();
            if (actions && actions.cancel && actions.cancel.callback) {
              actions.cancel.callback();
            }
          }}
          confirmBtnCssClass={`${classes.button} ${classes[confirmLevel]}`}
          cancelBtnCssClass={`${classes.button} ${classes[cancelLevel]}`}
          confirmBtnText={
            actions && actions.confirm
              ? actions && actions.confirm.label
              : undefined
          }
          cancelBtnText={
            actions && actions.cancel
              ? actions && actions.cancel.label
              : undefined
          }
          showCancel={actions !== undefined && actions.cancel !== undefined}
          showConfirm={actions !== undefined && actions.confirm !== undefined}
        >
          <Typography
            display="block"
            variant="caption"
            gutterBottom
            align="center"
          >
            {message.subtitle}
          </Typography>
          <Typography
            display="block"
            variant="body2"
            gutterBottom
            align="center"
          >
            {message.body}
          </Typography>
        </SweetAlert>
      ),
    });
  }

  stack(message, actions) {
    this.setState({
      stackBarOpen: true,
      stackbarMessage: message,
      stackbarActions: actions,
    });
  }

  snack(message, icon) {
    this.setState({
      snackbarOpen: true,
      snackbarIcon: icon,
      snackbarMessage: message,
    });
  }

  hide() {
    this.setState({
      alert: null,
      stackBarOpen: false,
      snackbarOpen: false,
    });
  }

  render() {
    const {
      alert,
      stackbarMessage,
      stackbarActions,
      stackBarOpen,
      snackbarMessage,
      snackbarIcon,
      snackbarOpen,
    } = this.state;

    const { children, classes } = this.props;

    let message = "";
    const actions = [];

    if (stackbarActions) {
      if (stackbarActions.cancel) {
        actions.push(
          <Button
            key="snack_cancel"
            className={classes[`${stackbarActions.cancel.level}Button`]}
            size="small"
            style={{
              textTransform: "none",
            }}
            onClick={() => {
              this.hide();
              if (stackbarActions.cancel.callback) {
                stackbarActions.cancel.callback();
              }
            }}
          >
            {stackbarActions.cancel.label || "CANCEL"}
          </Button>
        );
      }

      if (stackbarActions.confirm) {
        actions.push(
          <Button
            key="snack_confirm"
            className={classnames(
              classes[`${stackbarActions.confirm.level}Button`],
              classes.leftMargin
            )}
            style={{
              textTransform: "none",
            }}
            size="small"
            onClick={() => {
              this.hide();
              if (stackbarActions.confirm.callback) {
                stackbarActions.confirm.callback();
              }
            }}
          >
            {stackbarActions.confirm.label || "OK"}
          </Button>
        );
      }
    }

    let background;
    let Icon;
    if (stackbarMessage) {
      if (stackbarMessage.warning) {
        Icon = variantIcon.warning;
        background = warningColor;
      }

      if (stackbarMessage.success) {
        Icon = variantIcon.success;
        background = successColor;
      }

      if (stackbarMessage.error) {
        Icon = variantIcon.error;
        background = dangerColor;
      }

      if (stackbarMessage.info) {
        Icon = variantIcon.info;
        background = infoColor;
      }

      message = (
        <div style={{ color: "white" }}>
          <Typography
            display="block"
            style={{ color: "white" }}
            variant="h6"
            gutterBottom
            align="left"
          >
            {Icon ? <Icon /> : []}
            {stackbarMessage.title}
          </Typography>
          <Typography
            display="block"
            style={{ color: "white" }}
            variant="caption"
            gutterBottom
            align="left"
          >
            {stackbarMessage.subtitle}
          </Typography>
          <Typography
            display="block"
            style={{ color: "white" }}
            variant="body2"
            gutterBottom
            align="left"
          >
            {stackbarMessage.body}
          </Typography>
        </div>
      );
    }

    return (
      <div>
        {children}
        {alert}
        {stackbarMessage && (
          <Snackbar
            key={stackbarMessage.timestamp}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            style={{
              zIndex: 999999999,
            }}
            autoHideDuration={null}
            open={stackBarOpen}
            onClose={this.hide.bind(this)}
            ContentProps={{
              "aria-describedby": "message-id",
            }}
          >
            <SnackbarContent
              message={message}
              action={actions}
              style={{
                background,
              }}
            />
          </Snackbar>
        )}
        {snackbarMessage && (
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            style={{
              zIndex: 999999999,
            }}
            autoHideDuration={10000}
            open={snackbarOpen}
            onClose={this.hide.bind(this)}
          >
            <SnackbarContent
              message={
                <Grid container alignItems="center" spacing={1}>
                  <Grid item style={{ width: 30 }}>
                    {snackbarIcon}
                  </Grid>
                  <Grid item style={{ width: "calc(100% - 30px)" }}>
                    {snackbarMessage}
                  </Grid>
                </Grid>
              }
            />
          </Snackbar>
        )}
      </div>
    );
  }
}

export default withStyles(styles)(NotificationCenter);
