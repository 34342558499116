export default (theme) => ({
  container: {
    padding: 5,
  },
  notes: {
    background: "#fff9c4",
    borderRadius: 4,
    border: "solid 1px #ffee58",
    marginTop: 20,
  },
});
