// material-ui
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class TableIsPublic extends Component {
  static propTypes = {
    classes: PropTypes.object,
    datum: PropTypes.object,
  };

  render() {
    const { datum } = this.props;

    return (
      <Typography display="block">
        {datum.public ? "Public" : "Private"}
      </Typography>
    );
  }
}

export default withStyles(styles)(TableIsPublic);
