import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import Close from "@material-ui/icons/Close";
import Edit from "@material-ui/icons/Edit";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// images
import dimension from "./Assets/dimension.png";
import stack from "./Assets/stack.png";
// styles
import styles from "./styles";

export const INCHES = "inches";

const COLOR_TYPE = {
  A: "#f44336",
  B: "#e91e63",
  C: "#9c27b0",
  D: "#3f51b5",
  E: "#2196f3",
  F: "#03a9f4",
  G: "#00bcd4",
  H: "#4caf50",
  I: "#8bc34a",
  J: "#cddc39",
};

class Pallet extends Component {
  static propTypes = {
    index: PropTypes.number,
    classes: PropTypes.object,
    count: PropTypes.number,
    length: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number,
    units: PropTypes.string,
    stackable: PropTypes.bool,
    remaining: PropTypes.bool,
    disabled: PropTypes.bool,
    onDelete: PropTypes.func,
    type: PropTypes.string,
    onDimensionEdit: PropTypes.func,
  };

  render() {
    const {
      classes,
      count,
      length,
      width,
      height,
      stackable,
      onDelete,
      units,
      onDimensionEdit,
      index,
      disabled,
      type,
      remaining,
    } = this.props;

    const unitSymbol = units === INCHES ? "″" : "′";

    return (
      <div>
        <Grid container alignItems="center" spacing={1}>
          {stackable && (
            <Grid item>
              <Tooltip title="Stackable">
                <img src={stack} alt="Stackable" height="15" />
              </Tooltip>
            </Grid>
          )}
          <Grid item>
            <img src={dimension} alt="dimension" height="15" />
          </Grid>
          <Grid item>
            <Typography display="block">
              {`${length}${unitSymbol}x${width}${unitSymbol}x${height}${unitSymbol}`}
            </Typography>
          </Grid>
          <Grid item>
            <div
              style={{
                padding: "2px 5px 2px 5px",
                color: "white",
                background: COLOR_TYPE[type],
                borderRadius: 20,
                fontSize: 12,
              }}
            >
              {type}
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          className={classes.container}
          justify="space-between"
          alignItems="center"
        >
          <Grid item>
            {count}{" "}
            <Typography display="block" variant="caption" color="textSecondary">
              {remaining ? "Remaining" : ""}
            </Typography>
          </Grid>
          {disabled !== true && (
            <Grid item>
              <Grid container>
                {onDimensionEdit ? (
                  <Grid item>
                    <Edit
                      onClick={() => onDimensionEdit(index)}
                      style={{ cursor: "pointer" }}
                    />
                  </Grid>
                ) : (
                  <div />
                )}
                {onDelete ? (
                  <Grid item>
                    <Close
                      onClick={() => onDelete(index)}
                      style={{ cursor: "pointer" }}
                    />
                  </Grid>
                ) : (
                  <div />
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(Pallet);
