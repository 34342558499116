import { networkAction } from "helpers/network/networkAction";

import {
  SEARCH_LOCATIONS,
  NEW_LOCATION,
  UPDATE_LOCATION,
  GET_LOCATION_BY_ID,
} from "constants/locations";

import * as Api from "api";

export const searchLocations = (params) => async (dispatch) =>
  networkAction(dispatch, SEARCH_LOCATIONS, Api.searchLocations, [params]);

export const newLocation = (body) => async (dispatch) =>
  networkAction(dispatch, NEW_LOCATION, Api.newLocation, [body]);

export const getLocationById = (id) => async (dispatch) =>
  networkAction(dispatch, GET_LOCATION_BY_ID, Api.getLocationById, [id]);

export const updateLocation = (id, body) => async (dispatch) =>
  networkAction(dispatch, UPDATE_LOCATION, Api.updateLocation, [id, body]);
