/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
// styles
import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
// material-ui
import { withStyles } from "@material-ui/styles";
import Moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import dots from "./dots.png";
import styles from "./styles";
import truck from "./truck.png";

class DayBox extends React.Component {
  static propTypes = {
    totalRate: PropTypes.number,
    color: PropTypes.string,
    topLeftIcon: PropTypes.string,
    duration: PropTypes.number,
    currentDay: PropTypes.string,
    onSelect: PropTypes.func,
    selected: PropTypes.string,
    classes: PropTypes.object,
    disable: PropTypes.bool,
    kind: PropTypes.string,
    element: PropTypes.object,
  };

  unibox(date) {
    const {
      totalRate,
      color,
      topLeftIcon,
      duration,
      onSelect,
      selected,
      classes,
      kind,
      twoWeeks,
      element,
    } = this.props;
    let width = 130 * duration + 100;

    return (
      <Grid item>
        {kind ? (
          <div className={classes.box}>
            {kind === "dotBox" ? (
              <div className={classes.dots}>
                <img alt="logo" src={dots} width="50" />
              </div>
            ) : (
              <div>
                <div className={classes.truck}>
                  <img alt="logo" src={truck} width="30" height="20" />
                </div>
                <div className={classes.pickUp}>
                  <Typography display="block" variant="body" align="center">
                    Pickup
                  </Typography>
                </div>
              </div>
            )}

            {this.footerLabel(date, 0)}
          </div>
        ) : (
          <div
            className={classes.box}
            style={{
              borderColor: color,
              background: selected ? color : undefined,
              color: selected ? "#ffff" : undefined,
              width: width,
            }}
            onClick={() => {
              onSelect({
                twoWeeks,
                date,
                element,
              });
            }}
          >
            {this.logo(topLeftIcon)}
            {this.rateLabel(totalRate)}
            {this.footerLabel(date, duration)}
          </div>
        )}
      </Grid>
    );
  }

  logo(iconPath) {
    const { classes } = this.props;

    return (
      <div className={classes.topLeftIcon}>
        <img alt="logo" src={iconPath} width="20" height="20" />
      </div>
    );
  }

  rateLabel(totalRate) {
    const { classes } = this.props;

    return (
      <div className={classes.rate}>
        <Typography display="block" variant="body" align="center">
          ${totalRate}
        </Typography>
      </div>
    );
  }

  footerLabel(date, duration) {
    const { classes } = this.props;
    const _date = new Date(date); // local copy, nothing will affect this.props.date
    let i = 0;
    const box = [];
    while (i <= duration) {
      box.push(
        <Grid item>
          <div className={classes.contentGroupedBox}>
            {this.dateLabel(_date)}
          </div>
        </Grid>
      );
      i++;
      _date.setDate(_date.getDate() + 1);
    }
    return (
      <Grid container spacing={24}>
        {" "}
        {box}
      </Grid>
    );
  }

  dateLabel(date) {
    const { classes, selected } = this.props;
    return (
      <div className={classes.date}>
        <Typography
          display="block"
          variant="caption"
          align="center"
          classes={{
            caption: selected ? classes.selected : undefined,
          }}
        >
          {Moment(date).format("MMMM Do")}
        </Typography>
      </div>
    );
  }

  disableBox(date) {
    const { classes } = this.props;
    return (
      <Grid item>
        <div className={classes.disableBox}>
          <div className={classes.date}>
            <Typography
              display="block"
              variant="caption"
              color="textSecondary"
              align="center"
            >
              {Moment(date).format("MMMM Do")}
            </Typography>
          </div>
        </div>
      </Grid>
    );
  }

  render() {
    const { disable, currentDay } = this.props;
    if (disable) {
      return this.disableBox(currentDay);
    }
    return this.unibox(currentDay);
  }
}

export default withStyles(styles)(DayBox);
