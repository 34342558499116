import { GET_QUOTES_V3 } from "constants/quotes";

export default (state = [], action) => {
  switch (action.type) {
    case `${GET_QUOTES_V3}_SUCCESS`:
      return action.payload;
    default:
      return state;
  }
};
