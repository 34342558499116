import { DRY, REEFER } from "./constants";

export function positiveNumber(number) {
  return (
    !isNaN(parseFloat(number)) && isFinite(number) && parseFloat(number) >= 0
  );
}

export function validNumber(number) {
  return !isNaN(parseFloat(number)) && isFinite(number) && parseFloat(number);
}

export function safariFix(date) {
  return date && date.replace && date.replace(/-/g, "/");
}

export function formattedPrice(x) {
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return formatter.format(x);
}

export function sameDay(d1, d2) {
  return (
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate()
  );
}

export function getQuoteBody(quote) {
  const shippers = quote.stops.filter((s) => s.type === "shipper");
  shippers.forEach((s) => {
    s.locationId = s.address.locationId;
    const stopAccessorials =
      quote.accessorials && quote.accessorials.filter((a) => a.stopId === s.id);
    const accessorialIds = [];
    stopAccessorials &&
      stopAccessorials.forEach((sa) => accessorialIds.push(sa.accessorialId));
    s.accessorialIds = accessorialIds;
  });
  const consignees = quote.stops.filter((s) => s.type === "consignee");
  consignees.forEach((c) => {
    c.locationId = c.address.locationId;
    const stopAccessorials =
      quote.accessorials && quote.accessorials.filter((a) => a.stopId === c.id);
    const accessorialIds = [];
    stopAccessorials &&
      stopAccessorials.forEach((sa) => accessorialIds.push(sa.accessorialId));
    c.accessorialIds = accessorialIds;
  });

  return {
    customerName: quote.customerName,
    service: quote.type,
    freightClass: quote.freightClass,
    commodityDescriptionId: quote.commodityDescriptionId,
    mode: quote.temp !== undefined ? REEFER : DRY,
    dryVanOnly: quote.dryVanOnly,
    shippers,
    consignees,
    units: {
      palletDimensions: quote.units.palletDimensions || [],
      totalDimensions: quote.units.totalDimensions,
    },
    reefer: {
      reeferTemp: `${Math.round(Number(quote.temp))}`,
      reeferCont: quote.reeferCont,
    },
    customerId: quote.customerId,
  };
}
