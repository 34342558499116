export default (theme) => ({
  margin: {
    margin: theme.spacing.unit,
  },
  paper: {
    width: 480,
    maxHeight: 274,
    overflowY: "scroll",
  },
});
