export const ROOT = "/";
export const SIGNIN = "/signin";
export const DEMO = "/demo";
export const DEFAULT = "/default";
export const MY_ACCOUNT = "/my-account";
export const USERS = "/users";
export const GROUP = "/groups";
export const ROLE = "/roles";
export const RULE = "/rules";
export const PATH = "/paths";
export const DEBUG = "/debug";
export const MARKDOWN = "/markdown";
export const DOCS = "/docs";
export const PROJECT_TYPE = "/global-settings";
export const CONTENT = "/content";
export const QUOTE = "/quote";
export const TENDER = "/tender";
export const QUOTES = "/quotes";
export const LOADS = "/loads";
