import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
// material-ui
import { withStyles } from "@material-ui/styles";
import Moment from "moment";
import { extendMoment } from "moment-range";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { formattedPrice, safariFix } from "../helpers";
// custom
import Day from "./Day";
// styles
import styles from "./styles";

const DAY_REF = {
  Sunday: 0,
  Monday: 1,
  Tuesday: 2,
  Wednesday: 3,
  Thursday: 4,
  Friday: 5,
  Saturday: 6,
};

const moment = extendMoment(Moment);

class Calendar extends Component {
  static propTypes = {
    classes: PropTypes.object,
    serviceLevel: PropTypes.object,
    pickupDate: PropTypes.string,
    onSelect: PropTypes.func,
    selected: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    const { pickupDate, serviceLevel } = this.props;

    const pickup = moment(safariFix(pickupDate));
    const from = moment(safariFix(serviceLevel.from));
    const to = moment(safariFix(serviceLevel.to));

    // check date format
    const dateError = !pickup.isValid() || !from.isValid() || !to.isValid();
    const firstSunday = pickup
      .clone()
      .subtract(DAY_REF[pickup.format("dddd")], "d");
    const lastSunday = to.clone().add(7 - DAY_REF[to.format("dddd")], "d");

    console.log(lastSunday.diff(firstSunday, "days"));

    if (lastSunday.diff(firstSunday, "days") === 7) {
      lastSunday.add(21, "d");
    } else if (lastSunday.diff(firstSunday, "days") === 14) {
      lastSunday.add(14, "d");
    } else if (lastSunday.diff(firstSunday, "days") === 21) {
      lastSunday.add(7, "d");
    }

    this.state = {
      pickup,
      from,
      to,
      dateError,
      firstSunday,
      lastSunday,
    };
  }

  onSelect(d) {
    const { serviceLevel, onSelect } = this.props;

    onSelect({
      element: serviceLevel,
      date: d.format("YYYY-MM-DD"),
    });
  }

  genCalendar() {
    const { pickup, from, to, firstSunday, lastSunday } = this.state;

    const { serviceLevel, selected } = this.props;

    const JSX = [];
    const d = firstSunday.clone();
    const serviceLevelRange = moment().range(from, to);
    const firstTransitDay = pickup.clone().add(1, "d");
    const lastTransitDay = from.clone().subtract(1, "d");
    const transitRange = moment().range(firstTransitDay, lastTransitDay);

    while (!d.isSame(lastSunday, "day")) {
      JSX.push(
        <Day
          day={d.clone()}
          key={d.toDate().getTime()}
          selectable={serviceLevelRange.contains(d)}
          grouped={serviceLevel.grouped && serviceLevelRange.contains(d)}
          color={serviceLevel.color}
          onSelect={this.onSelect.bind(this)}
          pickup={d.isSame(pickup, "day")}
          transit={transitRange.contains(d)}
          selected={
            selected &&
            selected.element.serviceLevel === serviceLevel.serviceLevel &&
            (serviceLevel.grouped
              ? serviceLevelRange.contains(d)
              : d.isSame(moment(selected.date), "day"))
          }
          first={d.isSame(firstTransitDay, "day") || d.isSame(from, "day")}
          last={d.isSame(lastTransitDay, "day") || d.isSame(to, "day")}
        />
      );
      d.add(1, "d");
    }

    return JSX;
  }

  render() {
    const { classes, serviceLevel } = this.props;
    const { pickup, dateError } = this.state;

    if (dateError) {
      return <div>Error: Check date</div>;
    }

    return (
      <div className={classes.wrapper}>
        {/* <div>
          pickup: {pickup.format('YYYY/MM/DD')}
        </div>
        <div>
          from: {from.format('YYYY/MM/DD')}
        </div>
        <div>
          to: {to.format('YYYY/MM/DD')}
        </div>
        <div>
          firstSunday: {firstSunday.format('YYYY/MM/DD')}
        </div>
        <div>
          lastSunday: {lastSunday.format('YYYY/MM/DD')}
        </div> */}
        <Grid container justify="space-between">
          <Grid item>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <img
                  src={serviceLevel.topLeftIcon}
                  alt={serviceLevel.name}
                  style={{ width: 35 }}
                />
              </Grid>
              <Grid item>
                <Typography
                  display="block"
                  variant="h6"
                  style={{
                    color: serviceLevel.color,
                    textTransform: "capitalize",
                  }}
                >
                  {serviceLevel.serviceLevel}
                </Typography>
                <Typography display="block" style={{ marginTop: -6 }}>
                  {serviceLevel.description}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography display="block" variant="h6">
              {formattedPrice(serviceLevel.totalRate)}
            </Typography>
          </Grid>
        </Grid>
        <div
          className={classes.container}
          style={{
            justifyContent: "space-between",
            padding: "0px 20px 0px 20px",
            width: "calc(100% - 40px)",
            textAlign: "center",
            marginTop: 20,
          }}
        >
          <Typography
            display="block"
            color="inherit"
            style={{ fontWeight: 600 }}
          >
            S
          </Typography>
          <Typography
            display="block"
            color="inherit"
            style={{ fontWeight: 600 }}
          >
            M
          </Typography>
          <Typography
            display="block"
            color="inherit"
            style={{ fontWeight: 600 }}
          >
            T
          </Typography>
          <Typography
            display="block"
            color="inherit"
            style={{ fontWeight: 600 }}
          >
            W
          </Typography>
          <Typography
            display="block"
            color="inherit"
            style={{ fontWeight: 600 }}
          >
            T
          </Typography>
          <Typography
            display="block"
            color="inherit"
            style={{ fontWeight: 600 }}
          >
            F
          </Typography>
          <Typography
            display="block"
            color="inherit"
            style={{ fontWeight: 600 }}
          >
            S
          </Typography>
        </div>
        <div style={{ flex: 1 }}>
          <Typography display="block" color="inherit" variant="caption">
            {pickup.format("MMMM YY")}
          </Typography>
        </div>
        <div className={classes.container}>{this.genCalendar()}</div>
      </div>
    );
  }
}

export default withStyles(styles)(Calendar);
