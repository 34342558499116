// material-ui
import SvgIcon from "@material-ui/core/SvgIcon";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";

class ArcherLogo extends Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
  };

  render() {
    const { theme } = this.props;

    return (
      <SvgIcon
        viewBox="0 0 579.41 165.83"
        style={{
          width: 101,
          height: 29,
        }}
      >
        <g>
          <defs>
            <rect id="SVGID_1_" width="170.25" height="165.83" />
          </defs>
          <polygon
            fill={theme.palette.primary.color[500]}
            class="st0"
            points="61.91,0 0,165.83 46.43,165.83 56.34,139.3 81.59,139.3 81.92,92.87 73.67,92.87 108.34,0 	"
          />
          <polygon
            class="st1"
            points="108.34,0 84.54,63.75 95.41,92.87 88.72,92.87 88.72,139.3 113.43,139.3 123.82,165.83 170.25,165.83
        		159.86,139.3 141.84,92.87 	"
          />
        </g>
        <path
          class="st2"
          d="M254.53,165.51h-32.7l-14.94-39.88c-0.15-0.68-0.4-1.57-0.76-2.68c-0.36-1.11-0.81-2.44-1.34-3.99l0.22,7.4
        	v39.15h-30.09V53.57h30.96c13.63,0,23.83,2.68,30.6,8.05c8.6,6.86,12.91,16.63,12.91,29.29c0,13.53-5.78,22.89-17.33,28.06
        	L254.53,165.51z M205.15,103.81h2.03c3.48,0,6.38-1.33,8.7-3.99c2.32-2.66,3.48-6.04,3.48-10.15c0-8.36-4.38-12.54-13.12-12.54
        	h-1.09V103.81z"
        />
        <path
          class="st2"
          d="M325.8,131.44l-1.74,30.34c-6.72,2.49-12.74,3.74-18.05,3.74c-12.52,0-23.06-4.43-31.61-13.3
        	c-9.57-9.94-14.36-24.01-14.36-42.21c0-17.04,4.5-30.71,13.49-41.02c8.99-10.27,21-15.4,36.03-15.4c4.88,0,9.86,0.88,14.94,2.64
        	v30.93c-4.5-4.5-9.11-6.74-13.85-6.74c-6.19,0-11.02,2.61-14.5,7.83c-3.48,5.27-5.22,12.55-5.22,21.85c0,4.5,0.44,8.53,1.31,12.07
        	c0.87,3.55,2.11,6.54,3.73,8.98c1.62,2.45,3.61,4.31,5.98,5.61c2.37,1.29,5.08,1.94,8.12,1.94
        	C315.28,138.69,320.53,136.27,325.8,131.44z"
        />
        <path
          class="st2"
          d="M415.05,165.51h-30.23v-45.02h-14.43v45.02h-30.23V53.57h30.23v40.09h14.43V53.57h30.23V165.51z"
        />
        <path
          class="st2"
          d="M430.71,165.51V53.57h56.19V80.4h-25.96v14.28h23.27v26.83h-23.27v17.18h25.96v26.83H430.71z"
        />
        <path
          class="st2"
          d="M579.41,165.51h-32.7l-14.94-39.88c-0.15-0.68-0.4-1.57-0.76-2.68c-0.36-1.11-0.81-2.44-1.34-3.99l0.22,7.4
        	v39.15H499.8V53.57h30.96c13.63,0,23.83,2.68,30.6,8.05c8.6,6.86,12.91,16.63,12.91,29.29c0,13.53-5.78,22.89-17.33,28.06
        	L579.41,165.51z M530.03,103.81h2.03c3.48,0,6.38-1.33,8.7-3.99c2.32-2.66,3.48-6.04,3.48-10.15c0-8.36-4.38-12.54-13.12-12.54
        	h-1.09V103.81z"
        />
      </SvgIcon>
    );
  }
}

export default withStyles({}, { withTheme: true })(ArcherLogo);
