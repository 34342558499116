import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import Calendar from "@material-ui/icons/CalendarToday";
// material-ui
import { withStyles } from "@material-ui/styles";
import DatePicker from "components/DatePicker";
import Help from "components/Help";
import LocationFinder from "components/LocationFinder";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
// internal
import Card from "../Card";
// constants
import {
  ADD,
  MAX_LOCATION_AUTOCOMPLETE_ITEMS,
  REMOVE,
  SHIPPER,
} from "../constants";
// styles
import styles from "./styles";

class Stop extends Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    nextClicked: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    newLocation: PropTypes.func,
    searchLocations: PropTypes.func,
    gatherErrorFunction: PropTypes.func,
    holidays: PropTypes.array,
    step: PropTypes.number,
    ltl: PropTypes.bool,
    type: PropTypes.string,
    shipConId: PropTypes.number,
    locationId: PropTypes.number,
    name: PropTypes.string,
    line1: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    referenceNumber: PropTypes.string,
    confimationNumber: PropTypes.string,
    date: PropTypes.string,
    timeFrom: PropTypes.string,
    timeTo: PropTypes.string,
    contactName: PropTypes.string,
    phone: PropTypes.string,
    notes: PropTypes.string,
    index: PropTypes.number,
    service: PropTypes.string,
    grouped: PropTypes.bool,
    dateFrom: PropTypes.string,
    dateTo: PropTypes.string,
    getLocationById: PropTypes.func,
    updateLocation: PropTypes.func,
  };

  constructor(...args) {
    super(...args);
    const { gatherErrorFunction, step, shipConId } = this.props;
    gatherErrorFunction(
      {
        errorFunction: this.hasError.bind(this),
        section: `Stop ${shipConId}`,
      },
      ADD,
      step
    );
  }

  componentWillUnmount() {
    const { gatherErrorFunction, step, shipConId } = this.props;
    gatherErrorFunction(
      {
        errorFunction: this.hasError.bind(this),
        section: `Stop ${shipConId}`,
      },
      REMOVE,
      step
    );
  }

  hasError() {
    const { locationId, referenceNumber } = this.props;
    if (locationId === undefined || locationId === null) {
      return true;
    }

    if (referenceNumber === "") {
      return true;
    }

    return false;
  }

  render() {
    const {
      classes,
      nextClicked,
      disabled,
      theme,
      type,
      index,
      zip,
      onChange,
      newLocation,
      searchLocations,
      service,
      locationId,
      name,
      referenceNumber,
      confimationNumber,
      notes,
      date,
      grouped,
      dateFrom,
      dateTo,
      timeFrom,
      holidays,
      ltl,
      getLocationById,
      updateLocation,
    } = this.props;

    const today = new Date();
    const tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    const primaryColor = theme.palette.primary.color[500];

    return (
      <Card
        nextClicked={nextClicked}
        error={this.hasError()}
        primaryColor={primaryColor}
        disabled={disabled}
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Grid container alignItems="center">
              <Grid item style={{ paddingRight: 8 }}>
                {type === SHIPPER ? <ArrowUpward /> : <ArrowDownward />}
              </Grid>
              <Grid item>
                <Typography display="block" variant="h6">
                  {type} #{index + 1}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Typography display="block" variant="subtitle1" color="primary">
          Location
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <TextField
              disabled
              id="zip"
              label="Location"
              placeholder="Zip Code"
              fullWidth
              helperText={zip.length < 5 ? "Required" : ""}
              value={zip}
              onChange={(e) => onChange(e.target.value, "zip", index)}
              InputLabelProps={{
                shrink: true,
              }}
              error={zip === "" && nextClicked}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <LocationFinder
              maxLocationAutocompleteItems={MAX_LOCATION_AUTOCOMPLETE_ITEMS}
              error={
                nextClicked && (locationId === undefined || locationId === null)
              }
              helperText={
                locationId === undefined || locationId === null
                  ? "Required"
                  : ""
              }
              zip={zip.length >= 5 ? zip : undefined}
              disabled={disabled}
              searchLocations={searchLocations}
              service={service}
              getLocationById={getLocationById}
              updateLocation={updateLocation}
              onChange={(e) => {
                if (e.target.value) {
                  onChange(e.target.value.id, "locationId", index);
                  onChange(e.target.value.name, "name", index);
                  onChange(e.target.value.address.line1, "line1", index);
                  onChange(e.target.value.address.zip, "zip", index);
                  onChange(e.target.value.address.city, "city", index);
                  onChange(e.target.value.address.state, "state", index);
                } else {
                  onChange(undefined, "locationId", index);
                  onChange("", "name", index);
                  onChange("", "line1", index);
                  onChange("", "city", index);
                  onChange("", "state", index);
                }
              }}
              locationId={locationId}
              newLocation={newLocation}
              value={locationId !== null ? name : undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography display="block" variant="subtitle1" color="primary">
              References
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Help
              explanation="Reference number is provided on customer rate confirmation form"
              image="https://storage.googleapis.com/archer-app-dev/help/Reference%20Number.png"
            >
              <TextField
                id="referenceNumber"
                label="Reference Number"
                placeholder={type === SHIPPER ? "BOL and reference #" : "PO#"}
                fullWidth
                helperText={"Required or enter 'none'"}
                value={referenceNumber}
                onChange={(e) =>
                  onChange(e.target.value, "referenceNumber", index)
                }
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={disabled}
                error={referenceNumber === "" && nextClicked}
              />
            </Help>
          </Grid>
          <Grid item xs={12} md={6}>
            <Help
              explanation="The pickup number is provided by customer on their rate confirmation"
              image="https://storage.googleapis.com/archer-app-dev/help/PU%20Number.png"
            >
              <TextField
                id="confimationNumber"
                label="Confimation Number"
                placeholder="Enter the Confimation Number if Any"
                fullWidth
                value={confimationNumber}
                onChange={(e) =>
                  onChange(e.target.value, "confimationNumber", index)
                }
                InputLabelProps={{
                  shrink: true,
                }}
                disabled={disabled}
              />
            </Help>
          </Grid>
          <Grid item xs={12}>
            <Typography display="block" variant="subtitle1" color="primary">
              Appointment
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            {type === SHIPPER || grouped ? (
              <Chip
                style={{ marginTop: 14 }}
                label={
                  grouped
                    ? `${moment(dateFrom).format("MMMM Do YYYY")} - ${moment(
                        dateTo
                      ).format("MMMM Do YYYY")}`
                    : moment(date).format("MMMM Do YYYY")
                }
                avatar={
                  <Avatar>
                    <Calendar />
                  </Avatar>
                }
              />
            ) : (
              <DatePicker
                label="Select a Date"
                holidays={holidays}
                value={date}
                helperText={"Required"}
                onChange={(e) => onChange(e, "date", index)}
                ltl={ltl}
              />
            )}
          </Grid>
          {/* {
            type === SHIPPER && (service !== 'economy')
             ? (
                <Grid item xs={12} md={3}>
                  <TextField
                    id="time"
                    fullWidth
                    helperText={'Required'}
                    error={(timeFrom === '') && nextClicked}
                    value={timeFrom}
                    label={service === 'guaranteed' ? 'LOCAL Time (24h)' : 'From Earliest LOCAL Time (24h)'}
                    type="time"
                    onChange={e => onChange(e.target.value, 'timeFrom', index)}
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 60, // 5 min
                    }}
                  />
                </Grid>
             ) : <div />
          }
          {
            (type === SHIPPER) && (service === 'premium')
             ? (
                <Grid item xs={12} md={3}>
                  <Typography display="block" variant="caption" color="textSecondary">
                    To Latest LOCAL Time (24h)
                  </Typography>
                  <Typography display="block">
                    {timeTo} (4H Window)
                  </Typography>
                </Grid>
             ) : <div />
          }
          {
            (type === SHIPPER) && (service === 'economy')
             ? (
                <Grid item xs={12} md={3}>
                  <Typography display="block" variant="caption" color="textSecondary">
                    Appointment Time
                  </Typography>
                  <Typography display="block">
                    FCFS
                  </Typography>
                </Grid>
             ) : <div />
          } */}
          <Grid item xs={12} md={3}>
            <TextField
              id="time"
              fullWidth
              helperText={"Required"}
              error={timeFrom === "" && nextClicked}
              value={timeFrom}
              label={"LOCAL Time (24h)"}
              type="time"
              onChange={(e) => onChange(e.target.value, "timeFrom", index)}
              className={classes.textField}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                step: 60, // 5 min
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.notes}>
            <TextField
              id="notes"
              label="Notes"
              placeholder={"Enter Some Relevant Notes"}
              fullWidth
              value={notes}
              onChange={(e) => onChange(e.target.value, "notes", index)}
              InputLabelProps={{
                shrink: true,
              }}
              multiline
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Card>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Stop);
