import { GET } from "constants/methods";

const service = "v3/quote-service";
const holidays = `/${service}/holidays`;

export function getHolidays() {
  const url = `${holidays}`;
  return {
    service,
    method: GET,
    url,
  };
}
