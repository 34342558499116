import loads from "./loads";
import quotes from "./quotes";
import searchPresets from "./searchPresets";
import users from "./users";
import errors from "./errors";
import app from "./app";
import user from "./user";
import organizations from "./organizations";
import userApps from "./userApps";
import personNames from "./personNames";
import { combineReducers } from "redux"; //eslint-disable-line
export default combineReducers({
  errors,
  app,
  users,
  userApps,
  user,
  organizations,
  quotes,
  personNames,
  searchPresets,
  loads,
});
