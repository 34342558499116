import { Avatar } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Chip from "@material-ui/core/Chip";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import CloseIcon from "@material-ui/icons/Close";
import Place from "@material-ui/icons/Place";
// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class LocationsByAddress extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,
    service: PropTypes.string,
    primaryColor: PropTypes.string,

    close: PropTypes.func,
    open: PropTypes.bool,
    searchLocations: PropTypes.func,
    newLocationRequested: PropTypes.func,
    onCancel: PropTypes.func,
    onSelect: PropTypes.func,
    selected: PropTypes.object,
  };

  state = {
    loading: true,
    locationsFound: [],
  };

  componentWillReceiveProps(nextProps) {
    if (!this.props.open && nextProps.open && nextProps.selected) {
      this.searchLocations(nextProps.selected.place_id);
    }
  }

  searchLocations(placeID) {
    // last min 2 sec for clear visibility of the message
    const { searchLocations, newLocationRequested } = this.props;
    const prom = searchLocations(`?placeId=${placeID}`);
    setTimeout(() => {
      prom.then((resp) => {
        if (resp.payload.length === 0) {
          newLocationRequested();
          this.close();
        } else {
          this.setState({
            locationsFound: resp.payload,
            loading: false,
          });
        }
      });
    }, 2000);
  }

  close() {
    const { close } = this.props;
    this.setState({
      locationsFound: [],
      loading: true,
    });
    close();
  }

  render() {
    const {
      classes,
      fullScreen,
      open,
      selected,
      service,
      primaryColor,
      onCancel,
      onSelect,
      newLocationRequested,
    } = this.props;

    const { locationsFound, loading } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={this.close.bind(this)}
        aria-labelledby="responsive-dialog-title"
      >
        <div>
          <DialogTitle id="responsive-dialog-title">
            {loading
              ? "Searching Locations..."
              : `${locationsFound.length} Location${
                  locationsFound.length > 1 ? "s" : ""
                } Found :`}
          </DialogTitle>
          <DialogContent>
            <Typography display="block">
              {selected ? selected.formatted_address : ""}
            </Typography>
            <br />
            <Grid container spacing={24} justify="center">
              {loading ? (
                <Grid item style={{ textAlign: "center" }}>
                  <CircularProgress />
                </Grid>
              ) : (
                <Grid item />
              )}
              {locationsFound &&
                locationsFound.map((l) => (
                  <Grid
                    item
                    xs={12}
                    key={l.id}
                    style={{
                      border: "solid 1px #9e9e9e",
                      borderRadius: 4,
                      marginBottom: 10,
                    }}
                  >
                    <Grid
                      container
                      spacing={24}
                      alignItems={"center"}
                      justify="space-between"
                    >
                      <Grid item>
                        <Grid container spacing={24} alignItems="center">
                          <Grid item>
                            <Avatar style={{ background: primaryColor }}>
                              <Place />
                            </Avatar>
                          </Grid>
                          <Grid item>
                            <Typography display="block" variant="h6">
                              {l.name}
                            </Typography>
                            <Typography display="block" variant="caption">
                              {l.contact ? `Contact: ${l.contact}` : ""}{" "}
                              {l.phone ? `Phone: ${l.phone}` : ""}
                            </Typography>
                            {service === "LTL" ? (
                              <div>
                                {l.accessorials.ltlAccessorials &&
                                  l.accessorials.ltlAccessorials.map((a) => (
                                    <Chip
                                      label={`${a.name}, $${a.costAmount}`}
                                    />
                                  ))}
                              </div>
                            ) : (
                              <div />
                            )}
                            {service === "TL" ? (
                              <div>
                                {l.accessorials.tlAccessorials &&
                                  l.accessorials.tlAccessorials.map((a) => (
                                    <Chip
                                      label={`${a.name}, $${a.costAmount}`}
                                    />
                                  ))}
                              </div>
                            ) : (
                              <div />
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="outlined"
                          color={"primary"}
                          onClick={() => {
                            onSelect(l);
                            this.close();
                          }}
                        >
                          Select
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              {!loading ? (
                <Grid item style={{ textAlign: "center" }}>
                  <Typography display="block" variant="caption">
                    Can't find the spot you are looking for ?
                  </Typography>
                  <br />
                  <Button
                    color="primary"
                    onClick={() => {
                      newLocationRequested();
                      this.close();
                    }}
                  >
                    New Location
                  </Button>
                </Grid>
              ) : (
                <div />
              )}
            </Grid>
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              onClick={() => {
                this.close();
                onCancel();
              }}
            >
              <CloseIcon className={classes.leftIcon} />
              &nbsp;Cancel
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(LocationsByAddress));
