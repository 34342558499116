import { GET } from "constants/methods";

const service = "v3/quote-service";
const accessorials = `/${service}/accessorials`;

export function getAccessorials(customerID) {
  const url = `${accessorials}${customerID ? `?customerId=${customerID}` : ""}`;
  return {
    service,
    method: GET,
    url,
  };
}
