export default (theme) => ({
  container: {
    padding: 5,
  },
  pieces: {
    padding: 4,
    marginTop: 10,
  },
  notes: {
    background: "#fff9c4",
    borderRadius: 4,
    border: "solid 1px #ffee58",
    padding: 4,
    marginTop: 10,
  },
  root: {
    width: 30,
    textAlign: "center",
    color: "white",
    fontSize: 25,
    marginLeft: 3,
  },
  avatar: {
    background: theme.palette.primary.color[700],
    padding: 5,
    height: 40,
    width: 40,
    borderRadius: 40,
  },
});
