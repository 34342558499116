// material-ui
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class Counter extends Component {
  static propTypes = {
    classes: PropTypes.object,
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.container}>
        <Typography display="block" variant="body1">
          <span role="img" aria-label="Cat">
            🐱
          </span>{" "}
          Miaou !
        </Typography>
      </div>
    );
  }
}

export default withStyles(styles)(Counter);
