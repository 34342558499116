export default (theme) => ({
  container: {
    width: "100vw",
    minHeight: "100vh",
    background: theme.palette.background.default,
  },
  loading: {
    width: "100vw",
    minHeight: "100vh",
    background: "rgba(255,255,255,0.4)",
    backdropFilter: "blur(18px)",
    position: "absolute",
    top: 0,
  },
  item: {
    minHeight: "100vh",
  },
  h1: {
    fontFamily: "poppins, sans-serif",
    fontWeight: 700,
    fontStyle: "normal",
    textAlign: "center",
    fontSize: 80,
    lineHeight: 1.3,
  },
  body: {
    fontStyle: "normal",
    textAlign: "center",
    fontSize: 16,
    lineHeight: "21px",
  },
  h5: {
    fontFamily: "poppins, sans-serif",
    fontStyle: "normal",
    fontSize: 34,
    lineHeight: "51px",
  },
  h6: {
    fontFamily: "poppins, sans-serif",
    fontWeight: 300,
    fontStyle: "normal",
    fontSize: 35,
    lineHeight: 1,
  },
  ultimate: {
    fontFamily: "bc-alphapipe, sans-serif",
    fontWeight: 700,
    fontStyle: "normal",
  },
  paper: {
    maxWidth: 600,
    padding: 20,
    boxShadow: "0px 0px 80px 1px rgba(0,0,0,0.2)",
    margin: "auto",
    textAlign: "center",
  },
  stripe: {
    padding: 15,
    border: "solid 1px #cfd8dc",
    borderRadius: 6,
    marginTop: 10,
  },
});
