import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Grid from "@material-ui/core/Grid";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import withMobileDialog from "@material-ui/core/withMobileDialog";
import SaveIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
// material-ui
import withStyles from "@material-ui/styles/withStyles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class FormLocation extends Component {
  static propTypes = {
    classes: PropTypes.object,
    fullScreen: PropTypes.bool,

    close: PropTypes.func,
    open: PropTypes.bool,

    location: PropTypes.object,
    onNewLocation: PropTypes.func,
    onCancel: PropTypes.func,

    newLocation: PropTypes.func,
    updateLocation: PropTypes.func,
    deleteLocation: PropTypes.func,
  };

  static contextTypes = {
    NotificationCenter: PropTypes.object,
  };

  constructor(...args) {
    super(...args);
    this.state = {
      loading: false,
      name: "",
      contact: "",
      phone: "",
      groceryWarehouse: false,
      liftgateRequired: false,
      liquorPermit: false,
      nextClicked: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.open && !this.props.open) {
      this.init(nextProps.location);
    }
  }

  handleChange = (name) => (event) => {
    const { target } = event;
    const { value } = target;

    this.setState({
      [name]: value,
    });
  };

  handleCheckboxChange = (name) => (event) => {
    const { target } = event;
    const { checked } = target;

    this.setState({
      [name]: checked,
    });
  };

  handleClick() {
    const { name, contact, phone } = this.state;

    if (name.length && contact.length && phone.length) {
      this.save();
    } else {
      this.setState({
        nextClicked: true,
      });
    }
  }

  init(location) {
    console.log("Now Look! Init", location);
    this.setState({
      loading: false,
      name: location && location.name ? location.name : "",
      contact: location && location.contact ? location.contact : "",
      phone: location && location.phone ? location.phone : "",
      groceryWarehouse:
        location && location.groceryWarehouse ? location.groceryWarehouse : "",
      liftgateRequired:
        location && location.liftgateRequired ? location.liftgateRequired : "",
      liquorPermit:
        location && location.liquorPermit ? location.liquorPermit : "",
    });
  }

  parsePlace(p) {
    const place = {
      place_id: null,
      verified: false,
      latitude: null,
      longitude: null,
      partial_match: false,
    };

    if (p !== null) {
      place.place_id = p.place_id;
      place.name = p.name;
      if (p.geometry !== null) {
        place.location = p.geometry.location;
        if (p.geometry.location !== null) {
          place.latitude = place.location.lat();
          place.longitude = place.location.lng();
        }
      }
      place.formatted_address = p.formatted_address;
      const _ref = p.address_components;
      const _len = _ref.length;
      for (let _i = 0; _i < _len; _i++) {
        const i = _ref[_i];
        const _ref1 = i.types;
        const _len1 = _ref1.length;
        for (let _j = 0; _j < _len1; _j++) {
          const t = _ref1[_j];
          place[t] = i.short_name;
          place[`${t}_long`] = i.long_name;
        }
      }
      place.line_1 = `${place.street_number} ${place.route}`.trim();
      place.line_2 = place.subpremise;
      place.city =
        place.locality_long ||
        place.sublocality_long ||
        place.sublocality_level_1_long ||
        place.neighborhood_long;
      place.state = place.administrative_area_level_1;
      place.zip = place.postal_code;
      place.types = p.types;
      place.verified = place.street_number !== null;
    }

    return {
      addressId: place.address_id,
      formattedAddress: place.formatted_address,
      line1: place.line_1,
      line2: place.line_2 || "",
      city: place.city,
      state: place.state,
      zip: place.zip,
      country: place.country,
      latitude: place.latitude,
      longitude: place.longitude,
      timezone: place.timezone,
      placeId: place.place_id,
      verified: place.verified,
    };
  }

  delete() {
    const { NotificationCenter } = this.context;
    NotificationCenter.sweetAlert(
      {
        title: "Are you sure ?",
        subtitle:
          "If you delete this location, you won't be able to recover it.",
        timestamp: new Date().getTime(),
        error: true,
      },
      {
        cancel: {
          label: "Cancel",
          level: "default",
        },
        confirm: {
          label: "I am sure",
          level: "error",
          callback: this.confirmDelete.bind(this),
        },
      }
    );
  }

  async confirmDelete() {
    const { deleteLocation, group, close, refresh } = this.props;

    const { NotificationCenter } = this.context;

    const resp = await deleteLocation(group.id);
    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: "Location has been deleted.",
        success: true,
        timestamp: new Date().getTime(),
      });
      close();
      refresh();
      setTimeout(NotificationCenter.hide, 1500);
    }
  }

  async save() {
    const {
      updateLocation,
      newLocation,
      location,
      onNewLocation,
      close,
    } = this.props;

    const {
      name,
      contact,
      phone,
      groceryWarehouse,
      liftgateRequired,
      liquorPermit,
    } = this.state;

    const { NotificationCenter } = this.context;

    const accessorialTags = [];
    if (liftgateRequired) {
      accessorialTags.push("liftgate");
    }
    if (groceryWarehouse) {
      accessorialTags.push("grocery");
    }
    if (liquorPermit) {
      accessorialTags.push("liquor_permit");
    }

    let resp;
    this.setState({ loading: true });
    if (location && location.id) {
      resp = await updateLocation(location.id, {
        name,
        contact,
        phone,
        accessorialTags,
      });
    } else {
      resp = await newLocation({
        name,
        contact,
        phone,
        accessorialTags,
        address: this.parsePlace(location),
      });
    }

    if (resp.success) {
      onNewLocation(resp.payload);
      close();
    }

    this.setState({ loading: false });

    if (resp.success) {
      NotificationCenter.sweetAlert({
        title: location
          ? "Location has been updated."
          : "Location has been created.",
        success: true,
        timestamp: new Date().getTime(),
      });

      setTimeout(NotificationCenter.hide, 2000);
    }
  }

  render() {
    const { classes, fullScreen, open, location, close, onCancel } = this.props;

    const {
      name,
      contact,
      phone,
      groceryWarehouse,
      liftgateRequired,
      liquorPermit,
      loading,
      nextClicked,
    } = this.state;

    return (
      <Dialog
        fullScreen={fullScreen}
        open={open}
        scroll="paper"
        onClose={close}
        aria-labelledby="responsive-dialog-title"
      >
        {loading ? (
          <div className={classes.progressContainer}>
            <DialogTitle id="alert-dialog-title">
              Creating the Location ...
            </DialogTitle>
            <CircularProgress />
          </div>
        ) : (
          <div>
            <DialogTitle id="responsive-dialog-title">
              {location && location.id
                ? `${location.name}`
                : "Let's Create a New Location"}
            </DialogTitle>
            <DialogContent>
              <Grid container spacing={24}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    placeholder="Location's Name"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    helperText="Required"
                    error={nextClicked && name === ""}
                    className={classes.textField}
                    value={name}
                    onChange={this.handleChange("name")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="contactName"
                    label="Contact"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    error={nextClicked && contact === ""}
                    placeholder="Contact Full Name"
                    helperText="Required"
                    className={classes.textField}
                    value={contact}
                    onChange={this.handleChange("contact")}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    id="contactPhone"
                    label="Phone"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder="Contact Phone"
                    helperText="Required"
                    error={nextClicked && phone === ""}
                    className={classes.textField}
                    value={phone}
                    onChange={this.handleChange("phone")}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={24}>
                <Grid item>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={liftgateRequired}
                          onChange={this.handleCheckboxChange(
                            "liftgateRequired"
                          )}
                          value="Lift Gate Required"
                          color="primary"
                        />
                      }
                      label="Lift Gate Required"
                    />
                  </FormGroup>
                </Grid>
                <Grid item>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={groceryWarehouse}
                          onChange={this.handleCheckboxChange(
                            "groceryWarehouse"
                          )}
                          value="Grocery Warehouse"
                          color="primary"
                        />
                      }
                      label="Grocery Warehouse"
                    />
                  </FormGroup>
                </Grid>
                <Grid item>
                  <FormGroup row>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={liquorPermit}
                          onChange={this.handleCheckboxChange("liquorPermit")}
                          value="Liquor Permit"
                          color="primary"
                        />
                      }
                      label="Liquor Permit"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              {location && (
                <Button
                  className={classes.delete}
                  onClick={this.delete.bind(this)}
                >
                  <DeleteIcon className={classes.leftIcon} />
                  &nbsp;Delete
                </Button>
              )}
              <Button onClick={onCancel}>
                <CloseIcon className={classes.leftIcon} />
                &nbsp;Cancel
              </Button>
              <Button
                color="primary"
                autoFocus
                disabled={!name.length || !contact.length || !phone.length}
                onClick={this.handleClick.bind(this)}
              >
                <SaveIcon className={classes.leftIcon} />
                {location ? "Save" : "Create"}
              </Button>
            </DialogActions>
          </div>
        )}
      </Dialog>
    );
  }
}

export default withStyles(styles)(withMobileDialog()(FormLocation));
