import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import Info from "@material-ui/icons/Info";
// material-ui
import { withStyles } from "@material-ui/styles";
import CustomerFinder from "components/CustomerFinder";
import Help from "components/Help";
import PropTypes from "prop-types";
import React, { Component } from "react";
// internal
import Card from "../Card";
// constants
import { ADD, REMOVE } from "../constants";
// styles
import styles from "./styles";

class AboutLoad extends Component {
  static propTypes = {
    classes: PropTypes.object,
    theme: PropTypes.object,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    nextClicked: PropTypes.bool,
    referenceNumber: PropTypes.string,
    notes: PropTypes.string,
    customerId: PropTypes.number,
    customerName: PropTypes.string,
    searchCustomers: PropTypes.func,
    gatherErrorFunction: PropTypes.func,
    step: PropTypes.number,
  };

  constructor(...args) {
    super(...args);
    const { gatherErrorFunction, step } = this.props;
    gatherErrorFunction(
      {
        errorFunction: this.hasError.bind(this),
        section: "About the Load",
      },
      ADD,
      step
    );
  }

  componentWillUnmount() {
    const { gatherErrorFunction, step } = this.props;
    gatherErrorFunction(
      {
        errorFunction: this.hasError.bind(this),
        section: "About the Load",
      },
      REMOVE,
      step
    );
  }

  handleChange = (name) => (event) => {
    const { onChange } = this.props;
    const { target } = event;
    const { value } = target;

    onChange({
      [name]: value,
    });
  };

  hasError() {
    const { referenceNumber, customerId } = this.props;
    return referenceNumber.length === 0 && customerId;
  }

  render() {
    const {
      nextClicked,
      disabled,
      theme,
      classes,
      referenceNumber,
      notes,
      customerName,
      customerId,
      searchCustomers,
    } = this.props;

    const primaryColor = theme.palette.primary.color[500];

    return (
      <div>
        <Card
          nextClicked={nextClicked}
          error={this.hasError()}
          primaryColor={primaryColor}
          disabled={disabled}
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item>
              <Info />
            </Grid>
            <Grid item>
              <Typography display="block" variant="h6">
                About the Load
              </Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2} className={classes.line}>
            <Grid item xs={12} md={4}>
              <CustomerFinder
                searchCustomers={searchCustomers}
                onChange={this.handleChange("customerId")}
                defaultValue={{ name: customerName, id: customerId }}
                helperText={"Required"}
                error={customerId === undefined && nextClicked}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Help
                image="https://storage.googleapis.com/archer-app-dev/help/PRO%20on%20new%20Quote.png"
                explanation="In Legacy Archer after getting quote they generate load and that gives WA# to use for PRO number if customer did not provide one."
              >
                <TextField
                  id="referenceNumber"
                  label="Pro Number"
                  placeholder={"Enter Pro Number"}
                  helperText={"Required"}
                  fullWidth
                  value={referenceNumber}
                  onChange={this.handleChange("referenceNumber", true)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  error={referenceNumber.length === 0 && nextClicked}
                  disabled={disabled}
                />
              </Help>
            </Grid>
            <Grid item xs={12} className={classes.notes}>
              <TextField
                id="notes"
                label="Notes"
                placeholder={"Enter Some Relevant Notes"}
                fullWidth
                value={notes}
                onChange={this.handleChange("notes", true)}
                InputLabelProps={{
                  shrink: true,
                }}
                multiline
                disabled={disabled}
              />
            </Grid>
          </Grid>
        </Card>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(AboutLoad);
