import { DATA, GET } from "constants/methods";

const service = "v3/file-service";
const loads = `/${service}/loads`;

export function uploadLoadFile(loadID, file, descriptionID) {
  const data = new FormData();
  data.append("file", file);
  data.append("description", descriptionID);

  const url = `${loads}/${loadID}/files`;

  return {
    method: DATA,
    url,
    body: data,
  };
}

export function getLoadFiles(loadID) {
  const url = `${loads}/${loadID}/files`;
  return {
    service,
    method: GET,
    url,
  };
}
