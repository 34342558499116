import { GET } from "constants/methods";

const service = "/v1";
const customers = "customers";

export function searchCustomers(query, start, limit) {
  const url = `${service}/${customers}/search?query=${encodeURIComponent(
    query
  )}&start=${start}&limit=${limit}`;
  return {
    service,
    method: GET,
    url,
  };
}
