// material-ui
import { withStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { Component } from "react";
// styles
import styles from "./styles";

class TableIcon extends Component {
  static propTypes = {
    datum: PropTypes.object,
  };

  render() {
    const { datum } = this.props;

    if (datum.icon) {
      return (
        <img
          src={datum.icon || datum.projectTypeIcon}
          alt="icon"
          style={{ height: 30 }}
        />
      );
    }

    return <div></div>;
  }
}

export default withStyles(styles)(TableIcon);
